import dayjs from "dayjs";
import grayscaleLogo from "../../assets/Cardigan_Logomark_Grayscale.png"
import ExternalLink from "../Common/Components/ExternalLink";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { useEffect, useState } from "react";

const NewsArticle = ({ data }) => {

  const [linkText, setLinkText] = useState("");

  useEffect(() => {
    switch (data.link.type) {
      case "article":
        setLinkText("Read original article");
        break;
      case "video":
        setLinkText("Watch the full segment");
        break;
      case "pdf":
        setLinkText("Download PDF of original article");
        break;
      default:
        break;
    }
  }, [data.link.type])

  const lap = useScreenWidth("isLaptop");
  const tab = useScreenWidth("isTablet");
  return (
    <div className={`${lap ? "v-max-width-25" : tab ? "v-max-width-43" : "v-max-width-100"}`}>
      <img className="p-width-100 height-260 object-fit-cover m-b-15" alt={data.title} src={data.image ? data.image : grayscaleLogo} />
      <h4 className={`color-brown ${lap ? "font-size-24" : "font-size-20"}`}>{data.title}</h4>
      <p className={`m-t-10 ${lap ? "font-size-20" : ""}`}>{data.content}</p>
      <p className={` m-t-15 ${lap ? "font-size-20" : ""}`}>{dayjs(data.date).format("MMMM DD, YYYY")}</p>
      <ExternalLink
        href={data.link.url}
        text={linkText}
        classes={`${lap ? "font-size-20" : "font-size-16"} m-t-15 color-blue`}
      />
    </div>
  )
}

export default NewsArticle;