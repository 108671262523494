import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { COLORS } from "../../../../../../../constants/colors";
import { ORDER_FROM_LOCATIONS } from "../../../../../../../constants/locations";

export const contactConfirm = (confirmation) => {

  dayjs.extend(advancedFormat);
  const { contact:
    { contact: {
      firstName,
      lastName,
      email,
      phone
    }
    },
    cartConfig,
    message,
    instructions
  } = confirmation;
  let html = `
    <div style="padding: 25px 30px;">
      <div style="margin-bottom: 40px;">
        <h4 style="margin-bottom: 5px; font-size: 16px; color: ${COLORS.blue}; text-transform: uppercase; font-family: 'Open Sans', sans-serif;">Order Type</h4>
        <p style="margin-top: color: ${COLORS.brown}; 20px;">${cartConfig.orderType.externalId}</p>`;

  Object.keys(cartConfig).map(item => {
    if (item !== "orderType") {
      html += `<p style="margin-top: 20px; color: ${COLORS.brown}; display: flex; flex-direction: column; width: 200px;">`;
      if (item === "date") {
        html += dayjs(cartConfig[item]).format("dddd, MMMM Do, YYYY");
      } else {
        if (item === "location") {
          html += `<span>${cartConfig[item]}</span>
                    <span>${ORDER_FROM_LOCATIONS.filter(el => el.name === cartConfig[item])[0].address}</span>`
        } else if (item === "deliveryType") {
          html += cartConfig[item].name;
        } else {
          html += cartConfig[item];
        }
      }
      html += `</p>`;
    }
    return item;
  })

  html += `</div>
  <div>
    <h4 style="font-size: 16px; color: ${COLORS.blue}; text-transform: uppercase; font-family: 'Open Sans', sans-serif;">Contact</h4>
    <p style="margin-top: 20px; color: ${COLORS.brown};">${firstName} ${lastName}</p>
    <p style="margin-top: 20px; color: ${COLORS.brown};">${email}</p>
    <p style="color: ${COLORS.brown};">${phone}</p>
  </div>`;

  if (message) {
    html += `<div style="margin-top: 40px;">
      <h4 style="margin-bottom: 5px; font-size: 16px; color: ${COLORS.blue}; text-transform: uppercase; font-family: 'Open Sans', sans-serif;">Special message</h4>
      <p style="margin-top: 20px; color: ${COLORS.brown};">${message}</p>
    </div>`;
  }
  if (instructions) {
    html += `<div style="margin-top: 40px;">
      <h4 style="margin-bottom: 5px; font-size: 16px; color: ${COLORS.blue}; text-transform: uppercase; font-family: 'Open Sans', sans-serif;">Order Instructions</h4>
      <p style="margin-top: 20px; color: ${COLORS.brown};">${instructions}</p>
    </div>`;
  }

  html += `</div>`;
  return html;
}