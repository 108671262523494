import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Fragment } from "react";
import { smallBlueUpperClasses } from "../";
import { ORDER_FROM_LOCATIONS } from "../../../constants/locations";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";
import { contClasses, StyledSectionCont } from "../../Order/Checkout/PersonalInfo";
import withLocalContext from "../../store/withLocalContext";

const ContactConfirm = ({ context: { state } }) => {
  const lap = useScreenWidth("isLaptop");
  if (!state.confirmation) {
    return null;
  }
  dayjs.extend(advancedFormat);
  const { contact:
    { contact: {
      firstName,
      lastName,
      email,
      phone
    }
    },
    cartConfig,
    message,
    instructions
  } = state.confirmation;

  return (
    <StyledSectionCont container className={`${contClasses()}`}>
      <Grid2 xs={12} className={`${lap ? "m-b-60" : "m-b-45"}`}>
        <h2 className={`color-brown ${lap ? "" : "font-size-20"}`}>Thanks for your order!</h2>
      </Grid2>
      <Grid2 xs={12} className="m-b-25">
        <h3 className="color-red font-size-20">Confirmation</h3>
      </Grid2>
      <Grid2 xs={12}>
        <div className="width-fit">
          <h4 className={`${smallBlueUpperClasses()} m-b-5`}>Your order is confirmed</h4>
          <p>You’ll receive a confirmation email with your receipt.</p>
          <div className="height-3 background-color-tan m-y-35" />
        </div>
      </Grid2>
      <Grid2 xs={12} className="m-b-40">
        <h4 className={`${smallBlueUpperClasses()} m-b-5`}>Order Type</h4>
        <p className="m-t-20">{cartConfig.orderType.externalId}</p>
        {Object.keys(cartConfig).map(item => (
          item !== "orderType" &&
          <p key={item} className="m-t-20 d-flex flex-direction-column width-200">
            {
              item === "date" ?
                dayjs(cartConfig[item]).format("dddd, MMMM Do, YYYY")
                :
                item === "location" ?
                  <Fragment>
                    <span>{cartConfig[item]}</span>
                    <span>{ORDER_FROM_LOCATIONS.filter(el => el.name === cartConfig[item])[0].address}</span>
                  </Fragment>
                  :
                  item === "deliveryType" ?
                    cartConfig[item].name
                    :
                    cartConfig[item]
            }
          </p>
        ))}
      </Grid2>
      <Grid2 xs={12}>
        <h4 className={`${smallBlueUpperClasses()} m-b-5`}>Contact</h4>
        <p className="m-t-20">{firstName} {lastName}</p>
        <p className="m-t-20">{email}</p>
        <p>{phone}</p>
      </Grid2>
      {message &&
        <Grid2 xs={12} className="m-t-40">
          <h4 className={`${smallBlueUpperClasses} m-b-5`}>Special message</h4>
          <p className="m-t-20">{message}</p>
        </Grid2>
      }
      {instructions &&
        <Grid2 xs={12} className="m-t-40">
          <h4 className={`${smallBlueUpperClasses} m-b-5`}>Order Instructions</h4>
          <p className="m-t-20">{instructions}</p>
        </Grid2>
      }
    </StyledSectionCont>
  )
}

export default withLocalContext(ContactConfirm);