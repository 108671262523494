import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Fragment } from "react";
import { COLORS } from "../../constants/colors";
import { Box } from "@mui/material";
import LocationItem from "../Common/Components/LocationList/LocationItem";
import LocationsMap from "../Common/Components/LocationsMap";
import CustomBreakpoints from "../Common/Components/CustomBreakpoints";

const FlagshipLocations = ({
  locations = [],
  title = "",
  description = "",
  imageDisplay = "top",
  contactInfo
}) => {

  return (
    <Grid2 container xs={12}>
      <CustomBreakpoints customBreakpoints={{
        xs: 0,
        lg: 1415
      }}>
        <Grid2 container xs={12} lg={5} justifyContent="center" sx={{ marginBottom: { xs: "30px", lg: 0 } }}>
          <LocationsMap />
        </Grid2>
      </CustomBreakpoints>
      {(title || description) &&
        <Fragment>
          {title &&
            <Grid2
              container
              alignContent={"flex-start"}
              xs={12}
              sx={{
                position: { xs: "sticky", sm: "unset" },
                top: { xs: "80px", sm: "unset" },
                backgroundColor: { xs: COLORS.white, sm: "transparent" },
                margin: { xs: "0 -30px", sm: "unset" },
                paddingLeft: { xs: "30px", sm: "unset" },
                width: { xs: "100vw", sm: "unset" },
                background: { xs: `linear-gradient(${COLORS.white}, ${COLORS.white}ab)`, sm: "unset" },
                backdropFilter: { xs: "blur(20px)", sm: "unset" },
                display: { xs: "block", lg: "none" }
              }}
            >
              <Box
                component={"h2"}
                sx={{
                  fontSize: { xs: "24px", lg: "40px" },
                  marginBottom: "20px",
                  textAlign: "left"
                }}
              >
                {title}
              </Box>
            </Grid2>
          }
          {description &&
            <Grid2 container alignContent={"flex-start"} xs={12} sx={{ marginBottom: { xs: 0, sm: "50px" } }}>
              <Box
                component={"p"}
                sx={{
                  textAlign: "left",
                  fontSize: { xs: "16px", sm: "20px" },
                  width: "940px"
                }}
              >
                {description}
              </Box>
            </Grid2>
          }
        </Fragment>
      }
      <CustomBreakpoints customBreakpoints={{
        xs: 0,
        sm: 600,
        lg: 1410
      }}>
        <Grid2 container xs={12} lg={7} alignContent={"flex-start"}>
          {locations.map((location, i) => (
            <LocationItem
              key={i}
              location={location}
              index={i}
              locLen={locations.length}
              isNumbered={true}
              imageDisplay={imageDisplay}
              mapEl={true}
            />
          ))}
          <Grid2 sx={{ marginLeft: "30px", marginTop: "40px" }}>
            {contactInfo && contactInfo}
          </Grid2>
        </Grid2>
      </CustomBreakpoints>
    </Grid2 >
  )
}

export default FlagshipLocations;