import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { Fragment, useEffect } from "react";
import { inputWrappers } from "..";
import { STATES } from "../../../../../constants/states";
import withLocalContext from "../../../../store/withLocalContext";
import SubmitBtn from "../../SubmitBtn";
import { TextInput } from "../../Inputs";
import { usePersonInfo } from "../Provider";
import CreditCard from "./CreditCard";
import { useScreenWidth } from "../../../../Common/Functions/useScreenWidth";

const Payment = ({ sectionId, setSectionErrors, showSubmitBtn, total, context: { state: globalState } }) => {
  const {
    state,
    addBillingFirstName,
    addBillingLastName,
    addBillingAddress,
    addBillingApt,
    addBillingCity,
    addBillingState,
    addBillingZipcode
  } = usePersonInfo();

  const lap = useScreenWidth("isLaptop");

  useEffect(() => {
    setSectionErrors(state.sectionErrors)
  })

  return (
    <Grid2 container xs={12}>
      {total !== 0 &&
        <Grid2 xs={12}>
          <h3>Payment</h3>
        </Grid2>
      }
      {
        globalState.orderTotals.total > 0 &&
        <Fragment>
          <Grid2 xs={12} className={`${lap ? "m-b-65" : "m-b-5"}`}>
            <div className={`${inputWrappers}`}>
              <TextInput
                id="billingFirstName"
                maxWidth="33%"
                label="First name on card"
                value={state.billingFirstName}
                onChange={(e) => addBillingFirstName(e.target.value)}
                sectionId={sectionId}
                hasProvider
              />
              <TextInput
                id="billingLastName"
                maxWidth="33%"
                label="Last name on card"
                value={state.billingLastName}
                onChange={(e) => addBillingLastName(e.target.value)}
                sectionId={sectionId}
                hasProvider
              />
            </div>
            <div className={`${inputWrappers}`}>
              <CreditCard />
            </div>
          </Grid2>

          <Grid2 xs={12}>
            <div className={`${inputWrappers}`}>
              <TextInput
                id="billingAddress"
                maxWidth="66%"
                label="Billing Address"
                value={state.billingAddress}
                onChange={(val) => {
                  if (val !== "") {
                    if (val.components.city) {
                      addBillingCity(val.components.city)
                    }
                    if (val.components.state) {
                      addBillingState({ name: val.components.state })
                    }
                    if (val.components.postal_code) {
                      addBillingZipcode(val.components.postal_code)
                    }
                    addBillingAddress(val.short_add)
                  } else {
                    addBillingAddress(val)
                  }
                }}
                sectionId={sectionId}
                type="address"
                hasProvider
              />
              <TextInput
                id="billingApt"
                maxWidth="33%"
                label="Apartment, Suite, ect."
                value={state.billingApt}
                onChange={(e) => addBillingApt(e.target.value)}
                sectionId={sectionId}
                notRequired
                hasProvider
              />
            </div>
            <div className={`${inputWrappers}`}>
              <TextInput
                id="billingCity"
                maxWidth="33%"
                label="City"
                value={state.billingCity}
                onChange={(e) => addBillingCity(e.target.value)}
                sectionId={sectionId}
                hasProvider
              />
              <TextInput
                id={"billingState"}
                maxWidth="33%"
                label="State"
                value={state.billingState}
                onChange={(val) => addBillingState(val[0])}
                sectionId={sectionId}
                containerId={"cardigan_checkout"}
                type="dropdown"
                options={STATES}
                isControlled
                hasProvider
              />
              <TextInput
                id={"billingZipcode"}
                maxWidth="33%"
                label="Zipcode"
                value={state.billingZipcode}
                onChange={(e) => addBillingZipcode(e.target.value)}
                sectionId={sectionId}
                hasProvider
              />
            </div>
          </Grid2>
        </Fragment>
      }
      {showSubmitBtn &&
        <Grid2 container xs={12} sx={lap ? { marginTop: "20px", marginRight: "33px" } : {}} justifyContent={lap ? "flex-end" : "center"}>
          <SubmitBtn total={total} />
        </Grid2>
      }
    </Grid2>
  )
}

export default withLocalContext(Payment);