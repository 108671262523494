import everything from "../assets/billboard/everything.webp";
import goodness from "../assets/billboard/goodness.webp";
import now from "../assets/billboard/now.webp";
import party from "../assets/billboard/party.webp";
// import stamp from "../assets/billboard/stamp.webp";
import warmth from "../assets/billboard/warmth.webp";

import smoothie from "../assets/products/smoothie.webp";
import ice_cream from "../assets/products/ice_cream.webp";
import yogurt_bowl from "../assets/products/yogurt_bowl.webp";
import donut_boxes from "../assets/products/donut_boxes.webp";
import inspired from "../assets/products/inspired.webp";
import latte from "../assets/products/latte.webp";
// import old_fashioned from "../assets/products/old_fashioned.webp";
import schedule from "../assets/products/cardigan_logomark_grayscale.webp";
import dietary_notice from "../assets/products/cardigan_heart_icon.webp";
import { ROUTES } from "./routes";
import { MENU_ARRAY } from "./menuArray";

export const billboardArrDesktop = [
  warmth,
  // stamp,
  party,
  now,
  everything,
  goodness
];
export const billboardArrMobile = [
  warmth,
  // stamp,
  party,
  now,
  everything,
  goodness
];
export const productArr = [
  {
    image: donut_boxes,
    title: "Donut Boxes",
    ariaLabel: "See donuts menu",
    description: "Full dozen, half dozen, you pick or we pick - we’ve got you covered.",
    link: {
      title: "Fill your box",
      href: `${ROUTES.MENU}#${MENU_ARRAY[0].category}`
    },
    dimensions: {
      width: 298,
      height: 192
    }
  },
  {
    image: inspired,
    title: "Inspired Donuts",
    ariaLabel: "See this week’s inspired donuts",
    description: "Our line of signature creations rotate every Monday and Thursday.",
    link: {
      title: "See today’s inspired donuts",
      href: `${ROUTES.MENU}?sub_category=Inspired Donuts#${MENU_ARRAY[0].category}`
    },
    dimensions: {
      width: 220,
      height: 220
    }
  },
  {
    image: yogurt_bowl,
    title: "Yogurt Bowls",
    ariaLabel: "See yogurt bowl menu",
    description: "Greek yogurt bowls filled with fresh fruit, sauces, and additional toppings.",
    link: {
      title: "Build your own",
      href: `${ROUTES.MENU}#${MENU_ARRAY[1].category}`
    },
    dimensions: {
      width: 229,
      height: 223
    }
  },
  {
    image: latte,
    title: "Coffee Drinks",
    ariaLabel: "See coffee and tea menu",
    description: "Make an espresso yours with shot options, milk substitutes, and syrups.",
    link: {
      title: "Customize your caffeine",
      href: `${ROUTES.MENU}#${MENU_ARRAY[2].category}`
    },
    dimensions: {
      width: 232,
      height: 241
    }
  },
  {
    image: smoothie,
    title: "Smoothies",
    ariaLabel: "See smoothie menu",
    description: "Our signature smoothies are blended with fresh fruit and ingredients.",
    link: {
      title: "Choose a blend",
      href: `${ROUTES.MENU}#${MENU_ARRAY[3].category}`
    },
    dimensions: {
      width: 213,
      height: 262
    }
  },
  {
    image: ice_cream,
    title: "Ice Cream",
    ariaLabel: "See ice cream menu",
    description: "Handmade ice cream served between donuts, in a cone, as a sundae, or malt.",
    link: {
      title: "Try a combo",
      href: `${ROUTES.MENU}#${MENU_ARRAY[4].category}`
    },
    dimensions: {
      width: 164,
      height: 240
    }
  },
  // {
  //   image: old_fashioned,
  //   title: "Ship Me Donuts",
  //   ariaLabel: "Get details about shipping donuts",
  //   description: "With second day air, have your favorite donuts shipped to your house or sent to family, friends, or colleagues.",
  //   link: {
  //     title: "Get our donuts across the country",
  //     href: `${ROUTES.MAIL_ME_DONUTS}`
  //       },
  // dimensions: {
  //   width: 231,
  //   height: 224
  // }
  // },
  {
    image: schedule,
    title: "Donut Schedule",
    ariaLabel: "See the upcoming inspired donut schedule",
    description: "Stay up-to-date with our rotating schedule of inspired donuts each week.",
    link: {
      title: "See what’s new",
      href: `${ROUTES.UPCOMING_DONUT_SCHEDULE}`
    },
    dimensions: {
      width: 215,
      height: 217
    }
  },
  {
    image: dietary_notice,
    title: "Dietary Notice",
    ariaLabel: "Read the full dietary notice",
    description: "May contain dairy milk, eggs, wheat, tree nuts and other allergens...",
    link: {
      title: "Keep reading",
      href: `${ROUTES.MENU}`
    },
    dimensions: {
      width: 259,
      height: 206
    }
  }
];