/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import withLocalContext from "../store/withLocalContext";
import MenuItems from "./MenuItems";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Nav from "./Nav";
import styled from "styled-components";
import { isActiveCat, setHeaderTitle, setPopupHTML } from "../store/actions";
import { element_sizes } from "../../constants/elementSizes";
import CartConfiguration from "../Popup/CartConfiguration";
import LoadingState from "../Common/Components/LoadingState";
import { ORDER_PSEUDO_NAMES } from "../../constants/orderTypes";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import AllergenNotice from "./AllergenNotice";
// import MobileMenu from "./MobileMenu";
import CartConfigBanner from "../CartConfigBanner";
import MobileMenu from "../MobileMenu";
import { usePageTracking } from "../Tracking";

const StyledNavContainer = styled.div`
height: calc(100vh - ${element_sizes.headerHgt.large}px);
`;

const StyledMenuContainer = styled.div`
  max-width: 1100px;
`;

export const Menu = ({ context: { state, dispatch } }) => {
  const lap = useScreenWidth("isLaptop");

  // check for presence of has in url
  // set activeCat if there is one
  // otherwise check for presence of query and see if it is cart config
  useEffect(() => {
    if (window.location.search) {
      let query = window.location.search;
      query = query.replace("?", "");
      if (query.includes("cart_configuration")) {
        let queryArr = query.split("=");
        dispatch(setPopupHTML(<CartConfiguration orderType={ORDER_PSEUDO_NAMES.filter(item => item.id === queryArr[1])[0]} pickup={query.includes("same")} />))
      }
      if (query.includes("mobile_category")) {
        dispatch(isActiveCat(""));
      }
    }
  }, []);

  // checking if category hash is present and then scrolling to that category
  useEffect(() => {
    if (!state.isMenuLoading) {
      if (window.location.hash) {
        let hash = window.location.hash.replace("#", "");
        let elToScrollTo = document.getElementById(hash);
        const scrollTo = elToScrollTo.offsetTop - (state.cartConfig ? lap ? 50 : 120 : lap ? 0 : 45) - (state.siteAnnouncement ? element_sizes.announcement : 0);
        window.scrollTo({ top: scrollTo, behavior: "auto" });
        if (!lap) {
          dispatch(setHeaderTitle(state.menu.filter(item => item.category === hash)[0].title));
        }
      }
    }
  }, [state.isMenuLoading, lap]);

  usePageTracking();

  return (
    <Grid2 container className="m-b-120">
      {state.cartConfig &&
        <CartConfigBanner />
      }
      {lap &&
        <StyledNavContainer className={`v-width-20 max-width-300 d-flex p-sticky top-${element_sizes.headerHgt.large + (state.cartConfig ? 56 : 0) + (state.siteAnnouncement ? element_sizes.announcement : 0)}`}>
          <Nav />
        </StyledNavContainer>
        // :
        // <Grid2 container xs={12}>
        //   {/* <MobileMenu /> */}
        // </Grid2>
      }
      <StyledMenuContainer islap={String(lap)} className={`${lap ? "v-width-80" : `v-width-100 m-t-${state.cartConfig ? 70 : 0}`}`}>
        {state.isMenuLoading ?
          <div className={`p-fixed ${lap ? "height-60 m-t-80" : "height-40 m-l-30 m-y-50"}`}>
            <LoadingState loadText="Loading donuts..." />
          </div>
          :
          <Grid2 container className="text-align-left">
            {lap &&
              <Grid2 xs={12} className={state.cartConfig ? "m-t-20" : ""}>
                <AllergenNotice lap={lap} />
              </Grid2>
            }
            <Grid2 xs={12} container id="menu-container" sx={{ minHeight: "100vh", padding: lap ? 0 : "0 25px 25px" }}>
              {state.menu.map((category, i) => (
                <MenuItems key={category.category} index={i} category={category} />
              ))}
            </Grid2>
            {!lap &&
              <Grid2 container justifyContent={"center"} xs={12}>
                <MobileMenu />
              </Grid2>}
          </Grid2>
        }
      </StyledMenuContainer>
    </Grid2>
  );
};

export default withLocalContext(Menu);