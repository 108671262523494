import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect } from "react";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import ImageCard from "./ImageCard";

const MobileImgCarousel = ({ images }) => {
  const tab = useScreenWidth("isTablet");
  useEffect(() => {
    var elem = document.querySelector('.catering-carousel');
    new window.Flickity(elem, {
      draggable: tab ? false : true,
      wrapAround: true,
      imagesLoaded: true,
      pageDots: false,
      prevNextButtons: false
    });
  })

  return (
    <Grid2 container xs={12} sx={{ height: `223px` }} justifyContent="center">
      <div className="catering-carousel carousel p-width-100">
        {images.map(item => (
          <div key={item.title} className="carousel-cell">
            <ImageCard data={item} />
          </div>
        ))}
      </div>
    </Grid2>
  )
}
export default MobileImgCarousel;