import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { setAddToLS } from "../store/actions";
import withLocalContext from "../store/withLocalContext";

const AddSubtract = ({ enableEdit = true, quantity, onClickAdd, onClickSubtract, context: { dispatch } }) => {

  const lap = useScreenWidth("isLaptop");

  return (
    <div className={`height-28 width-fit d-flex align-center justify-between`}>
      {enableEdit &&
        <span
          className={`${lap ? "font-size-30 m-b-9" : "font-size-22 m-b-5"} cursor-pointer line-height-21 p-x-12 user-select-none`}
          onClick={onClickSubtract}
          onClickCapture={() => dispatch(setAddToLS(true))}
          aria-label="Decrease quantity"
        >
          -
        </span>
      }
      <span className={`${lap ? "font-size-16" : "font-size-14"} user-select-none`}>
        {quantity}
      </span>
      {enableEdit &&
        <span
          className="font-size-18 cursor-pointer p-x-12 user-select-none"
          onClick={onClickAdd}
          onClickCapture={() => dispatch(setAddToLS(true))}
          aria-label="Increase quantity"
        >
          +
        </span>
      }
    </div>
  )
}
export default withLocalContext(AddSubtract);