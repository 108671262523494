import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import Slider from '../Common/Components/Slider';
import ease from "easy-ease";
import List from '../Common/Components/List';
import { toUSD } from '../Common/Functions/toUSD';
import { useScreenWidth } from '../Common/Functions/useScreenWidth';

const DONUT_TYPE = [
  {
    id: "classics",
    name: "Classics Only ($1.95 per donut)",
    value: 1.95,
  },
  {
    id: "classics_speciality",
    name: "Classics and Specialty (2/3 Classic $1.95) + (1/3 Specialty $3.95)",
    value: (1.95 / 3 * 2) + (3.95 / 3 * 1),
  }
]

const DELIVERY_OPTIONS = [
  {
    id: "pickup",
    name: "Pickup (Free)",
    value: 0,
  },
  {
    id: "delivery",
    name: "Delivery ($75 first hour, $50 per additional hour)",
    value: 75,
  }
]

const SETUP = [
  {
    id: "setup_yes",
    name: "Yes ($50/hour)",
    value: 50,
  },
  {
    id: "setup_no",
    name: "No",
    value: 0,
  }
]

const StyledContainer = styled(Grid2)`
   label {
    font-size: ${props => props.islap === "true" ? "24px" : "20px"};
  }
  li {
    font-size: ${props => props.islap === "true" ? "24px" : "16px"};
  }
`;

export default function DonutCalulator({ returnEstObj }) {
  const [total, setTotal] = useState(0);

  const lap = useScreenWidth("isLaptop");

  const initialEstimateObj = useMemo(() => {
    return {
      quantity: {
        id: "quantity",
        name: "Quantity",
        value: 0,
      },
      type: DONUT_TYPE[1],
      delivery_opt: DELIVERY_OPTIONS[0],
      setup: SETUP[0]
    }
  }, []);

  const [estimateObj, setEstimateObj] = useState(initialEstimateObj);

  useEffect(() => {
    setEstimateObj(initialEstimateObj)
  }, [initialEstimateObj])


  useEffect(() => {
    ease({
      startValue: Number(total),
      endValue: ((estimateObj.quantity.value * estimateObj.type.value) + estimateObj.delivery_opt.value + estimateObj.setup.value) * 100,
      durationMs: 1000,
      onStep: function (value) {
        setTotal(Number(value).toFixed(0));
      },
    })
    // eslint-disable-next-line
  }, [estimateObj])

  useEffect(() => {
    returnEstObj({ ...estimateObj, total: { value: toUSD(total) } });
    // eslint-disable-next-line
  }, [estimateObj, total,]);

  return (
    <StyledContainer islap={String(lap)} container xs={12} className="text-align-left">
      <Grid2 container xs={12} sx={{ backgroundColor: `${COLORS.tan}33` }} className="p-a-40">
        <div>
          <h3 className={`m-b-18 ${lap ? "" : "font-size-20"}`}>Estimate your cost</h3>
          <p className={`${lap ? "font-size-20" : ""}`}>We recommend a donut quantity equal to 100% or 125% of your expected attendance.</p>
        </div>
        <Grid2 container xs={12} className={`${lap ? "m-t-60" : "m-t-20"}`}>
          <Grid2 container xs={12} md={7} className="height-fit">
            <Grid2 xs={12}>
              <h3>
                <span className={`futura-cond ${lap ? "font-size-24" : "font-size-20"}`}>
                  Donut Quantity
                </span>
                {estimateObj.quantity.value > 0 &&
                  <span className='m-l-3 futura color-brown font-size-18'>({estimateObj.quantity.value})</span>
                }
              </h3>
            </Grid2>
            <Grid2 xs={12}>
              <div className='m-r-25 max-width-450'>
                <Slider
                  quantity={estimateObj.quantity.value}
                  max={400}
                  markIncrements={lap ? 50 : 100}
                  containerId="donutSlider"
                  selectedQuantity={(val) => {
                    setEstimateObj({ ...estimateObj, quantity: { ...estimateObj.quantity, value: val } })
                  }}
                />
              </div>
            </Grid2>
          </Grid2>
          <Grid2 container xs={12} md={5} className={`${lap ? "-m-t-30" : "m-t-30"}`}>
            <List
              category='Delivery or Pickup'
              initialOpt={DELIVERY_OPTIONS[0]}
              options={DELIVERY_OPTIONS}
              singleSelect
              selectedValue={(val) => setEstimateObj({ ...estimateObj, delivery_opt: val[0] })}
            />
          </Grid2>
          <Grid2 container xs={12} md={7} className={`${lap ? "m-t-14" : ""}`}>
            <div className='max-width-500'>
              <List
                category='Donut Type'
                initialOpt={DONUT_TYPE[1]}
                options={DONUT_TYPE}
                singleSelect
                selectedValue={(val) => setEstimateObj({ ...estimateObj, type: val[0] })}
              />
            </div>
          </Grid2>
          <Grid2 container xs={12} md={5} className={`${lap ? "m-t-14" : ""}`}>
            <Grid2 xs={12} md={6}>
              <List
                category='Setup'
                initialOpt={SETUP[0]}
                options={SETUP}
                singleSelect
                selectedValue={(val) => setEstimateObj({ ...estimateObj, setup: val[0] })}
              />
            </Grid2>
            <Grid2 container alignContent={`flex-end"`} justifyContent={`${lap ? "flex-start" : "center"}`} xs={12} md={6} className={`${lap ? "" : "m-t-25"}`}>
              <div className={`${lap ? "m-b-20" : ""} border-3-tan p-x-22 p-y-20`}>
                <p className={`${lap ? "font-size-24" : "font-size-20"} color-blue m-b-8`}>Estimated Total</p>
                <p className={`${lap ? "font-size-30" : "font-size-24"} max-width-224 sentinel`}>{toUSD(total)}</p>
              </div>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </StyledContainer >
  );
}