import { Link, useLocation } from "react-router-dom"
import { COLORS } from "../../constants/colors"
import { ROUTES } from "../../constants/routes"
import { DownArrow } from "../../assets/DownArrow";
import { useEffect, useState } from "react";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";

const CHECKOUT_NAV = [
  {
    title: "Cart",
    route: ROUTES.CART
  },
  {
    title: "Payment + Delivery",
    route: ROUTES.CHECKOUT
  },
  {
    title: "Confirmation",
    route: ROUTES.CONFIRMATION
  }
]

const OrderNav = () => {
  const location = useLocation();
  const lap = useScreenWidth("isLaptop");
  const [isCart, setIsCart] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    if (location.pathname === "/cart") {
      setIsCart(true)
    } else {
      setIsCart(false)
    }
  }, [location.pathname])

  useEffect(() => {
    const { pathname } = location;
    if (!lap) {
      if (pathname === ROUTES.CHECKOUT) {
        setBreadcrumbs([CHECKOUT_NAV[0], CHECKOUT_NAV[1]]);
      }
      if (pathname === ROUTES.CONFIRMATION) {
        setBreadcrumbs([CHECKOUT_NAV[1], CHECKOUT_NAV[2]]);
      }
    } else {
      setBreadcrumbs(CHECKOUT_NAV);
    }
  }, [location, lap])

  return (
    isCart ?
      <Link
        to={ROUTES.MENU}
        className={`color-red ${lap ? "font-size-20" : "font-size-16 "} width-max underline d-flex align-center text-align-left`}
      >
        <span className="rotate-90 d-flex m-r-10"><DownArrow fillColor={COLORS.gold} /></span> <span>Continue Shopping</span>
      </Link>
      :
      <div className="d-flex">
        {breadcrumbs.map((item, i) => (
          <Link
            key={item.title}
            to={item.route}
            className={`${location.pathname === item.route ? "color-red" : "color-brown"} ${item.route === ROUTES.CONFIRMATION ? location.pathname === ROUTES.CONFIRMATION ? "" : "pointer-none" : ""} ${lap ? "font-size-20 width-fit" : "font-size-16 width-max"} m-r-10 underline d-flex align-center text-align-left`}
          >
            <span>{item.title}</span>
            {i !== breadcrumbs.length - 1 &&
              <span className="rotate-270 d-flex m-l-10">
                <DownArrow fillColor={COLORS.gold} />
              </span>
            }
          </Link>
        ))}
      </div>
  )
}

export default OrderNav;