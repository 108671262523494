const Checkbox = ({ isCartConfig, isChecked, tabIndex, singleSelect }) => {
  return (
    <span tabIndex={tabIndex} className={`${singleSelect ? "circle" : "square"}-18 min-width-18 border-brown center m-r-16 cursor-pointer ${isCartConfig && "m-b-35"}`}>
      {isChecked &&
        <span className={`${singleSelect ? "circle" : "square"}-14 m-r-2 m-l-2 background-color-red`}></span>
      }
    </span>
  )
}

export default Checkbox;