import { toUSD } from "../../../../Functions/toUSD";
import cardiganLogomark from "../../../../../../assets/Cardigan_Logomark_Grayscale.png";
import { modiferList } from "./modiferList";
import { variation } from "./variation";
import { donutPicksLineItem } from "./donutPicksLineItem";
import { calcDonutDiscount } from "../../../CalculateTotals";

export const lineItem = (cartItem, state) => {
  const lineItemPrice = cartItem.modifierList ? cartItem.price : cartItem.itemData.variations[0].itemVariationData.priceMoney.amount;
  const discount = calcDonutDiscount(state.cart).discountPct;
  const discountCategories = ["Classic Donuts", "Premium Donuts", "Inspired Donuts"];
  const isDiscount = (discountCategories.includes(cartItem.category.name) && discount !== 0) ? true : false;

  let html = "";
  html += `
  <div style="max-width: 300px; margin: auto;">
    <div style="margin-top: 40px; margin-left: 20px; text-align: left;">
      <p style="font-family: Sentinel; color: #624C42;">${cartItem.name}</p>
    </div>
    <div style="display: flex;">
      <div style="margin-right: 5px;">
        <img style="height: 83px; width: 83px; object-fit: cover;" src=${cartItem.imageUrl ? `${cartItem.imageUrl}` : `${cardiganLogomark}`} alt=${cartItem.name}/>
      </div>
      <div>
        <p style="color: #624C42; font-family: futura; text-align: left; position: relative; height: fit-content; top: 50%; transform: translate(0,-50%);">
          ${isDiscount ?
      `<span style="height: 2px; rotate: -10deg; width: 50%; background-color: #D85028; border-radius: 50px; display: flex; position: absolute; top: 10px;"></span>`
      :
      ""}
        <span style="color: ${isDiscount ? "#808080;" : "#624C42;"}">${toUSD(lineItemPrice * cartItem.quantity)}</span>
        ${isDiscount ?
      `<span style="margin-left: 15px;">${toUSD((lineItemPrice * cartItem.quantity) - (discount * (lineItemPrice * cartItem.quantity)))}</span>`
      : ""}
        </p>
      </div>
    </div>
  <div>
    ${cartItem.variation ?
      variation(cartItem.variation)
      : ""
    }
    ${cartItem.modifierList ?
      modiferList(cartItem.modifierList)
      : ""
    }
    ${cartItem.donutPicks ?
      donutPicksLineItem(cartItem.donutPicks, state.donutPickItems.filter(el => el.name === cartItem.itemData.name)[0])
      : ""
    }
  </div>`;

  return html;
}