import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import CardiganLogomark from "../../assets/Cardigan_Logomark_Grayscale.png";
import MenuCard from "./MenuCard";
import { ROUTES } from "../../constants/routes";
import { element_sizes } from "../../constants/elementSizes";
import { COLORS } from "../../constants/colors";
import withLocalContext from "../store/withLocalContext";
import { useEffect, useState } from "react";
import { disableItem } from "./disableItem";

export const StyledGrid2 = styled(Grid2)`
  background: linear-gradient(${COLORS.white}, ${COLORS.white}ab);
  backdrop-filter: blur(20px);
  box-shadow: 0px 3px 7px 0px #fffafa;
`;

const MenuCategories = ({ subCategory, index, context: { state } }) => {

  const lap = useScreenWidth("isLaptop");
  const tab = useScreenWidth("isTablet");
  const menuCardWidth = lap ? 210 : 127;
  const navigate = useNavigate();
  const [catIsAvail, setCatIsAvail] = useState(false);

  useEffect(() => {
    if (state.cartConfig) {
      let disableRes = disableItem(state.cartConfig, subCategory.items[0].orderTypes);
      setCatIsAvail(disableRes);
    }
  }, [state.cartConfig, subCategory.items])

  return (
    <Grid2
      key={subCategory.subCategory}
      container
      xs={12}
      justifyContent={tab ? "flex-start" : "center"}
      sx={lap ? {} : { marginTop: index === 0 ? "0px" : "30px" }}
    >
      <StyledGrid2
        xs={12}
        sx={lap ? {} :
          {
            width: "100vw",
            marginX: "-30px"
          }}
        className={`z-index-1 p-sticky top-${lap ? `${element_sizes.headerHgt.large + (state.cartConfig ? 52 : 0)} height-55` : `${state.cartConfig ? 148 : 78} height-35`} m-b-10 d-flex align-center`}
      >
        <h2
          className={`color-red p-y-10 width-fit ${lap ? "" : "m-l-20 font-size-20"} ${state.cartConfig ? catIsAvail ? "" : "opacity-5" : ""}`}>
          {subCategory.subCategory}
        </h2>
        {!subCategory.tags.includes("none") &&
          <div className={`${lap ? "m-l-10" : " m-l-10"}`}>
            {subCategory.tags.map(tag => (
              <span style={{ whiteSpace: "nowrap" }} className={`${tag === "Pick-up only" ? "brown-tag" : "gold-tag"} m-r-10`} key={tag}>{tag}</span>
            ))}
          </div>
        }
      </StyledGrid2>
      <Grid2
        id={subCategory.subCategory}
        container
        xs={12}
        justifyContent={tab ? "flex-start" : "space-between"}
        sx={tab ? {} :
          {
            maxWidth: "330px",
          }}
      >
        {subCategory.items.map(item => (
          <MenuCard key={item.id} item={item} tags={subCategory.tags} index={index} menuCardWidth={menuCardWidth} />
        ))}
        {subCategory.subCategory === "Inspired Donuts" &&
          <Grid2 justifyContent={"center"} xs={"auto"} xl={3} className={`${lap ? "m-b-48" : " m-b-18 m-x-20"}`}>
            <span className="cursor-pointer" onClick={() => navigate(ROUTES.UPCOMING_DONUT_SCHEDULE)}>
              <div className={`width-${menuCardWidth} height-${menuCardWidth}`}>
                <img className={`max-width-${menuCardWidth}`} alt="Cardigan Logomark" src={CardiganLogomark} />
              </div>
              <div className="color-brown underline height-60">
                <p className="text-align-center">Donut schedule</p>
              </div>
            </span>
          </Grid2>
        }
      </Grid2>
    </Grid2>
  )
}

export default withLocalContext(MenuCategories);