export const STATES = [
  {
    "id": "Alabama",
    "name": "AL"
  },
  {
    "id": "Alaska",
    "name": "AK"
  },
  {
    "id": "Arizona",
    "name": "AZ"
  },
  {
    "id": "Arkansas",
    "name": "AR"
  },
  {
    "id": "California",
    "name": "CA"
  },
  {
    "id": "Colorado",
    "name": "CO"
  },
  {
    "id": "Connecticut",
    "name": "CT"
  },
  {
    "id": "Delaware",
    "name": "DE"
  },
  {
    "id": "District of Columbia",
    "name": "DC"
  },
  {
    "id": "Florida",
    "name": "FL"
  },
  {
    "id": "Georgia",
    "name": "GA"
  },
  {
    "id": "Hawaii",
    "name": "HI"
  },
  {
    "id": "Idaho",
    "name": "ID"
  },
  {
    "id": "Illinois",
    "name": "IL"
  },
  {
    "id": "Indiana",
    "name": "IN"
  },
  {
    "id": "Iowa",
    "name": "IA"
  },
  {
    "id": "Kansa",
    "name": "KS"
  },
  {
    "id": "Kentucky",
    "name": "KY"
  },
  {
    "id": "Lousiana",
    "name": "LA"
  },
  {
    "id": "Maine",
    "name": "ME"
  },
  {
    "id": "Maryland",
    "name": "MD"
  },
  {
    "id": "Massachusetts",
    "name": "MA"
  },
  {
    "id": "Michigan",
    "name": "MI"
  },
  {
    "id": "Minnesota",
    "name": "MN"
  },
  {
    "id": "Mississippi",
    "name": "MS"
  },
  {
    "id": "Missouri",
    "name": "MO"
  },
  {
    "id": "Montana",
    "name": "MT"
  },
  {
    "id": "Nebraska",
    "name": "NE"
  },
  {
    "id": "Nevada",
    "name": "NV"
  },
  {
    "id": "New Hampshire",
    "name": "NH"
  },
  {
    "id": "New Jersey",
    "name": "NJ"
  },
  {
    "id": "New Mexico",
    "name": "NM"
  },
  {
    "id": "New York",
    "name": "NY"
  },
  {
    "id": "North Carolina",
    "name": "NC"
  },
  {
    "id": "North Dakota",
    "name": "ND"
  },
  {
    "id": "Ohio",
    "name": "OH"
  },
  {
    "id": "Oklahoma",
    "name": "OK"
  },
  {
    "id": "Oregon",
    "name": "OR"
  },
  {
    "id": "Pennsylvania",
    "name": "PA"
  },
  {
    "id": "Rhode Island",
    "name": "RI"
  },
  {
    "id": "South Carolina",
    "name": "SC"
  },
  {
    "id": "South Dakota",
    "name": "SD"
  },
  {
    "id": "Tennessee",
    "name": "TN"
  },
  {
    "id": "Texas",
    "name": "TX"
  },
  {
    "id": "Utah",
    "name": "UT"
  },
  {
    "id": "Vermont",
    "name": "VT"
  },
  {
    "id": "Virginia",
    "name": "VA"
  },
  {
    "id": "Washington",
    "name": "WA"
  },
  {
    "id": "West Virginia",
    "name": "WV"
  },
  {
    "id": "Wisconsin",
    "name": "WI"
  },
  {
    "id": "Wyoming",
    "name": "WY"
  }
]