import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { COLORS } from "../../constants/colors";
import withLocalContext from "../store/withLocalContext";
import { isActiveCat, setHeaderTitle } from "../store/actions";
import { element_sizes } from "../../constants/elementSizes";

const MobileMenu = ({ context: { state, dispatch } }) => {

  return (
    <Grid2
      container
      xs={12}
      justifyContent="center"
      alignContent="center"
      sx={{
        position: "fixed",
        bottom: "20px",
        width: "calc(100% - 24px)",
        margin: "auto",
        border: `2px solid ${COLORS.blue}`,
        borderRadius: "40px",
        height: "75px",
        zIndex: "300",
        // backgroundColor: COLORS.white + "e6",
        backgroundColor: COLORS.white + "d9",
        backdropFilter: "blur(4px)"
      }}
    >
      {state.menu.map(item => (
        <img
          key={item.title}
          alt={item.ariaLabel}
          className="m-x-7 height-50 max-width-58 object-fit-contain"
          src={state.activeCat === item.category ? item.mobileActiveIcon : item.mobileIcon}
          onClick={() => {
            dispatch(setHeaderTitle(item.title));
            dispatch(isActiveCat(item.category));
            window.scrollTo(0, (document.getElementById(item.category).offsetTop - ((state.cartConfig ? 120 : 50) + (state.siteAnnouncement ? element_sizes.announcement : 0))));
          }}
        />
      ))}
    </Grid2>
  )
}
export default withLocalContext(MobileMenu);