import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import "./stylesheets/App.scss";
import ComponentRoutes from "./components/Routes";
// import { router } from "./components/Routes";
import Ctx from "./components/store/Ctx";
import { theme } from "./materialTheme";
import GlobalLoad from "./components/GlobalLoad";
import Header from "./components/Header";
import { element_sizes } from "./constants/elementSizes";
import { COLORS } from "./constants/colors";
import LocalStorage from "./components/LocalStorage";
import Footer from "./components/Footer";
import Announcement from "./components/Announcement";

const App = () => {
  // Get the root element
  let root = document.querySelector(':root');

  function setElementSizes() {
    // Inspiration from here: https://www.w3schools.com/css/css3_variables_javascript.asp
    Object.entries(element_sizes).map(element => {
      let sizeKeys = Object.keys(element[1]);
      return sizeKeys.map(item => {
        return root.style.setProperty(`--${item}-${element[0]}`, element[1][item] + "px");
      })
    });

    Object.entries(COLORS).map(color => {
      return root.style.setProperty(`--${color[0]}`, color[1]);
    });
  };

  useEffect(() => {
    setElementSizes();
  });

  return (
    <div id="App" className="App" >
      <ThemeProvider theme={theme}>
        <Ctx>
          <Router>
            {/* <RouterProvider router={router} /> */}
            <GlobalLoad />
            <LocalStorage />
            <Header />
            <Announcement />
            <ComponentRoutes />
            <Footer />
          </Router>
        </Ctx>
      </ThemeProvider>
    </div>
  );
};

export default App;
