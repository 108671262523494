import { MENU_ARRAY } from "../../../constants/menuArray";

export const findCategory = (subCategory) => {
  // if subCategory exists in MENU_ARRAY, return the category
  let category = MENU_ARRAY.find(category => {
    return category.subCategories.find(item => {
      return item.subCategory === subCategory;
    })
  });
  return category.category;
};