import City_Center from "../assets/locations/Cardigan_Donuts_City_Center.webp";
import IDS from "../assets/locations/Cardigan_Donuts_IDS_Center.webp";
import FRGMNT_Mill from "../assets/locations/FRGMNT_Mill.webp";
import FRGMNT_North_Loop from "../assets/locations/FRGMNT_North_Loop.webp";
import FRGMNT_St_Anthony from "../assets/locations/FRGMNT_St_Anthony.webp";
// import FRGMNT_EP from "../assets/locations/FRGMNT_EP.webp";
import Getaway_Motor_Cafe from "../assets/locations/Getaway_Motor_Cafe.webp";
import { ORDER_PSEUDO_NAMES, specificOrderTypes } from "./orderTypes";

export const FLAGSHIP_LOCATIONS = [
  {
    name: "City Center",
    address: "40 South 7th St. #207 Minneapolis, MN 55402",
    title: "City Center Downtown",
    addressLink: "https://maps.app.goo.gl/RiB6Z2pwyxf1CL9NA",
    lngLat: {
      lat: 44.977881503394144,
      lng: -93.27307204325604,
    },
    hours: [
      {
        days: "Mon - Fri",
        times: "6:30am - 5:30pm",
      },
      {
        days: "Saturday",
        times: "8:00am - 3:00pm",
      },
      {
        days: "Sunday",
        times: "Closed",
      },
    ],
    orderAvailibility: [
      // military time is used for start and end times
      {
        day: "Monday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[0].id,
            startTime: "8:00",
            endTime: "15:00",
          },
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "8:00",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Tuesday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[0].id,
            startTime: "8:00",
            endTime: "15:00",
          },
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "8:00",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Wednesday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[0].id,
            startTime: "8:00",
            endTime: "15:00",
          },
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "8:00",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Thursday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[0].id,
            startTime: "8:00",
            endTime: "15:00",
          },
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "8:00",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Friday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[0].id,
            startTime: "8:00",
            endTime: "15:00",
          },
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "8:00",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Saturday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[0].id,
            startTime: "8:00",
            endTime: "15:00",
          },
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "8:00",
            endTime: "17:00",
          },
        ],
      },
    ],
    image: City_Center,
    isSkyway: true,
    orderTypes: [
      specificOrderTypes.preDel,
      specificOrderTypes.prePickCC,
      specificOrderTypes.sameCC,
    ],
  },
  {
    name: "IDS Center",
    address: "80 South 8th St. #226 Minneapolis, MN 55402",
    title: "IDS Center Downtown",
    addressLink: "https://maps.app.goo.gl/s4Zt8msdUw1qjEJF8",
    lngLat: {
      lat: 44.976163527215405,
      lng: -93.27231755092396,
    },
    hours: [
      {
        days: "Mon - Fri",
        times: "7:00am - 5:30pm",
      },
      {
        days: "Saturday",
        times: "Closed",
      },
      {
        days: "Sunday",
        times: "Closed",
      },
    ],
    orderAvailibility: [
      // military time is used for start and end times
      {
        day: "Monday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[0].id,
            startTime: "7:30",
            endTime: "15:00",
          },
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Tuesday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[0].id,
            startTime: "7:30",
            endTime: "15:00",
          },
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Wednesday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[0].id,
            startTime: "7:30",
            endTime: "15:00",
          },
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Thursday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[0].id,
            startTime: "7:30",
            endTime: "15:00",
          },
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Friday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[0].id,
            startTime: "7:30",
            endTime: "15:00",
          },
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
    ],
    image: IDS,
    isSkyway: true,
    orderTypes: [specificOrderTypes.prePickIDS, specificOrderTypes.sameIDS],
  },
];

export const PARTNER_LOCATIONS = [
  {
    name: "FRGMNT (North Loop)",
    address: "729 Washington Ave N Minneapolis, MN 55401",
    title: "FRGMNT Coffee (North Loop)",
    addressLink: "https://maps.app.goo.gl/SetaTA38Wd86fysRA",
    lngLat: {
      lat: 44.98810553944603,
      lng: -93.27791911566648,
    },
    hours: [
      {
        days: "Mon - Fri",
        times: "6:30am - 6:00pm",
      },
      {
        days: "Saturday",
        times: "7:00am - 6:00pm",
      },
    ],
    orderAvailibility: [
      // military time is used for start and end times
      {
        day: "Monday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Tuesday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Wednesday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Thursday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Friday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Saturday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "8:30",
            endTime: "17:00",
          },
        ],
      },
    ],
    image: FRGMNT_North_Loop,
    isSkyway: false,
    orderTypes: [specificOrderTypes.prePickFRG],
  },
  {
    name: "FRGMNT (St. Anthony Main)",
    address: "43 SE Main St Minneapolis, MN 55414",
    title: "FRGMNT Coffee (St. Anthony Main)",
    addressLink: "https://maps.app.goo.gl/8GTdxB6AUu227Qe56",
    lngLat: {
      lat: 44.986133967347826,
      lng: -93.25837107334323,
    },
    hours: [
      {
        days: "Mon - Fri",
        times: "6:30am - 6:00pm",
      },
      {
        days: "Saturday",
        times: "7:00am - 6:00pm",
      },
    ],
    orderAvailibility: [
      // military time is used for start and end times
      {
        day: "Monday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Tuesday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Wednesday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Thursday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Friday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Saturday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "8:30",
            endTime: "17:00",
          },
        ],
      },
    ],
    image: FRGMNT_St_Anthony,
    isSkyway: false,
    orderTypes: [specificOrderTypes.prePickFRG],
  },
  {
    name: "FRGMNT (Mill District)",
    address: "1011 Washington Ave S, Minneapolis, MN 55415",
    title: "FRGMNT Coffee (Mill District)",
    addressLink: "https://maps.app.goo.gl/j6Q5ctShPjskiXm56",
    lngLat: {
      lat: 44.975695733285534,
      lng: -93.25379103666475,
    },
    hours: [
      {
        days: "Mon - Fri",
        times: "6:30am - 6:00pm",
      },
      {
        days: "Sat, Sun",
        times: "7:00am - 6:00pm",
      },
    ],
    orderAvailibility: [
      // military time is used for start and end times
      {
        day: "Monday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Tuesday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Wednesday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Thursday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Friday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "7:30",
            endTime: "17:00",
          },
        ],
      },
      {
        day: "Saturday",
        orderType: [
          {
            id: ORDER_PSEUDO_NAMES[1].id,
            startTime: "8:30",
            endTime: "17:00",
          },
        ],
      },
    ],
    image: FRGMNT_Mill,
    isSkyway: false,
    orderTypes: [specificOrderTypes.prePickFRG],
  },
];

export const ORDER_FROM_LOCATIONS = [
  ...FLAGSHIP_LOCATIONS,
  ...PARTNER_LOCATIONS,
];

export const WEEKEND_POPUPS = [
  {
    title: "Getaway Motor Cafe",
    image: Getaway_Motor_Cafe,
    address: "120 Broadway Street Carver, MN 55315",
    addressLink: "https://maps.app.goo.gl/QNnFpNdphULkEfNs9",
    hours: [
      {
        days: "Saturday",
        times: "8:00am until sold out",
      },
    ],
    lngLat: {
      lat: 44.76239937428445,
      lng: -93.62405891153364,
    },
  },
  // {
  //   title: "FRGMNT Coffee Eden Prairie",
  //   image: FRGMNT_EP,
  //   address: "11095 Viking Dr, Eden Prairie, MN 55344",
  //   addressLink: "https://maps.app.goo.gl/BSLYTKHMyF9vuhoM7",
  //   hours: [
  //     {
  //       days: "Saturday",
  //       times: "8:00am until sold out"
  //     },
  //   ],
  //   lngLat: {
  //     lat: 44.86203593666299,
  //     lng: -93.41780846735227
  //   }
  // }
];

export const BAKERY_CASES = [
  {
    title: "Gray Fox LaSalle Plaza",
    image: "",
    address: "800 LaSalle Ave Minneapolis, MN 55402",
    addressLink: "https://maps.app.goo.gl/5zysybwtgqdURH3w6",
    hours: [
      {
        days: "Mon - Fri",
        times: "7:30am - 2:00pm",
      },
    ],
    lngLat: {
      lat: 44.97644549357814,
      lng: -93.27461707714869,
    },
  },
  {
    title: "Gray Fox 901 Marquette",
    image: "",
    address: "901 S Marquette Ave, Minneapolis, MN 55402",
    addressLink: "https://maps.app.goo.gl/AhTtxywJhNM3uRRN8",
    hours: [
      {
        days: "Mon - Fri",
        times: "7:30am - 2:30pm",
      },
    ],
    lngLat: {
      lat: 44.97428948828276,
      lng: -93.27166141394126,
    },
  },
  {
    title: "Gray Fox Deluxe Plaza",
    image: "",
    address: "801 Marquette Ave S Minneapolis, MN 55402",
    addressLink: "https://maps.app.goo.gl/pLHTqYN7QdCytziT7",
    hours: [
      {
        days: "Mon & Fri",
        times: "7:30am - 11:30am",
      },
      {
        days: "Tue - Thur",
        times: "7:30am - 6:00pm",
      },
    ],
    lngLat: {
      lat: 44.97514834520685,
      lng: -93.27144097472888,
    },
  },
];
