import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { DownArrow } from "../../../assets/DownArrow";
import { adjustMenu } from "../Functions/adjustMenu";
import { COLORS } from "../../../constants/colors";
import withLocalContext from "../../store/withLocalContext";
import { useScreenWidth } from "../Functions/useScreenWidth";
import { StyledLabel } from "../Functions/StyledComponents";

const StyledSpan = styled.span`
  transition: rotate .2s;
`;

export const Dropdown = (
  { options = [],
    category = "",
    containerId = "",
    catId = "",
    initialOpt = "",
    selectedValue,
    cannotChangeVal, // sets whether the value can be changed internally
    sendDonutPicksValue,
    isCartConfig,
    numbered,
    styles = {},
    isControlled,
    isDisabled,
    tabIndex,
    color,
  }
) => {

  const lap = useScreenWidth("isLaptop");
  const [initialValue, setInitialValue] = useState(initialOpt.name);
  const [isVisible, setIsVisible] = useState(false);
  const [priorValue, setPriorValue] = useState(initialOpt);
  const colorStyles = color === "blue" ?
    {
      text: "color-white",
      menuText: "color-blue",
      background: "background-color-blue",
      border: "border-blue",
      activeBorder: "border-hoverBlue",
      borderL: "border-l-blue",
      activeBorderL: "border-l-hoverBlue",
      borderB: "border-b-blue",
      arrow: COLORS.white
    }
    :
    {
      text: "color-brown",
      menuText: "color-brown",
      background: "background-color-white",
      border: "border-brown",
      activeBorder: "border-red",
      borderL: "border-l-brown",
      activeBorderL: "border-l-red",
      borderB: "border-b-brown",
      arrow: COLORS.red
    };

  const saveDonutPicksVal = (opt) => {
    if (sendDonutPicksValue) {
      sendDonutPicksValue({ previous: priorValue, new: opt });
    }
    setPriorValue(opt);
  }

  useEffect(() => {
    if (isControlled) {
      setInitialValue(initialOpt.name)
    }
  }, [initialOpt, isControlled]);

  const ulId = "ul_" + catId;
  return (
    <Grid2 container flexDirection={"column"} className={`${isCartConfig ? "m-t-0" : sendDonutPicksValue ? "m-t-15" : styles.contMT ? styles.contMT : "m-t-30"} ${isDisabled ? "pointer-none opacity-75" : ""} text-align-left p-relative`}>
      {category &&
        <StyledLabel className="futura-cond m-b-15">{category}</StyledLabel>
      }
      <ClickAwayListener onClickAway={() => setIsVisible(false)}>
        <div
          tabIndex={tabIndex}
          className={`p-relative ${styles.contWidth ? styles.contWidth : "min-width-200"} ${styles.pointer ? styles.pointer : ""}`}
          onClick={() => {
            setIsVisible(!isVisible);
            adjustMenu(containerId, catId, ulId, !isVisible, lap);
          }}
        >
          {numbered &&
            <span className="p-absolute -left-35 top-15 width-25 text-align-right color-blue">{numbered}</span>
          }
          <div
            id={catId}
            className={`${isVisible ? colorStyles.activeBorder : `z-index-1 ${colorStyles.border}`} ${colorStyles.background} ${isCartConfig ? `p-l-5 p-r-28 ${lap ? "height-40" : "height-27"}` : styles.disContHeight ? styles.disContHeight : `${lap ? "p-x-34 height-45" : "p-x-14 height-38"}`} d-flex align-center justify-between cursor-pointer p-relative`}
          >
            <span className={`${styles.disPadL ? styles.disPadL : "m-r-20"} ${colorStyles.text} ${styles.fontSize ? styles.fontSize : ""}`}>
              {initialValue}
            </span>
            <span className={isCartConfig && `${isVisible ? colorStyles.activeBorderL : colorStyles.borderL} p-height-100`}>
              <StyledSpan
                className={`${styles.arrWidth ? styles.arrWidth : "width-15"}  d-flex p-absolute ${isCartConfig ? `${lap ? "top-14 right-9" : "top-8 right-7"}` : `${lap ? styles.arrowTop ? styles.arrowTop : "top-16" : styles.arrowTop ? styles.arrowTop : "top-13"} right-9`} ${isVisible ? "rotate-180" : "rotate-0"}`}
              >
                <DownArrow fillColor={colorStyles.arrow} />
              </StyledSpan>
            </span>
          </div>
          <ul
            id={ulId}
            className={`p-absolute m-t-5 background-color-white p-l-0 p-width-100 max-width-493 max-height-300 transition ${isVisible ? `height-${options.length * (lap ? 45 : 39)} ${colorStyles.border} dropdown-menu-box-shadow z-index-300 overflow-scroll` : "height-0 border-white overflow-hidden border-transparent"}`}
            name={category}
          >
            {options.map((opt, i) => (
              <li
                key={opt.id ? opt.id : i}
                onClick={() => {
                  if (!cannotChangeVal) {
                    setInitialValue(initialOpt.name === "let the store pick" ? opt.itemData.name : opt.name);
                  }
                  selectedValue && selectedValue([opt]);
                  saveDonutPicksVal && saveDonutPicksVal(opt);
                }}
                className={`${i !== options.length - 1 && colorStyles.borderB} ${lap ? "height-45" : "height-38 line-height-16"} p-l-15 d-flex align-center ${colorStyles.menuText} cursor-pointer dropdown-item list-style-none background-color-white`}
              >
                {initialOpt.name === "let the store pick" ? opt.itemData.name : opt.name}
              </li>
            ))}
          </ul>
        </div>
      </ClickAwayListener>
    </Grid2>
  )
}

export default withLocalContext(Dropdown);