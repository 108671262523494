
export const setPageDots = (id, carousel, numOfSlides) => {

  if (carousel) {

    let flicktyCar = document.getElementById(id);
    const btnConClass = id + "btnCon";
    const oldBtnCon = document.getElementsByClassName(btnConClass);
    if (oldBtnCon) {
      [...oldBtnCon].map(el => {
        return flicktyCar.removeChild(el);
      })
    }
    const btnDiv = document.createElement('div');
    btnDiv.classList.add(btnConClass, "center", "m-t-10");

    for (let i = 0; i < numOfSlides; i++) {
      let btn = document.createElement("button");
      btn.ariaLabel = `Page ${i + 1}`;
      btn.classList.add((i === 0 ? "page-dot-selected" : "page-dot"), "m-x-5");
      btn.id = btnConClass + i;
      btn.onclick = () => {
        carousel.select(i, true, true);
      };
      btnDiv.appendChild(btn);
    }

    document.getElementById(id).appendChild(btnDiv);
    carousel.on('change', () => changeBtnBackground());

    const changeBtnBackground = () => {
      let btnCon = document.getElementsByClassName(btnConClass);
      btnCon = [...btnCon][0];
      if (!btnCon.children) return;
      [...btnCon.children].map((item, i) => {
        if (i === carousel.selectedIndex) {
          item.classList.replace("page-dot", "page-dot-selected");
        } else {
          item.classList.replace("page-dot-selected", "page-dot");
        }
        return item;
      })
    }
  }
}