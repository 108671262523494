import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect } from "react";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { setHeaderTitle } from "../store/actions";
import withLocalContext from "../store/withLocalContext"
import ScheduledDonuts from "./ScheduledDonuts";
import { usePageTracking } from "../Tracking";

const UpcomingDonuts = ({ context: { dispatch } }) => {

  const lap = useScreenWidth("isLaptop");
  const title = "Upcoming Donut Schedule";

  useEffect(() => {
    dispatch(setHeaderTitle(title));
  }, [dispatch])

  usePageTracking();

  return (
    <Grid2 container xs={12} className={`${lap ? "p-r-75 p-l-75" : "p-r-30 p-l-30"} m-t-86 m-b-65 text-align-left`}>
      <Grid2 xs={12}>
        {lap &&
          <h1>{title}</h1>
        }
        <p className={`${lap ? "font-size-24" : ""} m-t-28`}>Each Inspired donut flavor is available in a limited quantity and will be made fresh each day for one week.</p>
        <p className={`${lap ? "font-size-24" : ""} m-t-5`}>Check out our upcoming rotating flavors below.</p>
      </Grid2>
      <Grid2 xs={12}>
        <ScheduledDonuts />
      </Grid2>
    </Grid2>
  )
}
export default withLocalContext(UpcomingDonuts);