import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Fragment, useState } from "react";
import TimeDropdown from "../../Common/Components/TimeDropdown";
import { setBanner, setCartConfig, setPopupHTML } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";

const OutOfStock = ({ context: { state, dispatch } }) => {
  const [time, setTime] = useState("");

  const choosePickupTime = () => {
    dispatch(setCartConfig({ ...state.cartConfig, time: time }))
    dispatch(setBanner("Order Time adjusted"));
    dispatch(setPopupHTML(""));
  }

  return (
    <Grid2 container className="max-width-400" justifyContent={"center"}>
      {!state.availableForSameDay ?
        <Fragment>
          <Grid2 container justifyContent={"center"}>
            <h3 className="m-b-15">Same Day Pick-up Not Available!</h3>
            <p className="font-size-20 m-b-10">To continue to checkout, change your order type.</p>
            <button
              className="red-button max-width-100"
              onClick={() => dispatch(setPopupHTML(""))}
            >
              Ok
            </button>
          </Grid2>
        </Fragment>
        :
        <Fragment>
          <Grid2 xs={12}>
            <h3 className="m-b-15">Unavailable Pick-up Time!</h3>
            <p className="font-size-20 m-b-10">The pick-up time you chose is no longer available!</p>
            <div className="height-2 m-y-20 background-color-tan" />
            <p className="font-size-20">Please choose a new pick-up time.</p>
          </Grid2>
          <Grid2 xs={12} container justifyContent={"center"} className="m-t-20">
            <TimeDropdown
              orderType={state.cartConfig.orderType}
              selectedValue={(val) => setTime(val)}
            />
          </Grid2>
          <Grid2 xs={12} container justifyContent={"center"} className="m-t-30 popup-button">
            <div className="p-width-40">
              <button
                className="red-button-flat"
                onClick={() => dispatch(setPopupHTML(""))}
              >
                Cancel
              </button>
            </div>
            <div className="p-width-60">

              <button
                className="red-button"
                onClick={() => choosePickupTime()}
              >
                Choose pick-up time
              </button>
            </div>
          </Grid2>
        </Fragment>
      }

    </Grid2>
  )
}

export default withLocalContext(OutOfStock);