import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import dateImg from "../../../assets/Date_Black.webp";
import { ClickAwayListener } from "@material-ui/core";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import styled from "styled-components";
import { COLORS } from "../../../constants/colors";
import withLocalContext from "../../store/withLocalContext";
// import Holidays from "date-holidays";
import LoadingState from "./LoadingState";
import { useScreenWidth } from "../Functions/useScreenWidth";
import { adjustMenu } from "../Functions/adjustMenu";

const StyledArrowContainer = styled.span`
  background-color: ${COLORS.red};
  >svg {
    height: 15px;
    color: ${COLORS.white};
  }
`;

const StyledDateNum = styled.span`
  &:hover {
    background-color:#8080804f;
  }
`;
const calWidth = 345;
const StyledSpanClasses = "d-flex border-radius-5 height-24 align-center cursor-pointer";
const spanDayClasses = `text-align-center font-size-12 width-${Math.floor(calWidth / 7)} color-brown`;

const dayNames = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

const Calendar = ({ calDate, containerId, date, title, subtitle, isDisabled, tabIndex, cartConfig, context: { state } }) => {
  const [days, setDays] = useState([]);
  const [month, setMonth] = useState(dayjs());
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [blackoutDates, setBlackoutDates] = useState([]);
  const [isSkyway, setIsSkyway] = useState(false);
  const [isIDS, setIDS] = useState(false);

  const lap = useScreenWidth("isLaptop");
  // const holidays = new Holidays("US");
  const calAnchorId = "calendar-anchor";
  const calPopupId = "calendar-popup";
  const rows = 6;
  const columns = 7;

  useEffect(() => {
    listDays();
  }, [])

  const listDays = () => {
    const tempDayArr = [];
    let day = dayjs().startOf('month');
    while (tempDayArr.length < (3 * 365)) {
      tempDayArr.push(day);
      day = dayjs(day).add(1, 'day');
    }
    setDays(tempDayArr);
  }

  const setAnimation = (direction) => {
    let count = 0;
    let el = document.getElementById("animateDays");
    if (direction === "left") {
      el.style.left = (el.offsetLeft + 300) + "px";
    } else {
      el.style.left = (el.offsetLeft - 350) + "px";
    }

    el.addEventListener('transitionend', function () {
      if (count === 0) {
        el.style.opacity = 0;
        if (direction === "left") {
          el.style.left = (el.offsetLeft - 700) + "px";
        } else {
          el.style.left = (el.offsetLeft + 700) + "px";
        }
      }
      if (count === 1) {
        el.style.opacity = 1;
        if (direction === "left") {
          el.style.left = (el.offsetLeft + 400) + "px";
        } else {
          el.style.left = (el.offsetLeft - 350) + "px";
        }
      }
      count++;
    })
  }

  useEffect(() => {
    if (state.siteData.blackout_dates) {
      const sortedBlackoutDates = state.siteData.blackout_dates[0].items.sort();
      setBlackoutDates(sortedBlackoutDates);
    }
  }, [state.siteData]);


  // Check if IDS has been selected
  useEffect(() => {
    const location = cartConfig?.location;
    if (location === 'IDS Center') {
      setIDS(true);
    } else {
      setIDS(false);
    }
  }, [cartConfig]);

  useEffect(() => {
    if (cartConfig) {
      // logic for finding delivery skyway
      let tempIsSkyway = false;
      if (cartConfig.orderType.id === "delivery") {
        if (cartConfig.deliveryType.name.includes("Skyway")) {
          tempIsSkyway = true;
        }
      }
      setIsSkyway(tempIsSkyway);
    }
  }, [cartConfig])

  return (
    state.isMenuLoading ?
      <div className="height-38">
        <LoadingState />
      </div>
      :
      <ClickAwayListener onClickAway={() => setIsVisible(false)}>
        <div
          id="calendar"
          className={`p-relative highlight-none ${isDisabled ? "pointer-none opacity-75" : "cursor-pointer"}`}
          tabIndex={tabIndex}
        >
          <div
            id={calAnchorId}
            style={{ overflowX: "hidden" }}
            className={`${lap ? "height-45 p-x-15" : "height-34 p-x-8"} border-brown d-flex align-center justify-between`}
            onClick={() => {
              setIsVisible(!isVisible);
              adjustMenu(containerId, calAnchorId, calPopupId, !isVisible, lap);
            }}
          >
            <span className="color-brown">
              {
                dayjs(date).format("MM/DD/YY")
              }
            </span>
            <span>
              <img
                className={`${lap ? "max-width-25" : "max-width-17"}`}
                src={dateImg}
                alt=""
              />
            </span>
          </div>
          <div
            id={calPopupId}
            className={`text-align-left p-a-10 p-absolute background-color-white transition ${isVisible ? `height-300 width-${calWidth} dropdown-menu-box-shadow z-index-260 overflow-scroll` : "height-0 width-0 -z-index-10 overflow-hidden"}`}
          >
            {title &&
              <h5 className={`${isVisible ? "color-red" : "color-transparent"} font-size-16 m-b-8`}>{title}</h5>
            }
            {subtitle &&
              <p className="font-size-16 color-brown">{subtitle}</p>
            }
            <div className="d-flex justify-between align-center m-t-8">
              <div
                className={`p-a-5 ${!canGoBack && "pointer-none opacity-5"}`}
                onClick={() => {
                  setMonth(dayjs(month).subtract(1, "month"));
                  setCanGoForward(dayjs(month).startOf("month").isBefore(dayjs(days[days.length - 1]), 'day'));
                  setCanGoBack(dayjs(month).subtract(2, "month").endOf("month").isAfter(dayjs(days[0]), 'day'));
                  setAnimation("left");
                }}
              >
                <StyledArrowContainer
                  className={`${StyledSpanClasses}`}
                >
                  <ArrowBackIosNewIcon />
                </StyledArrowContainer>
              </div>
              <span className="color-red">{dayjs(month).format("MMMM YYYY")}</span>
              <div
                className={`p-a-5 ${!canGoForward && "pointer-none opacity-5"}`}
                onClick={() => {
                  setMonth(dayjs(month).add(1, "month"));
                  setCanGoForward(dayjs(month).add(2, "month").startOf("month").isBefore(dayjs(days[days.length - 1]), 'day'));
                  setCanGoBack(dayjs(month).endOf("month").isAfter(dayjs(days[0]), 'day'));
                  setAnimation("right");
                }}
              >
                <StyledArrowContainer
                  className={`${StyledSpanClasses} rotate-180`}
                >
                  <ArrowBackIosNewIcon />
                </StyledArrowContainer>
              </div>
            </div>
            <div className="d-flex justify-evenly m-t-5">
              {dayNames.map(day => (
                <span key={day} className={`${spanDayClasses}`}>{day}</span>
              ))}
            </div>
            <div id="animateDays" className=" d-flex flex-wrap justify-evenly height-160 m-t-8">
              {
                Array(rows * columns).fill('a').map((a, i) => (
                  <span
                    key={i}
                    className={`p-height-20 center ${spanDayClasses}`}
                  >
                    {days.map((day, e) => (
                      dayjs(day).year() === dayjs(month).year() &&
                      dayjs(day).month() === dayjs(month).month() &&
                      dayjs(day).date() + dayjs(month).startOf('month').day() === i + 1 &&
                      <StyledDateNum
                        key={e}
                        onClick={() => {
                          // setDate(day);
                          setIsVisible(false);
                          calDate(day);
                        }}
                        className={`p-a-5 width-16 height-16 border-radius-5 center cursor-pointer
                      ${dayjs(date).isSame(dayjs(day), "day") ? "background-color-red color-white" :
                            dayjs(day).day() === 0 ||
                              dayjs(day).isBefore(dayjs().add(1, 'd'), "day") ||
                              blackoutDates.includes(dayjs(day).format("YYYY-MM-DD")) ||
                              (isSkyway && dayjs(day).day() === 6) ||
                              (isIDS && dayjs(day).day() === 6) ? "color-gold opacity-5 pointer-none" : "color-brown"
                          }
                      `}
                      >
                        {dayjs(day).date()}
                      </StyledDateNum>
                    ))}
                  </span>
                ))}
            </div>
          </div>
        </div>
      </ClickAwayListener>
  )
}

export default withLocalContext(Calendar);