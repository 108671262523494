import { useEffect, useState } from "react";
import styled from "styled-components";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import info from "../../assets/info.svg";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";

// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_flip_card

const StyledInfo = styled.img`
  transform: ${props => props.islap === "true" ? "scale(1.5)" : "scale(1.1)"} ;
`;

const FlipCardFrontBackStyles = `
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

const FlipCard = styled.div`
  background-color: transparent;
  perspective: 1000px;
`;

const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

  ${props => props.isimage === "false" && `
    transform: rotateY(180deg);
  `}
`;
const FlipCardFront = styled.div`
  ${FlipCardFrontBackStyles}

`;
const FlipCardBack = styled.div`
${FlipCardFrontBackStyles}
  transform: rotateY(180deg);
`;


const ImageCard = ({ data, idx }) => {
  const [isImage, setIsImage] = useState(true);
  const lap = useScreenWidth("isLaptop");
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setHeight(Math.round(window.innerWidth * .28));
    setWidth(Math.round(height * .94));
  }, [lap, height])

  return (
    <FlipCard
      className={`${lap ? `width-${width} height-${height} m-x-12 m-t-15` : "width-210 height-223 m-x-12"} `}
      onClick={() => setIsImage(!isImage)}
    >
      <FlipCardInner
        isimage={String(isImage)}
        className={`cursor-pointer user-select-none`}
      >
        <FlipCardFront className="p-width-100 p-height-100 p-relative">
          <img alt={data.title} src={data.image} className="p-width-100 p-height-100 object-fit-cover" />
          <StyledInfo islap={String(lap)} alt="show description" src={info} className={`p-absolute ${lap ? "top-20 right-20" : "top-10 right-10"}`} />
        </FlipCardFront>
        <FlipCardBack className="p-width-100 p-height-100 font-size-20 color-brown background-color-cream overflow-scroll">
          <CloseRoundedIcon aria-label="See photo" className={`float-right m-l-10 m-b-10 ${lap ? "max-width-30 m-t-20 m-r-20" : "max-width-20 m-t-15 m-r-10"}`} />
          <p className={`${lap ? "p-a-20 font-size-20 line-height-30" : "p-a-10 font-size-14"} text-align-left`}>{data.text}</p>
        </FlipCardBack>
      </FlipCardInner>
    </FlipCard>
  )
}

export default ImageCard;