import donutGif from "../../../assets/donut_fill_empty.gif";
import { useScreenWidth } from "../Functions/useScreenWidth";

const LoadingState = ({ loadText }) => {
  const lap = useScreenWidth("isLaptop");
  return (
    <div className="d-flex align-center p-height-100">
      {loadText &&
        <p className={`futura-cond m-r-10 ${lap ? "font-size-25" : "font-size-18"}`}>{loadText}</p>
      }
      <img alt="donut gif" src={donutGif} className="width-auto p-height-100" />
    </div>
  )
}

export default LoadingState;