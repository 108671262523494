export const sortDonuts = (donuts) => {

  let classics = [];
  let premiums = [];
  let inspireds = [];
  donuts.map(item => {
    if (item.category.name.includes("Classic")) {
      classics.push(item);
    } else if (item.category.name.includes("Premium")) {
      premiums.push(item);
    } else if (item.category.name.includes("Inspired")) {
      inspireds.push(item);
    }
    return item;
  })
  return { classics: classics, premiums: premiums, inspireds: inspireds };
}