import { v4 as uuidv4 } from 'uuid';

export const manageCart = (cart, item) => {

  let product = item.item;

  // if adding item to cart
  if (item.quantity === 1) {
    product.cartItemId = uuidv4();;

    // if item is coming from modifier or donutpicks popup
    if (product.modifierList || product.donutPicks) {
      cart.push({ ...product, quantity: 1 });
    } else {
      // logic for regular item
      // if it does not exist in cart, then add it
      // if it does exist then up the quantity one
      let filteredCart = cart.filter(item => item.id === product.id);
      if (filteredCart.length > 0) {
        cart.splice(cart.findIndex(item => item.id === product.id), 1, { ...product, quantity: filteredCart[0].quantity + 1 })
      } else {
        cart.push({ ...product, quantity: 1, name: product.itemData.name });
      }
    }
  }

  // if removing item from cart
  if (item.quantity === -1) {
    // if item is coming from modifier popup
    if (product.modifierList || product.donutPicks) {
      cart.splice(cart.findIndex(item => item.id === product.id), 1)
    } else {
      // logic for regular item
      // if it does not exist in cart, then add it
      // if it does exist then up the quantity one
      let filteredCart = cart.filter(item => item.id === product.id);
      if (filteredCart.length > 0) {
        if (filteredCart[0].quantity - 1 === 0) {
          cart.splice(cart.findIndex(item => item.id === product.id), 1)
        } else {
          cart.splice(cart.findIndex(item => item.id === product.id), 1, { ...product, quantity: filteredCart[0].quantity - 1 })
        }
      }
    }
  }
  if (item.quantity === -1000) {
    cart.splice(cart.findIndex(item => item.id === product.id), 1)
  }

  if (item.quantity === 0) {
    cart.splice(cart.findIndex(el => el.cartItemId === product.cartItemId), 1, { ...product, quantity: 1 })
  }

  return cart;
}