import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import { checkPromo } from "../../../services";
import { setOrderDiscount } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";
import GiftCard from "../../GiftCard";
import styled from "styled-components";
import { COLORS } from "../../../constants/colors";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";

const OPTIONS = [
  "Add Gift Card",
  "Add Promo Code"
];

const StyledOptBtn = styled.button`
  &:hover {
      background-color: ${props => props.isactive === "true" ? COLORS.hoverRed : COLORS.hoverWhite};
  }
`;

const GiftCardPromo = ({ context: { dispatch, state } }) => {
  const [activeIdx, setActiveIdx] = useState(0);
  const [validPromo, setValidPromo] = useState(false);
  const [promoToCheck, setPromoToCheck] = useState("");
  const [promoCheckRes, setPromoCheckRes] = useState("");
  const lap = useScreenWidth("isLaptop");

  const validatePromo = async () => {
    setPromoCheckRes("Checking code...")
    let promoRes = await checkPromo({ promo: promoToCheck });
    if (promoRes.data.message) {
      setPromoCheckRes(promoRes.data.message);
    } else {
      dispatch(setOrderDiscount({ ...promoRes.data[0].discountData, id: promoRes.data[0].id }));
      setPromoCheckRes("Promo code applied!");
      setValidPromo(true);
    }
  }

  const removePromo = () => {
    dispatch(setOrderDiscount(""));
    setPromoToCheck("");
    setValidPromo(false);
    setPromoCheckRes("");
  }

  return (
    <Grid2 container xs={12} className={`${lap ? "m-b-47" : "m-b-20"}`}>
      <Grid2 container xs={12} flexDirection="row">
        {OPTIONS.map((opt, i) => (
          <Grid2
            key={opt}
            onClick={() => setActiveIdx(i)}
            sx={{ width: "calc(50% - 5px)", }}
            className={`${i === 0 ? "m-r-5" : "m-l-5"}`}
          >
            <StyledOptBtn
              isactive={String(activeIdx === i)}
              className={`p-width-100 font-size-16 height-36 center ${activeIdx === i ? "background-color-red color-white" : "border-2-brown color-brown"} ${lap ? "" : "p-x-5"}`}
            >
              {opt}
            </StyledOptBtn>
          </Grid2>
        ))}
      </Grid2>
      <Grid2 container xs={12} className="m-t-22">
        <h3 className="font-size-20">{OPTIONS[activeIdx].replace(/add/i, "")}</h3>
      </Grid2>
      {activeIdx === 0 ?
        <Grid2 container xs={12}>
          <GiftCard />
        </Grid2>
        :
        <Grid2 container xs={12}>
          <input
            className="thin-input border-brown m-t-5 p-width-100"
            placeholder="add code..."
            value={promoToCheck}
            onChange={(e) => setPromoToCheck(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" ? validatePromo() : null}
          />
          <div className="d-flex justify-between p-width-100 m-t-13">
            <span className="color-gold">{promoCheckRes}</span>
            <span
              className={`${promoToCheck ? "opacity-1" : "opacity-5 pointer-none"} brown-link font-size-16`}
              onClick={() => {
                if (validPromo) {
                  removePromo();
                } else {
                  validatePromo();
                }
              }}
            >
              {validPromo ? "Remove" : "Check code"}
            </span>
          </div>
        </Grid2>
      }
    </Grid2>
  )
}

export default withLocalContext(GiftCardPromo);