import { useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import withLocalContext from "../../store/withLocalContext";
import { modifyCart, setAddToLS, setBanner, setCartConfig, setPopupHTML } from "../../store/actions";
import { useNavigate } from "react-router-dom";
import DonutPicks from "../DonutPicks";
import Modifiers from "../Modifiers";
import { disableItem } from "../../Menu/disableItem";
import OrderTypeItemsUnavailable from "../OrderTypeItemsUnavailable";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";
import { ORDER_PSEUDO_NAMES } from "../../../constants/orderTypes";
import { isAfter12PM } from "../../Common/Functions/timeChecks";
import { findCategory } from "../../Common/Functions/findCategory";
import { MENU_ARRAY } from "../../../constants/menuArray";
import Message from "../Message";

/**
 * Renders a submit button for the cart configuration form.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.type - The type of configuration.
 * @param {Object} props.config - The configuration data.
 * @param {boolean} props.isError - Flag indicating if there is an error.
 * @param {function} props.setIsError - Function to set the error flag.
 * @param {boolean} props.canTriggerfromMenuItem - Flag indicating if an item popup can be triggered.
 * @param {Object} props.context - The context object.
 * @param {Object} props.context.state - The state object from the context.
 * @param {function} props.context.dispatch - The dispatch function from the context.
 * @returns {JSX.Element} The submit button component.
 */
const SubmitBtn = ({ type, config, isError, setIsError, canTriggerfromMenuItem, context: { state, dispatch } }) => {

  const lap = useScreenWidth("isLaptop");
  const [errorMsg, setErrorMsg] = useState();
  const navigate = useNavigate();

  const checkSelections = () => {

    let payload = {};

    switch (type.id) {
      case "same":
        // inputs are locations and time
        // check for locations
        if (config.location) {
          payload = {
            location: config.location,
            time: config.time,
            orderType: config.orderType
          };
        } else {
          setIsError(true);
          setErrorMsg("Please select a location");
          return false;
        }
        break;
      case "prepick":
        // inputs are location, date, and time
        // check for locations
        if (config.location) {
          payload = {
            location: config.location,
            time: config.time,
            date: config.date,
            orderType: config.orderType
          };
        } else {
          setIsError(true);
          setErrorMsg("Please fill out all fields");
          return false;
        }
        break;
      case "delivery":
        // inputs are delivery type, date, time, and address
        // check for delivery type and address
        if (config.address && config.deliveryType) {
          payload = {
            date: config.date,
            deliveryType: config.deliveryType,
            address: config.address,
            orderType: config.orderType
          };

          if (config.deliveryType.name.includes("Skyway")) {
            payload.time = config.time;
          }

        } else {
          setIsError(true);
          setErrorMsg("Please fill out all fields");
          return false;
        }
        break;
      case "Ship Me Donuts":
        console.log(4);
        // TBD
        break;
      default:
        console.log(5);
        break;
    }
    setIsError(false);
    return { payload: payload, canSubmit: true };
  };

  /**
   * Checks the availability of items in the cart based on the configuration and order type.
   * If any item is unavailable, it sets a flag and collects the unavailable items in an array.
   * @returns {Object} An object containing the flag indicating whether to proceed and the array of unavailable items.
   */
  const checkAvailibility = () => {
    let showItemsUnavailablePopup = false;
    let tempArr = [];
    state.cart.map(item => {
      let isAvailable = disableItem(config, item.orderTypes);
      if (config.orderType.id === ORDER_PSEUDO_NAMES[0].id &&
        findCategory(item.category.name) === MENU_ARRAY[0].category &&
        isAfter12PM()
      ) {
        isAvailable = false;
      }
      if (!isAvailable) {
        showItemsUnavailablePopup = true;
        tempArr.push(item);
      }
      return item;
    })
    return { doNotProceed: showItemsUnavailablePopup, items: tempArr };
  }

  const submitForm = () => {
    let checkRes = { canSubmit: false };
    checkRes = checkSelections();
    if (checkRes.canSubmit) {
      let availRes = checkAvailibility();
      if (config.orderType.id === ORDER_PSEUDO_NAMES[0].id &&
        isAfter12PM() &&
        (!!canTriggerfromMenuItem &&
          findCategory(canTriggerfromMenuItem.category.name) === MENU_ARRAY[0].category
        )
      ) {
        const title = "Item Unavailable";
        const message = <p className="font-size-20">We're sorry, but we are unable to accept donut orders for same day pickup after 12pm. Please select a different delivery type.</p>;
        dispatch(setPopupHTML(<Message title={title} message={message} />));
      } else if (!availRes.doNotProceed) {
        dispatch(setAddToLS(true));
        dispatch(setCartConfig(checkRes.payload));
        if (canTriggerfromMenuItem) {
          if (state.donutPickItems.some(el => el.name === canTriggerfromMenuItem.itemData.name)) {
            dispatch(setPopupHTML(<DonutPicks data={canTriggerfromMenuItem} donutPicks={state.donutPickItems.filter(el => el.name === canTriggerfromMenuItem.itemData.name)[0]} />))
          } else {
            if (canTriggerfromMenuItem.itemData.modifierListInfo) {
              dispatch(setPopupHTML(<Modifiers data={canTriggerfromMenuItem} />))
            } else {
              dispatch(modifyCart({ item: canTriggerfromMenuItem, quantity: 1 }));
              dispatch(setPopupHTML(""));
              dispatch(setBanner(`Added ${canTriggerfromMenuItem.name} to your cart!`))
              navigate(window.location.pathname);
            }
          }
        } else {
          navigate(window.location.pathname);
          dispatch(setPopupHTML(""));
        }
      } else {
        dispatch(setPopupHTML(<OrderTypeItemsUnavailable proposedConfig={checkRes.payload} items={availRes.items} />));
      }
    }
  }

  return (
    <Grid2 container flexDirection={"column"} sx={{ margin: lap ? "unset" : "auto" }} className={`p-relative`}>
      <button
        onClick={() => submitForm()}
        className={`red-button ${lap ? "min-width-200" : "min-width-100"}`}
      >
        Confirm
      </button>
      {isError &&
        <span className="futura-cond background-color-white p-absolute width-max top-56 right-0">{errorMsg}</span>
      }
    </Grid2>
  )
}

export default withLocalContext(SubmitBtn);