import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import styled from "styled-components";
import { ORDER_FROM_LOCATIONS } from "../../../constants/locations";
import { ORDER_PSEUDO_NAMES } from "../../../constants/orderTypes";
import { setPopupHTML } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";

const StyledUL = styled.ul`
li {
    list-style: disc;
    list-style-type: disc;
  }
`;

const NotAvailable = ({ item, tags, context: { dispatch } }) => {

  const filterLocation = (type) => {
    let location = "";
    if (type.includes("CC")) {
      location = ORDER_FROM_LOCATIONS.filter(el => el.name === "City Center")[0];
    } else if (type.includes("IDS")) {
      location = ORDER_FROM_LOCATIONS.filter(el => el.name === "IDS Center")[0];
    } else if (type.includes("FRGMNT")) {
      location = ORDER_FROM_LOCATIONS.filter(el => el.name === "FRGMNT")[0];
    }

    return {
      name: ORDER_PSEUDO_NAMES.filter(el => el.pseudoNames.includes(type))[0].name,
      location: location
    };
  }

  return (
    <Grid2 container xs={12} sx={{ textAlign: "left", fontSize: "20px" }}>
      <Grid2 xs={12}>
        <h3 className="font-size-20">This item is only available for</h3>
      </Grid2>
      {item.orderTypes.map(el => (
        <Grid2 key={el} xs={12} sx={{ padding: "0 20px" }}>
          {el.includes("Pick") &&
            <StyledUL>
              <li>
                <p className="m-t-20">
                  pick-up today, at
                </p>
                <p className="futura-cond">
                  {filterLocation(el).location.name}
                </p>
              </li>
            </StyledUL>
          }
        </Grid2>
      ))}
      <Grid2 xs={12} sx={{ marginTop: "20px" }}>
        <p className="font-size-20">To add this item, change the pick-up location.</p>
        <button
          onClick={() => dispatch(setPopupHTML(""))}
          className="red-button max-width-100 m-t-20"
        >
          Ok
        </button>
      </Grid2>
    </Grid2>
  )
}
export default withLocalContext(NotAvailable);