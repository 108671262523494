import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

const StyledLink = styled.a`
${props => props.iswhite === "true" && `
  color: ${COLORS.white};
`}
`;

const StyledIcon = styled(OpenInNewIcon)`
  height: 18px;
`;

const ExternalLink = ({ text, href, ariaLabel, tabIndex, classes, onClick }) => {
  return (
    <StyledLink
      iswhite={String(classes.includes("color-white"))}
      href={href}
      className={`link d-flex ${classes}`}
      target="_blank"
      aria-label={ariaLabel}
      rel="noopener noreferrer"
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {text}
      <StyledIcon
        className='m-l-3'
      />
    </StyledLink>
  )
}

export default ExternalLink;