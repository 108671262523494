import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";
import { setPopupHTML } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";

const OrderErrorMessage = ({ context: { dispatch } }) => {
  const lap = useScreenWidth("isLaptop");
  return (
    <Grid2 container xs={12} sx={{ maxWidth: "300px" }}>
      <Grid2 xs={12}>
        <h3 className={`${lap ? "font-size-25" : "font-size-16"} m-b-25`}>
          An Error Occured
        </h3>
        <p className="font-size-18 m-b-10 color-blue">Please try your payment again.</p>
        <p className="font-size-18 m-b-10 color-blue">If this issue persists, give us a call to place your order over the phone: <br /><a className="color-blue" href="tel:6122597894">612-259-7804</a></p>
      </Grid2>
      <div className="center p-width-100 m-t-30">
        <button
          className="red-button max-width-80"
          onClick={() => {
            window.scrollTo(0, 0);
            window.location.reload();
            dispatch(setPopupHTML(""));
          }}
        >
          Okay
        </button>
      </div>
    </Grid2>
  )
}
export default withLocalContext(OrderErrorMessage);