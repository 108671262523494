import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Fragment, useEffect, useState } from "react";
import { addEventsRow, sendEmail } from "../../services";
import DiamondPageHeader from "../Common/Components/DiamondPageHeader";
import Form from "../Common/Components/Form";
import DonutCalulator from "../DonutCalulator";
import withLocalContext from "../store/withLocalContext"
import Contact from "./Contact";
import { FORM_DATA } from "./formData";

import pic1 from "../../assets/Connor-Amanda-Dinner.webp";
import pic2 from "../../assets/Mollie-Alex-Reception.webp";
import pic3 from "../../assets/couple-eating-donuts.webp";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { setHeaderTitle } from "../store/actions";
import { usePageTracking } from "../Tracking";

const imgClasses = "object-fit-cover p-width-100 object-position-top";
const title = "Weddings + Events";
const imageHtmlContent = <Fragment>
  <img alt="" src={pic1} className={`-m-r-60 ${imgClasses}`} />
  <img alt="" src={pic2} className={imgClasses} />
  <img alt="" src={pic3} className={imgClasses} />
</Fragment>

const diamondHtmlContent = (desk) => <h1 className={`color-white font-size-${desk ? 54 : 45} max-width-250`}>{title}</h1>;

const Weddings = ({ context: { dispatch, state } }) => {
  const desk = useScreenWidth("isDesktop");
  const lap = useScreenWidth("isLaptop");
  const [estDonutObj, setEstDonutObj] = useState("");

  const submitWeddingForm = async (data) => {
    let templateContent = [];
    Object.keys(estDonutObj).map(item => {
      return templateContent.push({
        name: item,
        content: item === "quantity" || item === "total" ? estDonutObj[item].value : estDonutObj[item].name
      });
    });
    data.template_content = data.template_content.concat(templateContent);

    let fullName = data.template_content.filter(item => item.name === "Full_Name")[0];
    fullName = fullName.content.split(" ");
    fullName = fullName[0];
    data.template_content = [...data.template_content, { name: "First_Name", content: fullName }]
    let emailRes = "";
    const tempNameArr = data.template_name;

    for (let i = 0; i < tempNameArr.length; i++) {
      const element = tempNameArr[i];
      data.template_name = element;
      if (i === 1) {
        const fromInfo = state.siteData.contact_info[0].filter(item => item.filter(el => el.content === "Wedding Events")[0]);
        data.message.to = [];
        fromInfo.map(item => {
          return data.message.to.push({ email: item.filter(item => item.header === "Email")[0].content });
        })
        data.message.to.push({ email: "justin@cardigandonuts.com", type: "cc" });

        const { subject, ...rest } = data.message;
        data.message = rest;
      } else {
        data.message.subject = `Cardigan Donuts catering request for ${data.template_content.filter(item => item.name === "Date_of_Event")[0].content}`;
      }

      emailRes = sendEmail({ data: data });
    }
    await addEventsRow({ data: data.template_content });
    return emailRes;
  }

  useEffect(() => {
    dispatch(setHeaderTitle(title))
  }, [dispatch])

  usePageTracking();

  return (
    <Grid2 container className={`m-b-100 ${lap ? "p-l-100 p-r-100 m-t-100" : "p-l-30 p-r-30"}`}>
      <Grid2 xs={12} sx={lap ? {} : { marginX: "-30px", width: "calc(100% + 60px)" }}>
        <DiamondPageHeader imageHtmlContent={imageHtmlContent} diamondHtmlContent={diamondHtmlContent(desk)} />
      </Grid2>
      <Grid2 container xs={12} sx={{ maxWidth: "1120px" }} className={`${lap ? "p-r-40 p-l-40 m-t-86 m-b-65" : "m-t-25 m-b-35"}`}>
        <p className={`text-align-left futura ${lap ? "line-height-31 font-size-24" : ""}`}>
          Nothing says 'happily ever after' like a customized selection from Cardigan Donuts on your special day. We are happy to assist with delivery, set-up, and display options. Check out the resources below to get started!
        </p>
      </Grid2>
      <Grid2 container xs={12} justifyContent="center" sx={lap ? {} : { marginX: "-30px", width: "calc(100% + 60px)" }}>
        <DonutCalulator returnEstObj={(val) => setEstDonutObj(val)} />
        <Grid2 container sx={{}} xs={12} className="p-l-35 p-r-35 m-t-65">
          <Form
            data={FORM_DATA}
            submitData={(val) => submitWeddingForm(val)}
          />
          <Contact />
        </Grid2>
      </Grid2>
    </Grid2>
  )
}
export default withLocalContext(Weddings);

