import { useEffect } from 'react'
import { isActiveCat, setHeaderTitle } from '../../store/actions';
import withLocalContext from '../../store/withLocalContext';
import { useScreenWidth } from './useScreenWidth';

const SetCatIntersection = ({ element, context: { dispatch } }) => {

  const lap = useScreenWidth("isLaptop");

  function buildThresholdList() {
    let thresholds = [];
    let numSteps = 25;

    for (let i = 1.0; i <= numSteps; i++) {
      let ratio = i / numSteps;
      thresholds.push(ratio);
    }
    thresholds.push(0);

    return thresholds;
  }

  useEffect(() => {

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: buildThresholdList()
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    let lastScrollTop = 0;

    function handleIntersect(entries) {

      // https://code-paper.com/javascript/examples-js-check-if-user-scrolling-up-or-down-on-mobile
      entries.forEach((entry) => {
        let scrollTop = window.pageYOffset;
        // scrolling down
        if (scrollTop > lastScrollTop) {
          if (lap) {
            if (entry.boundingClientRect.top < 700 && entry.boundingClientRect.top > 550) {
              dispatch(isActiveCat(entry.target.id))
            }
          } else {
            if (entry.boundingClientRect.top < 550 && entry.boundingClientRect.top > 450) {
              dispatch(isActiveCat(entry.target.id));
              if (entry.target.dataset.category) {
                dispatch(setHeaderTitle(entry.target.dataset.category));
              }
            }
          }
        } else { // scrolling up
          if (lap) {
            if (entry.boundingClientRect.bottom < 600 && entry.boundingClientRect.bottom > 450) {
              dispatch(isActiveCat(entry.target.id));
            }
          } else {
            if (entry.boundingClientRect.bottom < 450 && entry.boundingClientRect.bottom > 200) {
              dispatch(isActiveCat(entry.target.id))
              if (entry.target.dataset.category) {
                dispatch(setHeaderTitle(entry.target.dataset.category));
              }
            }
          }
        }
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
      });
    }

    const cleanUpEl = element.current;

    cleanUpEl && observer.observe(cleanUpEl);

    return () => observer.unobserve(cleanUpEl);
  }, [element, dispatch, lap]);

  return null;
};
export default withLocalContext(SetCatIntersection);