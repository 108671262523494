import { calcNumLineItems } from "../components/Common/Functions/CalculateTotals";
import { ORDER_PSEUDO_NAMES } from "./orderTypes";
import { ROUTES } from "./routes";

const STATIC_MENU = [
  {
    title: "Upcoming Donuts",
    link: `${ROUTES.UPCOMING_DONUT_SCHEDULE}`
  }
];

export const menu = (menu) => {
  let menuArr = [];
  menu.map(item => {
    return menuArr.push({
      title: item.title,
      link: `${ROUTES.MENU}#${item.category}`
    })
  })
  menuArr = [...menuArr, ...STATIC_MENU];
  return menuArr;
}

export let ORDER = [
  {
    title: "Pick-up In-store",
    link: `${ROUTES.MENU}?cart_configuration=${ORDER_PSEUDO_NAMES.filter(item => item.id === "same")[0].id}`
  },
  {
    title: "Get a delivery",
    link: `${ROUTES.MENU}?cart_configuration=${ORDER_PSEUDO_NAMES.filter(item => item.id === "delivery")[0].id}`
  },
  // {
  //   title: "Mail Me Donuts",
  //   link: `${ROUTES.MAIL_ME_DONUTS}`
  // },
  {
    title: "Gift Cards",
    link: `${ROUTES.SQUARE_GIFT_CARD}`
  }
];

export const CATERING = [
  {
    title: "Weddings + Events",
    link: ROUTES.WEDDINGS
  },
  {
    title: "Corporate Catering",
    link: ROUTES.CORPORATE_EVENTS
  }
];

export const ABOUT_US = [
  {
    title: "Locations",
    link: ROUTES.GET_IN_TOUCH
  },
  {
    title: "Job Openings",
    link: ROUTES.JOB_OPENINGS
  },
  {
    title: "Our Story",
    link: ROUTES.ABOUT_US
  }
];

export const mobileMenu = (cart) => {
  const MOBILE_MENU = [
    {
      title: "Home",
      link: ROUTES.LANDING_PAGE
    },
    {
      title: `Cart ${cart.length > 0 ? `(${calcNumLineItems(cart)})` : ""}`,
      link: ROUTES.CART
    },
    {
      title: "Menu",
      titleLink: `${ROUTES.MENU_CATEGORIES}?mobile_category`,
      pages: [
        {
          title: "See upcoming donuts",
          link: ROUTES.UPCOMING_DONUT_SCHEDULE
        }
      ]
    },
    {
      title: "Order",
      pages: ORDER
    },
    {
      title: "Catering",
      pages: CATERING
    },
    {
      title: "About us",
      pages: ABOUT_US
    }
  ];
  return MOBILE_MENU;
}