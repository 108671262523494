import { Fragment, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Link } from "react-router-dom";
import styled from "styled-components";
import withLocalContext from "../store/withLocalContext";
import { DownArrow } from "../../assets/DownArrow";
import { COLORS } from "../../constants/colors";
import { ROUTES } from "../../constants/routes";
import { setPopupHTML } from "../store/actions";
import CartConfiguration from "../Popup/CartConfiguration";
import { calcNumLineItems } from "../Common/Functions/CalculateTotals";
import ExternalLink from "../Common/Components/ExternalLink";
import { ORDER_PSEUDO_NAMES } from "../../constants/orderTypes";

const commonLinkStyles = `
width: calc(100% - 40px);
color: ${COLORS.white} !important;
  &:hover {
    background-color: #b0511b;
  }
`;

const StyledLink = styled(Link)`
  ${commonLinkStyles}
`;

const StyledImg = styled.span`
  transition: rotate .2s;
  ${(props) => props.rotate === "true" && `
  rotate: 180deg;
  `}
`;

const combinedStyles = `
  color: ${COLORS.white};
`;

const combinedClasses = "futura-cond font-size-20";

const StyledTitle = styled.p`
${combinedStyles}
`;

const StyledTitleLink = styled(Link)`
${combinedStyles}
`;

const NavMenu = ({ title, items, tabIndex, context: { state, dispatch } }) => {
  const [isVisible, setIsVisible] = useState(false);

  const whenOnMenu = (input) => {

    if (document.location.pathname === ROUTES.MENU) {
      if (input.link.includes("#")) {
        let hash = input.link.split("#");
        hash = hash[hash.length - 1];
        let pageLocation = document.getElementById(hash).offsetTop;
        window.scrollTo(0, pageLocation);

      } else if (input.link.includes("cart_configuration")) {
        let query = input.link.split("=");
        query = query[query.length - 1];
        dispatch(setPopupHTML(<CartConfiguration orderType={ORDER_PSEUDO_NAMES.filter(item => item.id === query)[0]} pickup={query.includes("same")} />))
      }
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setIsVisible(false)}>
      <div className="p-relative" >
        {items ?
          <Fragment>
            <div onClick={() => setIsVisible(!isVisible)} className="d-flex cursor-pointer">
              <StyledTitle
                className={`${combinedClasses} m-a-0`}
                tabIndex={tabIndex}
                onKeyDown={(val) => {
                  if (val.key === "Enter") {
                    setIsVisible(!isVisible)
                  }
                }}
              >
                {title}
              </StyledTitle>
              <StyledImg rotate={String(isVisible)} className="m-l-5 center"><DownArrow fillColor={COLORS.gold} /></StyledImg>
            </div>
            <Grid2
              sx={{
                left: "50%",
                transform: "translate(-50%)",
                transition: "all .2s",
                filter: "drop-shadow(5px -5px 25px rgba(0, 0, 0, 0.25))"
              }}
              className={`${isVisible ? `height-${items.length * 45}` : "height-0"} d-block z-index-1 overflow-hidden p-absolute top-40 width-max text-align-left`}
            >
              {items.map((item, i) => (
                item.link.includes("http") ?
                  <ExternalLink
                    onClick={() => setIsVisible(!isVisible)}
                    classes={`cursor-pointer text-decoration-none font-size-20 d-flex color-white background-color-red p-x-20 p-y-10`}
                    key={i += item.link}
                    href={item.link}
                    text={item.title}
                    tabIndex={tabIndex + 1 + i}
                  />
                  :
                  <StyledLink
                    onClick={() => {
                      whenOnMenu(item);
                      setIsVisible(!isVisible)
                    }}
                    state={{ fromNav: true }}
                    className={`cursor-pointer text-decoration-none font-size-20 d-block background-color-red p-x-20 p-y-10`}
                    key={i += item.link}
                    to={item.link}
                    tabIndex={tabIndex + 1 + i}
                  >
                    {item.title}
                  </StyledLink>
              ))}
            </Grid2>
          </Fragment>
          :
          <StyledTitleLink
            className={`${combinedClasses} text-decoration-none`}
            to={title.link}
            state={{ fromNav: true }}
            tabIndex={tabIndex}
          >
            {title.text}
            {title.text === "cart" &&
              <span className="color-white space-letters m-l-3 min-width-26">{state.cart.length > 0 ? `(${calcNumLineItems(state.cart)})` : ""}</span>
            }
          </StyledTitleLink>
        }
      </div>
    </ClickAwayListener >
  )
}
export default withLocalContext(NavMenu);