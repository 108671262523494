import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import background from "../../../assets/half-diamond.svg"

const BackgroundDiamond = ({ children }) => {

  return (
    <Grid2
      container
      xs={12}
      sx={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        backgroundSize: "60%"
      }}
    >
      {children}
    </Grid2>
  )
}
export default BackgroundDiamond;