import React, { useEffect, useState } from "react";
import withLocalContext from "../store/withLocalContext";
import { modifyCart, setBanner, setPopupHTML } from "../store/actions";
import styled from "styled-components";
import CardiganLogomark from "../../assets/Cardigan_Logomark_Grayscale.png";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import info from "../../assets/info.svg";
import { toUSD } from "../Common/Functions/toUSD";
import Modifiers from "../Popup/Modifiers";
import DonutPicks from "../Popup/DonutPicks";
import { disableItem } from "./disableItem";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import AddSubtract from "../AddSubtract";
import CartConfiguration from "../Popup/CartConfiguration";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import NotAvailable from "../Popup/NotAvailable";
import { isAfter12PM } from "../Common/Functions/timeChecks";
import { findCategory } from "../Common/Functions/findCategory";
import { ORDER_PSEUDO_NAMES } from "../../constants/orderTypes";
import { MENU_ARRAY } from "../../constants/menuArray";
import { TIME_BASED_OUT_OF_STOCK_ITEMS } from "../../constants/outOfStockItems";
import dayjs from "dayjs";

const FlipCardFrontBackStyles = `
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

const FlipCard = styled.div`
  background-color: transparent;
  perspective: 1000px;
`;

const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;

  ${props => props.isimage === "false" && `
    transform: rotateY(180deg);
  `}
`;
const FlipCardFront = styled.div`
  ${FlipCardFrontBackStyles}

`;
const FlipCardBack = styled.div`
${FlipCardFrontBackStyles}
  transform: rotateY(180deg);
`;

const StyledBtn = styled.button`
${props => props.islap === "true" ? `
font-size: 14px;
width: 95px;
`: `
font-size: 12px;
`}
`;

const isItemOutOfStock = (product, category, cartConfig) => {
  // is out of stock based on square specifications
  if (product.isOutOfStock) {
    return true;

    // is out of stock based on category, specific time, and order type
  } else if (findCategory(category) === MENU_ARRAY[0].category &&
    cartConfig?.orderType?.id === ORDER_PSEUDO_NAMES[0].id &&
    (isAfter12PM() ||
      TIME_BASED_OUT_OF_STOCK_ITEMS.some(item => item.name === product.itemData.name &&
        dayjs().isAfter(dayjs(item.timeOutOfStock))
      )
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export const MenuCard = ({ item, tags, menuCardWidth, context: { dispatch, state } }) => {

  const [isAvailable, setIsAvailable] = useState(true);
  const [quantity, setQuantity] = useState(0);
  const [hasModifier, setHasModifier] = useState(false);
  const [index, setIndex] = useState();
  const [isImage, setIsImage] = useState(true);

  const navigate = useNavigate();
  const lap = useScreenWidth("isLaptop");

  const imgClasses = `${item.itemData.description ? "cursor-pointer" : "pointer-none"} object-fit-cover p-width-100 object-position-center p-relative`;

  useEffect(() => {
    if (state.cartConfig) {
      let disableRes = disableItem(state.cartConfig, item.orderTypes);
      setIsAvailable(disableRes);
    }
  }, [item.orderTypes, state.cartConfig])

  useEffect(() => {
    if (!state.cartConfig) {
      setIsAvailable(true);
    }
  }, [state.cartConfig])

  useEffect(() => {
    setIndex(state.cart.findIndex(e => e.id === item.id))
    let filteredToItem = state.cart.filter(e => e.id === item.id);

    if (filteredToItem.length > 0) {
      if (filteredToItem[0].modifierList || filteredToItem[0].donutPicks) {
        setHasModifier(true);
        let modTotal = filteredToItem.reduce((total, e) => e.quantity + total, 0);
        return setQuantity(modTotal);
      }
      setQuantity(filteredToItem[0].quantity);
    } else {
      setQuantity(0);
    }
  }, [state, item.id]);

  return (
    <div
      className={`p-relative text-align-left m-b-18 ${lap ? "m-r-65" : "m-x-17"} max-width-${menuCardWidth}`}
    >
      <FlipCard
        className={`width-${menuCardWidth} height-${menuCardWidth} highlight-none`}
        onClick={() => { item.itemData.description && setIsImage(!isImage) }}
      >
        <FlipCardInner
          isimage={String(isImage)}
        >
          <FlipCardFront
            className="p-width-100 p-height-100"
          >
            <img
              className={`${imgClasses} ${!isAvailable ? "opacity-5" : ""}`}
              alt={item.itemData.name}
              src={item.imageUrl ? item.imageUrl : CardiganLogomark}
              loading="lazy"
            />
            {
              item.itemData.description ?
                <img
                  className={`cursor-pointer p-absolute top-7 right-5 ${lap ? "width-24" : "width-16"}`}
                  src={info}
                  alt={""}
                  aria-label="Read item description"
                />
                : null
            }
          </FlipCardFront>
          <FlipCardBack>
            <div
              className={`p-absolute top-0 width-${menuCardWidth - 24} height-${menuCardWidth - 24} cursor-pointer overflow-scroll ${lap ? "font-size-14" : "font-size-12"} p-a-12 line-height-21 color-brown background-color-cream`}>
              <div className="-m-t-5 -m-r-5">
                <CloseRoundedIcon aria-label="See product photo" className={`float-right color-blue cursor-pointer m-l-5 m-b-5 ${lap ? "width-24" : "width-16"}`} />
              </div>
              <p className="m-a-0 text-align-left">{item.itemData.description}</p>
            </div>
          </FlipCardBack>
        </FlipCardInner>
      </FlipCard>
      <div className="d-flex flex-direction-column justify-between color-brown height-60 font-size-16 m-b-8 text-align-center">
        <p className={`${!isAvailable ? "opacity-5" : ""} ${lap ? "" : "font-size-14"}`}>{item.itemData.name}</p>
        <div className="center">
          <p className={`${!isAvailable ? "opacity-5" : ""} ${lap ? "" : "font-size-14"}`}>
            {item.itemData.variations[0].itemVariationData.priceMoney && toUSD(item.itemData.variations[0].itemVariationData.priceMoney.amount)}
          </p>
          <span className="min-width-2 width-2 height-31 background-color-tan m-l-15 m-r-3" />
          {isItemOutOfStock(item, item.category.name, state.cartConfig) ?
            <h4 className="color-red m-l-8">Out of stock</h4>
            :
            state.cartConfig &&
              !isAvailable ?
              <StyledBtn
                islap={String(lap)}
                className={`sentinel blue-button max-height-30 border-radius-4 p-x-5 p-y-7 m-l-5`}
                onClick={() => dispatch(setPopupHTML(<NotAvailable item={item} tags={tags} />))}
              >
                More info
              </StyledBtn>
              :
              <AddSubtract
                quantity={quantity}
                onClickAdd={() => {
                  if (state.cartConfig) {
                    if (state.donutPickItems.some(el => el.name === item.itemData.name)) {
                      dispatch(setPopupHTML(<DonutPicks data={item} donutPicks={state.donutPickItems.filter(el => el.name === item.itemData.name)[0]} />))
                    } else {
                      if (item.itemData.modifierListInfo) {
                        dispatch(setPopupHTML(<Modifiers data={item} />))
                      } else {
                        dispatch(modifyCart({ item: item, quantity: 1 }));
                        dispatch(setBanner(`Added ${item.name} to your cart!`))
                      }
                    }
                  } else {
                    dispatch(setPopupHTML(<CartConfiguration
                      pickup={true}
                      orderAttributes={item.customAttributeValues}
                      canTriggerfromMenuItem={{ ...item, tags: tags }}
                    />));
                  }
                }}
                onClickSubtract={hasModifier ?
                  () => {
                    navigate(ROUTES.CART);
                  }
                  :
                  () => {
                    dispatch(modifyCart({ item: item, quantity: -1, index: index }));
                    dispatch(setBanner(`Removed ${item.name} from your cart.`));
                  }
                }
              />
          }
        </div>
      </div>
    </div>
  );
};

export default withLocalContext(MenuCard);