import donutTaster from "../../assets/aboutUs/donutTaster.webp";
import farmhouse from "../../assets/aboutUs/farmhouse.webp";
import frgmnt from "../../assets/aboutUs/frgmnt.webp";
import friedPickle from "../../assets/aboutUs/friedPickle.webp";
import marioKart from "../../assets/aboutUs/marioKart.webp";
import matheson from "../../assets/aboutUs/matheson.webp";
import pandemic from "../../assets/aboutUs/pandemic.webp";
import secondLocation from "../../assets/aboutUs/secondLocation.webp";
import superBowl from "../../assets/aboutUs/superBowl.webp";
import marioKartPdf from "../../assets/aboutUs/marioKartPdf.pdf";
import frgmntPdf from "../../assets/aboutUs/frgmntPdf.pdf";
import farmhousePdf from "../../assets/aboutUs/farmhousePdf.pdf";
import pandemicPdf from "../../assets/aboutUs/pandemicPdf.pdf";

export const ABOUT_DATA = [
  {
    image: secondLocation,
    title: "Cardigan announces second location & Ice Cream at IDS Center",
    content: "Food Editor, Stephanie March, interviews Cardigan about their new location and all things ice cream.",
    link: {
      type: "article",
      url: "https://mspmag.com/eat-and-drink/foodie/now-open-cardigan-donuts-in-ids/"
    },
    date: "2022-06-21"
  },
  {
    image: friedPickle,
    title: "Fried Pickle Fritters & Other State Fair Inspired Donuts",
    content: "The Racket takes time to feature some of Bakery Manager, Katie Ott's, State Fair inspired donuts.",
    link: {
      type: "article",
      url: "https://racketmn.com/state-fair-pickle-pizza-cardigan-donuts-fried-pickle-fritter"
    },
    date: "2022-08-31"
  },
  {
    image: frgmnt,
    title: "Cardigan announces partnership with FRGMNT Coffee",
    content: "Start Tribune food writer, Rick Nelson, heaps praise on the French Crullers while announcing a new place to get them: FRGMNT Coffee.",
    link: {
      type: "pdf",
      url: frgmntPdf
    },
    date: "2022-03-05"
  },
  {
    image: pandemic,
    title: "Cardigan remains open and navigates the pandemic",
    content: "The Star Tribune interviews Skyway restaurants who chose to remain open, including Cardigan.",
    link: {
      type: "pdf",
      url: pandemicPdf
    },
    date: "2021-03-28"
  },
  {
    image: donutTaster,
    title: "Who wants to be a donut taster?  Where the Inspiration comes from.",
    content: "Bakery Manager Emily Poole gives Fox 9 a behind the scenes look at Cardigan's Donut Lab.",
    link: {
      type: "article",
      url: "https://www.fox9.com/the-jason-show/who-wants-to-be-a-donut-taste-tester"
    },
    date: "2019-07-25"
  },
  {
    image: marioKart,
    title: "Mario Kart Inspired Donuts?",
    content: "Watch head chef Dan Rosene show the Star Tribune how he creates the Rainbow Road colored treat.",
    link: {
      type: "pdf",
      url: marioKartPdf
    },
    date: "2017-05-25"
  },
  {
    image: superBowl,
    title: "Cardigan Welcomes Super Bowl LII to Minneapolis",
    content: "Watch Justin with Co-founder & Head Chef Dan Rosene tell WCCO's Morning Show about Cardigan's Super Bowl Inspired donuts.",
    link: {
      type: "video",
      url: "https://www.youtube.com/watch?v=7lnXheZTGtA"
    },
    date: "2018-01-29"
  },
  {
    image: matheson,
    title: "Jason Matheson gets a taste of the creations from Cardigan Donuts.",
    content: "Watch co-founders Justin Bedford and Jeff Bull show the in-studio audience of the Jason Show what Cardigan has to offer.",
    link: {
      type: "video",
      url: "https://www.facebook.com/JasonShowTV/videos/its-the-newest-donut-hotspot-in-town-jason-matheson-gets-a-taste-of-the-creation/731512610349451/"
    },
    date: "2017-05-15"
  },
  {
    image: farmhouse,
    title: "Doughnut 'restaurant' offering fried chicken and bacon varieties opening in Minneapolis skyway",
    content: "In April 2017, Cardigan's launch was featured on the Star Tribune's Table Talk blog. ",
    link: {
      type: "pdf",
      url: farmhousePdf
    },
    date: "2017-04-24"
  }
]