import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect } from "react";
import { element_sizes } from "../../constants/elementSizes";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import Footer from "../Footer";
import AllergenNotice from "../Menu/AllergenNotice";
import Nav from "../Menu/Nav";
import { isActiveCat, setHeaderTitle } from "../store/actions";
import withLocalContext from "../store/withLocalContext";

const MobileCategory = ({ context: { state, dispatch } }) => {
  const lap = useScreenWidth("isLaptop");
  const title = "Menu";

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
  })

  useEffect(() => {
    dispatch(setHeaderTitle(title))
    dispatch(isActiveCat("donuts"))
  }, [dispatch])

  return (
    <Grid2 container xs={12} sx={{
      height: `calc(100vh - ${element_sizes.headerHgt.small}px)`,
      paddingX: "30px",
      overflow: "scroll"
    }}
      alignContent="flex-start"
      justifyContent="center"
    >
      <Grid2 xs={12}>
        <AllergenNotice lap={lap} />
      </Grid2>
      <Nav />
      <Grid2 xs={12} sx={{ width: "calc(100vw + 60px)", marginX: "-30px", marginTop: "20px" }}>
        <Footer />
      </Grid2>
    </Grid2>
  )
}

export default withLocalContext(MobileCategory);