import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddSubtract from "../AddSubtract";
import { toUSD } from "../Common/Functions/toUSD";
import DonutPicks from "../Popup/DonutPicks";
import Modifiers from "../Popup/Modifiers";
import { modifyCart, setAddToLS, setBanner, setPopupHTML } from "../store/actions";
import cardiganLogomark from "../../assets/Cardigan_Logomark_Grayscale.png";
import withLocalContext from "../store/withLocalContext";
import ModiferList from "./ModiferList";
import Variation from "./Variation";
import DonutPicksLineItem from "./DonutPicksLineItem";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { calcDonutDiscount } from "../Common/Functions/CalculateTotals";

const LineItem = ({ cartItem, index, enableEdit, isCollapsed, context: { dispatch, state } }) => {
  const lap = useScreenWidth("isLaptop");
  const location = useLocation();
  const lineItemPrice = cartItem.modifierList ? cartItem.price : cartItem.itemData.variations[0].itemVariationData.priceMoney.amount;
  const discount = calcDonutDiscount(state.cart).discountPct;
  const discountCategories = ["Classic Donuts", "Premium Donuts", "Inspired Donuts"];

  const isDiscount = (discountCategories.includes(cartItem.category.name) && discount) ? true : false;

  return (
    <Grid2 container xs={12}>
      {/* Title */}
      <Grid2 xs={11} xsOffset={1} className={`${lap ? "m-b-15" : ""}`}>
        <p className={`text-align-left sentinel color-brown m-l-20`}>{cartItem.name}</p>
      </Grid2>
      {/* Close Btn */}
      <Grid2 container xs={1} justifyContent="center" alignItems="center">
        {enableEdit &&
          <span className="hover d-flex" onClick={() => {
            dispatch(modifyCart({ item: cartItem, quantity: -1000, index: index }));
            dispatch(setAddToLS(true));
            dispatch(setBanner(`Removed ${cartItem.name} from your cart.`))
          }}>
            <CloseRoundedIcon
              fontSize={""} // pass empty string so that font class will stick
              className="color-red font-size-31"
            />
          </span>
        }
      </Grid2>
      {/* Image */}
      <Grid2 xs={lap ? 4 : 3}>
        <div className={`${lap ? "m-r-10" : "m-r-15"}`}>
          <img
            className={`${lap ? "square-100" : "square-83"} object-fit-cover`}
            src={cartItem.imageUrl ? cartItem.imageUrl : cardiganLogomark}
            alt={cartItem.name}
          />
        </div>
      </Grid2>
      {/* Price, quantity, edit */}
      <Grid2 xs={6}>
        <div className={`m-t-10`}>
          <div className={`${location.pathname === ROUTES.CHECKOUT ? "" : lap ? "-m-l-10" : ""}`}>
            <AddSubtract
              quantity={cartItem.quantity}
              enableEdit={enableEdit}
              onClickSubtract={() => {
                dispatch(modifyCart({ item: cartItem, quantity: -1, index: index }))
                dispatch(setBanner(`Removed ${cartItem.name} from your cart.`))
              }}
              onClickAdd={() => {
                if (cartItem.donutPicks) {
                  let newCartItem = JSON.parse(JSON.stringify(cartItem));
                  delete newCartItem.donutPicks;
                  dispatch(setPopupHTML(<DonutPicks data={newCartItem} donutPicks={state.donutPickItems.filter(el => el.name === newCartItem.itemData.name)[0]} />))
                } else {
                  if (cartItem.modifierList) {
                    let menuItem = "";
                    state.menu.map(cat => {
                      cat.subCategories.map(subCat => {
                        let filteredItem = subCat.items.filter(item => item.id === cartItem.id);
                        if (filteredItem.length > 0) {
                          menuItem = filteredItem[0];
                          return subCat;
                        }
                        return subCat;
                      })
                      return cat;
                    })
                    if (menuItem) {
                      dispatch(setPopupHTML(<Modifiers data={menuItem} />))
                    } else {
                      dispatch(setBanner("So sorry, I could not add your item to the cart! Please try again later."))
                    }
                  } else {
                    dispatch(modifyCart({ item: cartItem, quantity: 1 }));
                    dispatch(setBanner(`Added ${cartItem.name} to your cart!`))
                  }
                }
              }}
            />
          </div>
          <div>
            <p className={`${lap ? "m-t-16" : "m-t-4"} color-brown futura text-align-left p-relative width-fit`}>
              {isDiscount &&
                <span className="height-2 -rotate-10 p-width-50 background-color-red border-radius-50 d-flex p-absolute top-10" />
              }
              <span className={`${isDiscount ? "color-grey" : ""}`}>{toUSD(lineItemPrice * cartItem.quantity)}</span>
              {isDiscount &&
                <span className="m-l-15">{toUSD((lineItemPrice * cartItem.quantity) - (discount * (lineItemPrice * cartItem.quantity)))}</span>
              }
            </p>
          </div>
          {(cartItem.modifierList || cartItem.donutPicks) &&
            enableEdit &&
            <div>
              <span
                className={`underline cursor-pointer d-flex color-blue ${lap ? "m-t-15" : "m-t-5"}`}
                onClick={() => dispatch(setPopupHTML(cartItem.donutPicks ?
                  <DonutPicks data={cartItem} donutPicks={state.donutPickItems.filter(el => el.name === cartItem.itemData.name)[0]} />
                  :
                  <Modifiers modifyData={cartItem} />
                ))
                }
              >
                Edit
              </span>
            </div>
          }
        </div>
      </Grid2>
      {/* Variations, Mods, Donut Picks */}
      <Grid2 xs={lap ? 6 : 11} xsOffset={lap ? 5 : 1}>
        {!isCollapsed &&
          <Fragment>
            {cartItem.variation &&
              <Variation variation={cartItem.variation} />
            }
            {cartItem.modifierList &&
              <ModiferList modifierList={cartItem.modifierList} />
            }
            {cartItem.donutPicks &&
              <DonutPicksLineItem donuts={cartItem.donutPicks} donutPicks={state.donutPickItems.filter(el => el.name === cartItem.itemData.name)[0]} name={cartItem.itemData.name} />
            }
          </Fragment>
        }
      </Grid2>
      {/* Bottom Line */}
      <Grid2 xs={12}>
        <div className={`height-1 m-t-25 m-b-25 ${lap ? "background-color-tan" : "background-color-gold"}`} />
      </Grid2>
    </Grid2>
  )
}
export default withLocalContext(LineItem);