import { Fragment } from "react";
import { sendOrderConfirmationEmails } from "../Common/Functions/EmailHTML/OrderConfirm";
import withLocalContext from "../store/withLocalContext";
import confirmation from "../../components/Order/Checkout/data.json";

const EmailTemplate = ({ context: { state } }) => {

  return (
    <Fragment>

      <button onClick={() => sendOrderConfirmationEmails(state, confirmation)}>Send Email</button>

    </Fragment>
  );
}
export default withLocalContext(EmailTemplate);