import { COLORS } from "./colors";

export const cardStyle = {
  '.input-container': {
    borderColor: COLORS.brown,
    borderRadius: '0px',
    borderWidth: "1px",
  },
  '.input-container.is-focus': {
    borderColor: COLORS.red,
    borderWidth: "1px"
  },
  // '.input-container.is-error': {
  //   borderColor: '#ff1600',
  // },
  // '.message-text': {
  //   color: '#999999',
  // },
  // '.message-icon': {
  //   color: '#999999',
  // },
  // '.message-text.is-error': {
  //   color: '#ff1600',
  // },
  // '.message-icon.is-error': {
  //   color: '#ff1600',
  // },
  input: {
    backgroundColor: COLORS.white,
    color: COLORS.brown,
    fontFamily: 'helvetica neue, sans-serif',
  },
  'input::placeholder': {
    color: COLORS.brown,
  },
  // 'input.is-error': {
  //   color: '#ff1600',
  // },
  // '@media screen and (max-width: 600px)': {
  //    'input': {
  //       'fontSize': '12px',
  //    }
  // }     
};
