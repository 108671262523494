import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import withLocalContext from "../store/withLocalContext";
import NavItem from "./NavItem";

const Nav = ({ context: { state } }) => {
  const lap = useScreenWidth("isLaptop");

  return (
    <Grid2
      id="nav-container"
      container
      sx={{
        marginTop: lap ? "30px" : 0,
        maxHeight: lap ? `${Math.round(window.innerHeight * 0.55)}px` : "auto",
        width: lap ? "auto" : "min-content"
      }}
    >
      {state.menu.map(item => (
        <Grid2
          key={item.category}
          container
          xs={12}
          justifyContent={lap ? "center" : "flex-start"}
          alignContent={lap ? "center" : "flex-start"}
          sx={{ width: lap ? "100%" : "270px" }}
        >
          <NavItem item={item} />
        </Grid2>
      ))}
    </Grid2>
  )
};
export default withLocalContext(Nav);