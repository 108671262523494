import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";

const Instagram = () => {
  const lap = useScreenWidth("isLaptop");
  const diamondClasses = `d-flex background-color-gold ${lap ? "diamond-15 m-r-12" : "diamond-8 m-r-8"}`;

  useEffect(() => {
    const instaplug = document.querySelector(".elfsight-app-dda5ea93-da91-4077-9fd6-07908828c90d");
    if (!instaplug.hasChildNodes()) {
      instaplug.innerHTML = "Content not available";
    }
  }, [])
  return (
    <Fragment>
      <Helmet>
        <script src="https://apps.elfsight.com/p/platform.js"></script>

      </Helmet>
      <Grid2 container xs={12} justifyContent="center">
        <h3 className={`text-align-center ${lap ? "font-size-30" : "font-size-20"} color-brown`}>Instagram</h3>
        <Grid2 container xs={12} justifyContent="center" className={`${lap ? "m-t-20" : "m-t-10"} m-b-20`}>
          <span className={diamondClasses} />
          <span className={diamondClasses.replace("gold", "red")} />
          <span className={diamondClasses.replace(lap ? "m-r-12" : "m-r-8", "")} />
        </Grid2>
        {/* <div className="height-295 p-width-100 background-color-red color-white center font-size-50" >Instagram plugin here</div> */}
        <div id="cardigan-instagram-plugin" className="elfsight-app-dda5ea93-da91-4077-9fd6-07908828c90d"></div>
      </Grid2>
    </Fragment>
  )
}

export default Instagram;