import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useLocation } from "react-router-dom";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";
import { loadCartFromStorage, setAddToLS, setCartConfig, setPopupHTML } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";

const ClearCart = ({ context: { dispatch } }) => {
  const lap = useScreenWidth("isLaptop");
  const location = useLocation();
  return (
    <Grid2 container xs={12} sx={{ maxWidth: "250px" }}>
      <Grid2 xs={12}>
        <h3 className={`${lap ? "font-size-20" : "font-size-16"}`}>
          Are you sure you want to clear {location.pathname === "/menu" ? "all configurations?" : "your cart?"}
        </h3>
      </Grid2>
      <div className="center p-width-100 m-t-30">
        <button onClick={() => dispatch(setPopupHTML(""))} className="red-button-flat max-width-80">Cancel</button>
        <button
          className="red-button max-width-80"
          onClick={() => {
            if (location.pathname === "/menu") {
              dispatch(setCartConfig(""));
            }
            dispatch(setAddToLS(true));
            dispatch(loadCartFromStorage([]));
            dispatch(setPopupHTML(""));
          }}
        >
          Yes
        </button>
      </div>
    </Grid2>
  )
}
export default withLocalContext(ClearCart);