export const ROUTES = {
  LOGIN_IN: "/login",
  LANDING_PAGE: "/",
  CART: "/cart",
  CHECKOUT: "/checkout",
  CONFIRMATION: "/confirmation",
  MENU: "/menu",
  MENU_CATEGORIES: "/menu-categories",
  CORPORATE_EVENTS: "/corporate-events",
  WEDDINGS: "/weddings",
  JOB_OPENINGS: "/job-openings",
  GET_IN_TOUCH: "/get-in-touch",
  ABOUT_US: "/about-us",
  UPCOMING_DONUT_SCHEDULE: "/upcoming-donut-schedule",
  MAIL_ME_DONUTS: "/mail-me-donuts",
  SQUARE_GIFT_CARD: "https://squareup.com/gift/ML1YWZFPHR8VA/order",
  TERMS: "terms-and-privacy",
  EMAIL_TEMPLATE: "email-template"
};