import { lineItem } from "./lineItem";
import { confirmation } from "./confirmation";
import { sendEmail } from "../../../../../services";
import dayjs from "dayjs";

export const sendOrderConfirmationEmails = async (state, confirmationDetails) => {
  try {
    let html = "";
    html += `<div style="max-width: 300px; margin: auto;">`;
    html += confirmation(confirmationDetails);
    confirmationDetails.cart.map(cartItem =>
      html += `<div>${lineItem(cartItem, { cart: state.cart, donutPickItems: state.donutPickItems })}</div>`
    )
    html += "</div>";

    let paymentId = "";
    if (Array.isArray(confirmationDetails.payment)) {
      paymentId = confirmationDetails.payment[0].id;
    } else {
      paymentId = confirmationDetails.payment.id
    }

    const receiptBtn = `<a title="View your receipt" href="https://squareup.com/receipt/preview/${paymentId}" target="_blank" rel="noreferrer" style="font-weight: bold;letter-spacing: normal;text-align: center;text-decoration: none;color: #FFFFFF;padding: 0 20px;font-family: Futura;font-weight: 400; height: 50px; display: flex; align-items: center; line-height: 50px;">View your receipt</a>`

    const internalRes = await sendInternalConfirmEmail(html, receiptBtn);
    const customerRes = await sendCustomerConfirmEmail(html, confirmationDetails, receiptBtn);

    return { customer: customerRes, internal: internalRes };
  } catch (error) {
    console.log('error:', error);
  }
}

const sendCustomerConfirmEmail = async (html, confirmation, receiptBtn) => {

  try {
    const payload = {
      template_name: "customer-order-confirmation",
      template_content: [
        {
          name: "first_name",
          content: confirmation.contact.contact.firstName
        },
        {
          name: "cart_and_order_items",
          content: html
        },
        {
          name: "receipt_btn",
          content: receiptBtn
        }
      ],
      message: {
        to: [{ email: confirmation.contact.contact.email }],
        subject: `Your Cardigan Donuts order for ${dayjs(confirmation.fulfillmentDate).format("dddd, MMMM DD")}`
      },
    };
    let emailRes = await sendEmail({ data: payload });
    return emailRes;
  } catch (error) {
    console.log('error:', error);
  }
}
const sendInternalConfirmEmail = async (html, receiptBtn) => {
  try {
    const payload = {
      template_name: "internal-order-confirmation",
      template_content: [
        {
          name: "cart_and_order_items",
          content: html
        },
        {
          name: "receipt_btn",
          content: receiptBtn
        }
      ],
      message: {
        to: [{ email: "orders@cardigandonuts.com" }]
      },
    };
    let emailRes = await sendEmail({ data: payload });
    return emailRes;
  } catch (error) {
    console.log('error:', error);
  }
}