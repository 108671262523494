import React, { useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import TextField from "@material-ui/core/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import withLocalContext from "../../store/withLocalContext";
import { COLORS } from "../../../constants/colors";
import { useScreenWidth } from "../Functions/useScreenWidth";

const StyledTextField = styled(TextField)`

> div {
  padding-right: 40px !important;
  font-family: "Futura-Med";
  color: ${COLORS.brown} !important;
  ${props => props.islap === "false" && `
  font-size : 14px;
  height: 60px;
  `}
}
  > div > input {
    &::placeholder {
      ${props => props.islap === "false" && `
      font-size : 14px;
      `}
      font-family: "Futura-Med";
      color: ${COLORS.brown} !important;
      ${(props) => (props.initialvalue && `
      opacity:1;
      `)}
    }
    &:hover {
      border: none;
    }
  }

  .MuiOutlinedInput-inputAdornedEnd {
    padding-left: 5px !important;
  }
`;

export const AddressLookupInput = ({ selectedValue, initialValue, height, context: { state } }) => {
  const [isOpen, setIsOpen] = useState(false);
  const lap = useScreenWidth("isLaptop");

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      componentRestrictions: { country: ["us"] },
      types: ["address"],
    },
  });

  const getAddressDeets = (addy) => {
    selectedValue(addy.description);
  };

  return (
    <Autocomplete
      className={`d-flex justify-start ${height ? height : ""}`}
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      getOptionSelected={(option, value) => {
        return option.description === value.description
      }}
      popupIcon={null}
      getOptionLabel={(option) => option.description}
      onChange={(evt, val) => {
        if (val) getAddressDeets(val);
      }}
      id="address-lookup"
      options={placePredictions}
      loading={isPlacePredictionsLoading}
      renderOption={(option) => (
        <Grid2 container alignItems="center">
          <Grid2>
            <FontAwesomeIcon icon={faMapMarkerAlt} color={COLORS.red} />
          </Grid2>
          <Grid2 className="m-l-20">
            <p className="color-brown">{option.description}</p>
          </Grid2>
        </Grid2>
      )}
      renderInput={(params) => (
        <StyledTextField
          islap={String(lap)}
          initialvalue={initialValue}
          {...params}
          maxRows={2}
          multiline={true}
          className="max-width-457"
          placeholder={initialValue ? initialValue : "Enter street address..."}
          variant="outlined"
          onChange={(evt) => getPlacePredictions({ input: evt.target.value })}
          InputProps={{
            ...params.InputProps
          }}
        />
      )}
    />
  );
};

export default withLocalContext(AddressLookupInput);
