export const trueAttributesToArray = (attributes) => {
  let attKeys = Object.keys(attributes);

  let attArr = [];
  attKeys.map(att => {
    if (attributes[att].name !== "Available" && attributes[att].booleanValue) {
      attArr.push(attributes[att]);
    }
    return att;
  })
  return attArr;
}