import withLocalContext from "../store/withLocalContext";
import { Fragment, useEffect } from "react";
import { deviceDetect } from "react-device-detect";
import { getProducts, getSpreadSheetData } from "../../services";
import {
  addProducts,
  addTaxes,
  createPayments,
  isMenuLoading,
  setAvailableForMetroDelivery,
  setAvailableForSameDay,
  setDeliveryMethods,
  setDeviceDetails,
  setOptions,
  setSiteAnnouncement,
  setSiteData,
  setUnavailableItems
} from "../store/actions";
import Popup from "../Popup";
import { CustomSort } from "../Common/Functions/CustomSort";
import Banner from "../Banner";
import { setCarouselBtns } from "../Common/Components/CarouselBtns";
import rightCarouselArrow from "../../assets/rightCarouselArrow.svg"
import leftCarouselArrow from "../../assets/leftCarouselArrow.svg"
import { MENU_ARRAY } from "../../constants/menuArray";

export const GlobalLoad = ({ context: { state, dispatch } }) => {

  useEffect(() => {
    setGlobalState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.popupHTML) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  });

  const setGlobalState = async () => {
    try {
      // set device info
      const deviceDetails = deviceDetect();
      dispatch(setDeviceDetails(deviceDetails));

      const ccPayment = window.Square?.payments(process.env.REACT_APP_SQUARE_APP_ID, process.env.REACT_APP_CC_LOCATION_ID);
      const idsPayment = window.Square?.payments(process.env.REACT_APP_SQUARE_APP_ID, process.env.REACT_APP_IDS_LOCATION_ID);
      dispatch(createPayments([ccPayment, idsPayment]));

      let startTime = new Date();

      const products = await getProducts();

      // Set delivery Methods
      dispatch(setDeliveryMethods(products.data.delivery))
      // Sort products/items
      let initArr = MENU_ARRAY;
      initArr.map(item => {
        return item.subCategories.map(subCategory => {
          return subCategory.items = products.data.products.filter((prod, i) => prod.category.name === subCategory.subCategory)
        });
      });

      // add categories to items
      initArr.map(item => {
        item.subCategories.map(e => {
          e.items.map(prod => {
            return prod.orderTypes = e.orderTypes;
          })
          return e;
        })
        return item;
      });

      const siteAnnounceRes = await getSpreadSheetData({ sheet: "Site Announcement" });
      if (siteAnnounceRes.data.sheetData[0].items.length > 1) {
        dispatch(setSiteAnnouncement(siteAnnounceRes.data.sheetData[0].items[0]))
      }

      const dataRes = await getSpreadSheetData();

      // manage menu sort tab
      const sortedArr = sortData(initArr, dataRes.data.sheetData[0].items);
      dispatch(setSiteData({
        // manage upcoming donut schedule tab
        [dataRes.data.sheetData[1].sheet.toLowerCase().replace(/ /g, "_")]: dataRes.data.sheetData[1].items,
        // manage contact info tab
        [dataRes.data.sheetData[2].sheet.toLowerCase().replace(/ /g, "_")]: dataRes.data.sheetData[2].items,
        // manage blackout dates tab
        [dataRes.data.sheetData[3].sheet.toLowerCase().replace(/ /g, "_")]: dataRes.data.sheetData[3].items,
        [dataRes.data.sheetData[5].sheet.toLowerCase().replace(/ /g, "_")]: dataRes.data.sheetData[5].items,
      }));

      const deliverySettings = dataRes.data.sheetData.filter(item => item.sheet === "Delivery Settings");
      if (deliverySettings.length > 0) {
        const isMetroAvailable = deliverySettings[0].items.filter(item => item.column === "Metro Delivery Available");
        if (isMetroAvailable.length > 0) {
          dispatch(setAvailableForMetroDelivery(isMetroAvailable[0].items[0] === "TRUE" ? true : false));
        }
        const isPickupAvailable = deliverySettings[0].items.filter(item => item.column === "Same Day Pickup Available");
        if (isPickupAvailable.length > 0) {
          if (isPickupAvailable[0].items[0] === "FALSE") {
            dispatch(setAvailableForSameDay(false));
          }
        }
      }

      // set items
      dispatch(addProducts(sortedArr));
      let endTime = new Date();
      console.log(((endTime - startTime) / 1000) + " seconds");

      // set unavailable items
      dispatch(setUnavailableItems(products.data.unavailableItems))

      // set options
      dispatch(setOptions(products.data.options));

      // set taxes 
      dispatch(addTaxes(products.data.taxes));
      dispatch(isMenuLoading(false));

    } catch (err) {
      console.log(err)
    }
  };

  const sortData = (initArr, menuKey) => {

    initArr.map(item => {
      item.subCategories.map(cat => {

        const catSortKey = menuKey.filter(el => el.column === cat.subCategory)[0].items;
        if (catSortKey.length > 0) {
          let sortedCat = CustomSort(cat.items, catSortKey);
          cat.items = sortedCat;
        }
        return cat;
      })
      return item;
    });
    return initArr;
  };

  // setting custom carousel selector arrows
  useEffect(() => {
    const arrows = {
      right: rightCarouselArrow,
      left: leftCarouselArrow
    }
    setCarouselBtns(".flickity-prev-next-button", arrows);
  })

  return <Fragment>
    {/* <LineItems hide={state.hideFloatingCart} /> */}
    {state.popupHTML &&
      <Popup />
    }
    {
      <Banner />
    }
  </Fragment>

}

export default withLocalContext(GlobalLoad);