import React, { createContext, useContext, useReducer } from "react";

export const TYPES = {
  ADD_FIRST_NAME: "addFirstName",
  ADD_LAST_NAME: "addLastName",
  ADD_PHONE: "addPhone",
  ADD_EMAIL: "addEmail",
  ADD_DELIVERY_FIRST_NAME: "addDeliveryFirstName",
  ADD_DELIVERY_LAST_NAME: "addDeliveryLastName",
  ADD_DELIVERY_ADDRESS: "addDeliveryAddress",
  ADD_DELIVERY_APT: "addDeliveryApt",
  ADD_DELIVERY_CITY: "addDeliveryCity",
  ADD_DELIVERY_STATE: "addDeliveryState",
  ADD_DELIVERY_ZIPCODE: "addDeliveryZipcode",
  ADD_BILLING_FIRST_NAME: "addBillingFirstName",
  ADD_BILLING_LAST_NAME: "addBillingLastName",
  ADD_BILLING_ADDRESS: "addBillingAddress",
  ADD_BILLING_APT: "addBillingApt",
  ADD_BILLING_CITY: "addBillingCity",
  ADD_BILLING_STATE: "addBillingState",
  ADD_BILLING_ZIPCODE: "addBillingZipcode",
  ADD_SECTION_ERRORS: "addSectionErrors",
  ADD_CC_TOKEN: "addCCToken"
};

const {
  ADD_FIRST_NAME,
  ADD_LAST_NAME,
  ADD_PHONE,
  ADD_EMAIL,
  ADD_DELIVERY_FIRST_NAME,
  ADD_DELIVERY_LAST_NAME,
  ADD_DELIVERY_ADDRESS,
  ADD_DELIVERY_APT,
  ADD_DELIVERY_CITY,
  ADD_DELIVERY_STATE,
  ADD_DELIVERY_ZIPCODE,
  ADD_BILLING_FIRST_NAME,
  ADD_BILLING_LAST_NAME,
  ADD_BILLING_ADDRESS,
  ADD_BILLING_APT,
  ADD_BILLING_CITY,
  ADD_BILLING_STATE,
  ADD_BILLING_ZIPCODE,
  ADD_SECTION_ERRORS,
  ADD_CC_TOKEN
} = TYPES;

const PersonInfoContext = createContext();

const personInfoReducer = (state, action) => {
  switch (action.type) {
    case ADD_FIRST_NAME:
      return { ...state, firstName: action.payload };
    case ADD_LAST_NAME:
      return { ...state, lastName: action.payload };
    case ADD_PHONE:
      return { ...state, phone: action.payload };
    case ADD_EMAIL:
      return { ...state, email: action.payload };
    case ADD_DELIVERY_FIRST_NAME:
      return { ...state, deliveryFirstName: action.payload };
    case ADD_DELIVERY_LAST_NAME:
      return { ...state, deliveryLastName: action.payload };
    case ADD_DELIVERY_ADDRESS:
      return { ...state, deliveryAddress: action.payload };
    case ADD_DELIVERY_APT:
      return { ...state, deliveryApt: action.payload };
    case ADD_DELIVERY_CITY:
      return { ...state, deliveryCity: action.payload };
    case ADD_DELIVERY_STATE:
      return { ...state, deliveryState: action.payload };
    case ADD_DELIVERY_ZIPCODE:
      return { ...state, deliveryZipcode: action.payload };
    case ADD_BILLING_FIRST_NAME:
      return { ...state, billingFirstName: action.payload };
    case ADD_BILLING_LAST_NAME:
      return { ...state, billingLastName: action.payload };
    case ADD_BILLING_ADDRESS:
      return { ...state, billingAddress: action.payload };
    case ADD_BILLING_APT:
      return { ...state, billingApt: action.payload };
    case ADD_BILLING_CITY:
      return { ...state, billingCity: action.payload };
    case ADD_BILLING_STATE:
      return { ...state, billingState: action.payload };
    case ADD_BILLING_ZIPCODE:
      return { ...state, billingZipcode: action.payload };
    case ADD_SECTION_ERRORS:
      return { ...state, sectionErrors: action.payload };
    case ADD_CC_TOKEN:
      return { ...state, ccToken: action.payload };
    default:
      return { ...state };
  }
};

export const INITIAL_BILLING = {
  billingFirstName: "",
  billingLastName: "",
  billingAddress: "",
  billingApt: "",
  billingCity: "",
  billingState: "",
  billingZipcode: "",
};

export const INITIAL_DELIVERY = {
  deliveryFirstName: "",
  deliveryLastName: "",
  deliveryAddress: "",
  deliveryApt: "",
  deliveryCity: "",
  deliveryState: "",
  deliveryZipcode: "",
};

export const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  ...INITIAL_DELIVERY,
  ...INITIAL_BILLING,
  sectionErrors: {
    contact: [],
    billing: [],
    delivery: []
  },
  ccToken: ""
};

const PersonInfoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(personInfoReducer, INITIAL_STATE);
  const value = { state, dispatch };

  return (
    <PersonInfoContext.Provider value={value}>
      {children}
    </PersonInfoContext.Provider>
  );
};

const usePersonInfo = () => {
  const context = useContext(PersonInfoContext);
  const { state, dispatch } = context;

  const addFirstName = (payload) =>
    dispatch({ type: ADD_FIRST_NAME, payload });
  const addLastName = (payload) =>
    dispatch({ type: ADD_LAST_NAME, payload });
  const addPhone = (payload) =>
    dispatch({ type: ADD_PHONE, payload });
  const addEmail = (payload) =>
    dispatch({ type: ADD_EMAIL, payload });
  const addDeliveryFirstName = (payload) =>
    dispatch({ type: ADD_DELIVERY_FIRST_NAME, payload });
  const addDeliveryLastName = (payload) =>
    dispatch({ type: ADD_DELIVERY_LAST_NAME, payload });
  const addDeliveryAddress = (payload) =>
    dispatch({ type: ADD_DELIVERY_ADDRESS, payload });
  const addDeliveryApt = (payload) =>
    dispatch({ type: ADD_DELIVERY_APT, payload });
  const addDeliveryCity = (payload) =>
    dispatch({ type: ADD_DELIVERY_CITY, payload });
  const addDeliveryState = (payload) =>
    dispatch({ type: ADD_DELIVERY_STATE, payload });
  const addDeliveryZipcode = (payload) =>
    dispatch({ type: ADD_DELIVERY_ZIPCODE, payload });
  const addBillingAddress = (payload) =>
    dispatch({ type: ADD_BILLING_ADDRESS, payload });
  const addBillingFirstName = (payload) =>
    dispatch({ type: ADD_BILLING_FIRST_NAME, payload });
  const addBillingLastName = (payload) =>
    dispatch({ type: ADD_BILLING_LAST_NAME, payload });
  const addBillingApt = (payload) =>
    dispatch({ type: ADD_BILLING_APT, payload });
  const addBillingCity = (payload) =>
    dispatch({ type: ADD_BILLING_CITY, payload });
  const addBillingState = (payload) =>
    dispatch({ type: ADD_BILLING_STATE, payload });
  const addBillingZipcode = (payload) =>
    dispatch({ type: ADD_BILLING_ZIPCODE, payload });
  const addSectionErrors = (payload) =>
    dispatch({ type: ADD_SECTION_ERRORS, payload });
  const addCCToken = (payload) =>
    dispatch({ type: ADD_CC_TOKEN, payload });

  return {
    state,
    addFirstName,
    addLastName,
    addPhone,
    addEmail,
    addDeliveryFirstName,
    addDeliveryLastName,
    addDeliveryAddress,
    addDeliveryApt,
    addDeliveryCity,
    addDeliveryState,
    addDeliveryZipcode,
    addBillingFirstName,
    addBillingLastName,
    addBillingAddress,
    addBillingApt,
    addBillingCity,
    addBillingState,
    addBillingZipcode,
    addSectionErrors,
    addCCToken
  };
};

export { usePersonInfo, PersonInfoProvider };