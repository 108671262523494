import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ORDER_PSEUDO_NAMES } from "../../../constants/orderTypes";
import List from "../../Common/Components/List";
import { trueAttributesToArray } from "../../Common/Functions/trueAttributesToArray";
import { setPopupHTML } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";

const PickupTime = ({ setOrderType, setCanSeePickup, orderAttributes, context: { dispatch, state } }) => {

  let attArr = orderAttributes ? trueAttributesToArray(orderAttributes) : [];
  const [options, setOptions] = useState([]);
  const [pickupOpt, setPickupOpt] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let tempArr = [];
    if (orderAttributes) {
      ORDER_PSEUDO_NAMES.map(item => {
        let filteredAtt = attArr.filter(att => item.pseudoNames.includes(att.name))
        if (filteredAtt.length > 0) {
          if (state.availableForSameDay || item.id !== "same") {
            tempArr.push({
              id: item.id,
              name: item.externalId
            });
          }
        }
        return item;
      })
    } else {
      ORDER_PSEUDO_NAMES.map(e => {
        if (!e.id.includes("delivery")) {
          tempArr.push({
            id: e.id,
            name: e.externalId,
            disabled: e.id === "same" ? !state.availableForSameDay : false,
            disabledText: "(Not available after 3pm)"
          });
        }
        return e;
      });
    }

    setOptions(tempArr);
    // eslint-disable-next-line
  }, [])

  return (
    options.length === 0 ?
      <div className="center flex-direction-column">
        <h3 className="font-size-20 text-align-left">
          This item is not available for ordering as pickup today is only availble until 3:45pm.
        </h3>
        <button onClick={() => {
          dispatch(setPopupHTML(""));
          navigate(window.location.pathname);
        }} className="red-button max-width-135 m-t-20">Close</button>
      </div>
      :
      <div className="p-width-100 max-width-385 z-index-250">
        <h3 className="font-size-20 text-align-left">
          {orderAttributes ? "This item is available for the following fulfillment options:" : "When do you want your order?"}
        </h3>
        <List
          options={options}
          singleSelect={true}
          selectedValue={(val) => setPickupOpt(val[0].id)}
        />
        <div className="center m-t-55 p-relative">
          {msg &&
            <span className="p-absolute -top-30 color-blue futura-cond ">
              {msg}
            </span>
          }
          <button onClick={() => {
            dispatch(setPopupHTML(""));
            navigate(window.location.pathname);
          }} className="red-button-flat max-width-135 m-l-7">Cancel</button>
          <button onClick={() => {
            if (pickupOpt) {
              setOrderType(ORDER_PSEUDO_NAMES.filter(item => item.id === pickupOpt)[0]);
              setCanSeePickup(false);
            } else {
              setMsg("Please choose an option.");
            }
          }} className="red-button max-width-135 m-r-7">Next</button>

        </div>
      </div>
  )
}
export default withLocalContext(PickupTime);