import logo from "../../assets/cardigan_logomark_gold.webp";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import JobDescription from "../Popup/JobDescription";
import JobForm from "../Popup/JobForm";
import { setPopupHTML } from "../store/actions";
import withLocalContext from "../store/withLocalContext";

const JobType = ({ data, context: { dispatch } }) => {
  const lap = useScreenWidth("isLaptop");

  const height = Math.round(window.innerWidth * (lap ? 0.16 : 0.625));
  const width = Math.round(height * 1.31);

  return (
    <div className={`${lap ? `m-r-75 max-width-${width}` : "m-b-60"}`}>
      <img className={`${lap ? `width-${width}` : "p-width-100"} object-fit-contain height-${height}`} alt={data.title} src={data.image ? data.image : logo} />
      <h4 className={`${lap ? "font-size-24" : "font-size-20"} color-brown m-t-25`}>{data.title}</h4>
      <button
        className={`red-button ${lap ? "max-width-145" : "max-width-90"} m-t-25 sentinel`}
        onClick={() => dispatch(setPopupHTML(<JobForm />))}
      >
        Apply
      </button>
      <p className={`m-t-25 ${lap ? "font-size-20 line-height-26" : ""}`}>
        {data.text}
      </p>
      <button
        className={`brown-link p-a-0 futura m-t-20 ${lap ? "font-size-20" : "font-size-16"}`}
        onClick={() => dispatch(setPopupHTML(<JobDescription description={data.description} position={data.title} />))}
      >
        See full description
      </button>
    </div>
  )
}
export default withLocalContext(JobType);