import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { COLORS } from "../../../../constants/colors";
import { Box } from "@mui/material";
import { useScreenWidth } from "../../Functions/useScreenWidth";

const IMAGE_DISPLAYS = {
  TOP: "top",
  BOTTOM: "bottom",
  RIGHT: "right",
  NONE: "none"
}

const ImageContainer = ({ location, imgRight }) => {
  return (
    <Grid2 xs={imgRight ? 5 : 12}>
      <Box
        component={"img"}
        sx={{
          height: "100%",
          width: "100%",
          objectFit: "contain",
          cursor: location.isSkyway ? "pointer" : "unset",
          maxHeight: imgRight ? "200px" : "100%",
          marginTop: imgRight ? "-20px" : 0,
        }}
        onClick={() => {
          if (location.isSkyway) { window.open(location.image) }
        }}
        alt={location.name}
        src={location.image}
      />
    </Grid2>
  )
}


const LocationItem = ({ location, index, locLen, isNumbered, imageDisplay = "top", mapEl }) => {

  const lap = useScreenWidth("isTablet");
  const borderStyle = `2px solid ${COLORS.tan}`;
  const isNotLastItem = locLen - 1 !== index;
  const imgRight = imageDisplay === IMAGE_DISPLAYS.RIGHT;
  const imgBottom = imageDisplay === IMAGE_DISPLAYS.BOTTOM;
  const imgTop = imageDisplay === IMAGE_DISPLAYS.TOP;

  return (
    <Grid2
      container
      sx={{
        width: imgRight ? "50%" : { xs: "100%", sm: mapEl ? "350px" : "400px" },
        padding: { xs: index === 0 ? "0 0 45px" : "45px 0", sm: `10px ${isNumbered ? "10px 10px 38px" : "38px"}` },
        borderRight: imgRight ? "none" : { xs: "none", sm: isNotLastItem ? borderStyle : "none" },
        borderBottom: imgRight ? "none" : { xs: !isNotLastItem ? borderStyle : "none", sm: "none" },
        maxHeight: { xs: "unset", sm: imgRight ? "320px" : "unset" }
      }}
      xs={imgRight ? 6 : "auto"}
    >
      {isNumbered &&
        <Grid2 container xs={1}>
          <Box component={"div"} className={`${lap ? "m-t-4 diamond-33" : "m-t-8 diamond-19"} background-color-red center`}>
            <Box component={"span"} sx={{ fontSize: { xs: "14px", sm: "24px" } }} className="-rotate-45 color-white">{index + 1}</Box>
          </Box>
        </Grid2>
      }
      <Grid2
        container
        alignContent={"flex-start"}
        xs={isNumbered ? 11 : 12}
        paddingLeft={{ xs: 0, sm: isNumbered ? "28px" : 0 }}
      >
        {imgTop && location.image &&
          <ImageContainer location={location} />
        }
        <Grid2
          xs={12}
          sx={{
            borderBottom: imgRight ? borderStyle : "none",
          }}
        >
          <Box
            component={"h3"}
            sx={{
              textAlign: "left",
              fontSize: { xs: "20px", sm: "24px" },
              color: COLORS.brown,
              marginTop: "6px",
              marginBottom: imgRight ? "15px" : 0
            }}
          >
            {isNumbered ? location.name : location.title}
          </Box>
        </Grid2>
        <Grid2
          xs={12}
          sx={{ margin: "24px 0px" }}
        >
          <Box
            component={"a"}
            sx={{
              textAlign: "left",
              display: "flex",
              color: COLORS.brown,
              fontSize: { xs: "16px", sm: "20px" }
            }}
            target="_blank"
            rel="noopener noreferrer"
            href={location.addressLink}
          >
            {location.address}
          </Box>
          {location.isSkyway &&
            <Box
              component={"p"}
              sx={{
                textAlign: "left",
                fontSize: { xs: "16px", sm: "20px" }
              }}
            >
              <em>Skyway Level</em>
            </Box>
          }
        </Grid2>
        <Grid2 xs={imgRight ? 7 : 12} sx={{ marginBottom: "10px" }}>
          <Box
            component={"h3"}
            sx={{
              textAlign: "left",
              fontSize: { xs: "20px", sm: "24px" },
              color: COLORS.brown,
              marginBottom: "6px"
            }}
          >
            Hours
          </Box>
          {location.hours.map((hour, i) => (
            <Box
              key={hour.days}
              component={"p"}
              sx={{
                textAlign: "left",
                fontSize: { xs: "16px", sm: "20px" }
              }}
            >
              {hour.days}: {hour.times}
            </Box>
          ))}
        </Grid2>
        {(imgBottom || imgRight) && location.image &&
          <ImageContainer location={location} imgRight={imgRight} />
        }
      </Grid2>
    </Grid2>
  )
}

export default LocationItem;