import { useMediaQuery, createTheme } from "@material-ui/core";

export const useScreenWidth = (input) => {

  const theme = createTheme({
    breakpoints: {
      values: {
        phone: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1200,
        medDesktop: 1350,
        lgDesktop: 1536
      },
    },
  });

  const isPhone = useMediaQuery(theme.breakpoints.up("phone"));
  const isTablet = useMediaQuery(theme.breakpoints.up("tablet"));
  const isLaptop = useMediaQuery(theme.breakpoints.up("laptop"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("desktop"));
  const isMedDesktop = useMediaQuery(theme.breakpoints.up("medDesktop"));
  const isLgDesktop = useMediaQuery(theme.breakpoints.up("lgDesktop"));

  let screenSize = "hi";
  let isScreenSize = false;

  if (isPhone && !isTablet) {
    screenSize = "phone";
  }
  if (isTablet && !isLaptop) {
    screenSize = "tablet";
  }
  if (isLaptop && !isDesktop) {
    screenSize = "laptop";
  }
  if (isDesktop && !isMedDesktop) {
    screenSize = "desktop";
  }
  if (isMedDesktop && !isLgDesktop) {
    screenSize = "desktop";
  }
  if (isLgDesktop) {
    screenSize = "lgDesktop";
  }

  switch (input) {
    case "isPhone":
      isScreenSize = isPhone;
      break;
    case "isTablet":
      isScreenSize = isTablet;
      break;
    case "isLaptop":
      isScreenSize = isLaptop;
      break;
    case "isDesktop":
      isScreenSize = isDesktop;
      break;
    case "isMedDesktop":
      isScreenSize = isMedDesktop;
      break;
    case "isLgDesktop":
      isScreenSize = isLgDesktop;
      break;
    default:
      break;
  }

  return input ? isScreenSize : screenSize;
}