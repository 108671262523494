import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import pic1 from "../../assets/warby-parker.webp";
import pic2 from "../../assets/st-thomas.webp";
import pic3 from "../../assets/wnba.webp";
import ImageCard from "./ImageCard";
import Form from "../Common/Components/Form";
import { CORPORATE_FORM_DATA } from "./CorporateFormData";
import { addEventsRow, sendEmail } from "../../services";
import Contact from "../Weddings/Contact";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { useEffect, useState } from "react";
import { setHeaderTitle } from "../store/actions";
import withLocalContext from "../store/withLocalContext";
import MobileImgCarousel from "./MobileImgCarousel";
import { usePageTracking } from "../Tracking";

const eventArr = [
  {
    image: pic1,
    text: "In 2018, Warby Parker wanted to do something special for the grand opening of their first Twin Cities location. We developed custom mini-donuts to serve everyone who stoped by their launch event. These donuts featured custom icing and sprinkles to fit Warby's brand and celebrate their big day.",
    title: "Warby Parker"
  },
  {
    image: pic2,
    text: `Every year we host a St. Thomas "take-over" for their alumni give-day.  As so many St. Thomas alumni work downtown, St. Thomas hosts this event at Cardigan to kick-off their campaign and reward their alumni with complimentary coffee and donuts. Our City Center location boasts 1,100 square feet of dinning room space and is perfect for small and medium sized events.`,
    title: "St. Thomas"
  },
  {
    image: pic3,
    text: "We've worked with countless local brands to send public relations press kits featuring custom donuts to media outlets around town. One of our favorite press drops was for the WNBA all-star game in 2018.  We created edible prints featuring the all-star game branding and set the prints on our delicious jam filled bismarcks.",
    title: "WNBA"
  },
]

const CorporateEvents = ({ context: { dispatch, state } }) => {

  const lap = useScreenWidth("isLaptop");
  const title = "Corporate Catering";
  const [name, setName] = useState("");

  useEffect(() => {
    dispatch(setHeaderTitle(title))
  }, [dispatch])

  useEffect(() => {
    if (state.siteData.contact_info) {
      state.siteData.contact_info[0].map((item, i) => {
        item.map(el => {
          if (el.content === "Wedding Events") {
            setName(item.filter(a => a.header === "Name")[0].content)
          }
          return el;
        });
        return item;
      })
    }
  }, [state.siteData])

  const submitCorporateForm = async (data) => {
    let fullName = data.template_content.filter(item => item.name === "Full_Name")[0];
    fullName = fullName.content.split(" ");
    fullName = fullName[0];
    data.template_content = [...data.template_content, { name: "First_Name", content: fullName }]
    let emailRes = "";
    const tempNameArr = data.template_name;

    for (let i = 0; i < tempNameArr.length; i++) {
      const element = tempNameArr[i];
      data.template_name = element;
      if (i === 1) {
        const fromInfo = state.siteData.contact_info[0].filter(item => item.filter(el => el.content === "Wedding Events")[0]);
        data.message.to = [];
        fromInfo.map(item => {
          return data.message.to.push({ email: item.filter(item => item.header === "Email")[0].content });
        })
        data.message.to.push({ email: "justin@cardigandonuts.com", type: "cc" });

        delete data.message.subject;
      } else {
        data.message.subject = `Cardigan Donuts catering request for ${data.template_content.filter(item => item.name === "Date_of_Event")[0].content}`;
      }
      emailRes = await sendEmail({ data: data });
    }
    await addEventsRow({ data: data.template_content });
    return emailRes;
  }

  usePageTracking();

  return (
    <Grid2 container xs={12} className={`text-align-left m-b-100 ${lap ? "p-l-100 p-r-100 m-t-70" : "p-l-30 p-r-30"}`}>
      <Grid2 xs={12} sx={{ maxWidth: "1120px" }} className={`${lap ? "font-size-24" : "font-size-16"}`}>
        {lap && <h1>{title}</h1>}

        <p className="m-t-25">
          We’re ready to be flexible with your event, offering delivery and add-on options like the use of our donut wall. Simply enter your details and our Event Coordinator, {name.split(" ")[0]}, will reach out to finalize a custom-tailored donut order.
        </p>
        {/* <p className={`${lap ? "m-t-50 p-r-20" : "m-t-20"} `}>
          We’ve catered for Warby Parker, University of St. Thomas, and the WNBA All-Star game; to name a few. Fill out the form below to request a consultation.
        </p> */}
      </Grid2>
      <Grid2 container xs={12} justifyContent="center" className={`${lap ? "m-t-75" : "m-t-45"}`}>
        {lap ?
          eventArr.map((item, i) => (
            <ImageCard key={item.title} idx={i} data={item} />
          ))
          :
          <MobileImgCarousel images={eventArr} />
        }
      </Grid2>
      <Grid2 container xs={12} className={`${lap ? "m-t-75" : "m-t-45"}`}>
        <Form data={CORPORATE_FORM_DATA} submitData={(val) => submitCorporateForm(val)} />
        <Contact />
      </Grid2>
    </Grid2>
  )
}
export default withLocalContext(CorporateEvents);