import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { usePageTracking } from "../Tracking";
import LocationList from "../Common/Components/LocationList";
import BackgroundDiamond from "../Common/Components/BackgroundDiamond";
import { FLAGSHIP_LOCATIONS, PARTNER_LOCATIONS, WEEKEND_POPUPS, BAKERY_CASES } from "../../constants/locations";
import ContactInfo from "./ContactInfo";
import { useEffect } from "react";
import { setHeaderTitle } from "../store/actions";
import withLocalContext from "../store/withLocalContext";
import FlagshipLocations from "./FlagshipLocations";
import { Box } from "@mui/material";

const GetInTouch = ({ context: { dispatch } }) => {

  const LOCATION_INTRO_DATA = {
    FLAGSHIP_TITLE: "Flagship Locations",
    PARTNER_TITLE: "Partner Locations",
    PARTNER_DESCRIPTION: "Our Partner Locations feature a wide variety of donuts made fresh and delivered each morning (Monday - Saturday). You can also use our website to set-up an order pickup at each of our Partner Locations.",
    WEEKEND_POPUP_TITLE: "Weekend Pop-ups",
    WEEKEND_POPUP_DESCRIPTION: "Our Pop-up shops carry a select  variety of our classic, premium, and inspired donuts. Pre-orders are not available, and donuts can sell out fast.",
    BAKERY_CASES_TITLE: "Bakery Cases",
    BAKERY_CASES_DESCRIPTION: "Several coffee shops around town stock our donuts in their bakery case. Expect Old Fashioneds and a few other classics. Pre-orders are not available, and donuts can sell out fast."
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setHeaderTitle(mobileTitle))
  }, [dispatch])

  const lap = useScreenWidth("isLaptop");
  const mobileTitle = "Locations";
  const sectionStyles = { marginTop: { xs: "20px", sm: "95px" } };

  usePageTracking();

  return (
    <BackgroundDiamond>
      <Grid2 container xs={12} className={`${lap ? "p-l-115 p-r-90 m-t-85" : "p-l-30 p-r-30"} m-b-60`}>
        {lap &&
          <Box component={"h1"} sx={{ display: { xs: "none", lg: "block" } }} className="m-b-20">{LOCATION_INTRO_DATA.FLAGSHIP_TITLE}</Box>
        }
        {!lap && <ContactInfo />}
        <Grid2 container xs={12}>
          {/* Flagship Locations */}
          <FlagshipLocations
            title={LOCATION_INTRO_DATA.FLAGSHIP_TITLE}
            locations={FLAGSHIP_LOCATIONS}
            imageDisplay={lap ? "none" : "bottom"}
            contactInfo={lap && <ContactInfo />}
          />
        </Grid2>
        <Grid2 container xs={12} sx={sectionStyles}>
          {/* Partner Locations */}
          <LocationList
            locations={PARTNER_LOCATIONS}
            title={LOCATION_INTRO_DATA.PARTNER_TITLE}
            description={LOCATION_INTRO_DATA.PARTNER_DESCRIPTION}
          />
        </Grid2>
        <Grid2 container xs={12} sx={sectionStyles}>
          {/* Weekend Pop-ups */}
          <LocationList
            locations={WEEKEND_POPUPS}
            title={LOCATION_INTRO_DATA.WEEKEND_POPUP_TITLE}
            description={LOCATION_INTRO_DATA.WEEKEND_POPUP_DESCRIPTION}
          />
        </Grid2>
        <Grid2 container xs={12} sx={sectionStyles}>
          {/* Bakery Cases */}
          <LocationList
            locations={BAKERY_CASES}
            title={LOCATION_INTRO_DATA.BAKERY_CASES_TITLE}
            description={LOCATION_INTRO_DATA.BAKERY_CASES_DESCRIPTION}
          />
        </Grid2>
      </Grid2>
    </BackgroundDiamond>
  )
}
export default withLocalContext(GetInTouch);