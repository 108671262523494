import withLocalContext from "../store/withLocalContext";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useRef } from "react";
import SetCatIntersection from "../Common/Functions/SetCatIntersection";
import { element_sizes } from "../../constants/elementSizes";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import MenuCategories from "./MenuCategories";

const MenuItems = ({ category, index }) => {
  const lap = useScreenWidth("isLaptop");
  const ref = useRef();

  return (
    <Grid2
      container
      id={category.category}
      data-category={category.title}
      ref={ref}
      sx={{
        paddingTop: (lap ? element_sizes.headerHgt.large : 0) + "px",
        marginBottom: (lap ? "-" + element_sizes.headerHgt.large : 0) + "px",
        marginTop: index === 0 ? (lap ? "-" + element_sizes.headerHgt.large : 0) + "px" : "0"
      }}
    >
      {category.subCategories.map(subCategory => (
        subCategory.items.length > 0 &&
        <MenuCategories key={subCategory.subCategory} subCategory={subCategory} index={index} />
      ))}
      <SetCatIntersection element={ref} />
    </Grid2>
  )
}

export default withLocalContext(MenuItems);