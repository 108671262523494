export const calcNumLineItems = (cart) => {
  return cart.reduce((total, item) => item.quantity + total, 0);
}

export const calcProdTotal = (cart) => {
  let totalNumber = 0;

  cart.map(item => {
    let itemPrice = 0;
    if (item.price) {
      itemPrice = item.price;
    } else {
      itemPrice = item.itemData.variations[0].itemVariationData.priceMoney.amount;
    }
    totalNumber = totalNumber + Number(itemPrice) * item.quantity;
    return item;
  })
  return totalNumber;
}

export const calcDiscounts = (prodTotal, discountInfo) => {
  let discountTotal = 0;

  if (discountInfo.discountType) {
    if (discountInfo.discountType === "FIXED_PERCENTAGE") {
      let percentage = discountInfo.percentage;
      discountTotal = (prodTotal) * (percentage / 100);
    } else if (discountInfo.discountType === "FIXED_AMOUNT") {
      discountTotal = discountInfo.amountMoney.amount;
    }
  }
  return Number(discountTotal);
}

export const calcDelivery = (config, deliveryMethods) => {

  const { itemData: { variations } } = deliveryMethods;
  let deliveryPrice = variations.filter(item => item.id === config.deliveryType.id)[0].itemVariationData.priceMoney.amount;
  return Number(deliveryPrice);
}

export const calcShipping = () => {

}

export const calcTip = (total = 0, input = "") => {
  let tipTotal = 0;
  if (input.includes("%")) {
    tipTotal = (total) * (input.replace("%", "") / 100);
  } else if (input.search(/no/i) !== -1) {
    tipTotal = 0;
  } else {
    tipTotal = input * 100;
  }

  return (tipTotal / 100).toFixed(2);
}

export const calcTax = (prodTotal, taxes) => {
  return prodTotal * (Number(taxes) / 100);
}

export const calcDonutDiscount = (cart) => {
  const classics = "Classic Donuts";
  const premiums = "Premium Donuts";
  const inspireds = "Inspired Donuts";

  const dozenDiscount = 0.316;
  const halfDozenDiscount = 0.14;

  let donutDiscount = { name: "", amount: 0, discountPct: "" };
  let donutTotal = 0;
  let donutCount = 0;

  cart.map(item => {
    if ([premiums, classics, inspireds].includes(item.category.name)) {
      donutCount += item.quantity;
      donutTotal += (item.quantity * Number(item.itemData.variations[0].itemVariationData.priceMoney.amount));
    }
    return item;
  })

  if (donutCount >= 6 && donutCount < 12) {
    donutDiscount = { name: "Half dozen discount", amount: Math.round(donutTotal * halfDozenDiscount), discountPct: halfDozenDiscount };
  }
  if (donutCount >= 12) {
    donutDiscount = { name: "Dozen discount", amount: Math.round(donutTotal * dozenDiscount), discountPct: dozenDiscount };
  }
  return donutDiscount;
}