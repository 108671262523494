import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import SingleDonut from "./SingleDonut";
import LoadingState from "../../Common/Components/LoadingState";
import withLocalContext from "../../store/withLocalContext";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";

const ScheduledDonuts = ({ context: { state } }) => {
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  const [isThisMonth, setIsMonth] = useState(true);
  const [donuts, setDonuts] = useState([]);
  const beginningOfMonth = dayjs().startOf("month");
  const endOfMonth = dayjs().endOf("month");
  const beginningOfNextMonth = dayjs().add(1, "month").startOf("month");
  const endOfNextMonth = dayjs().add(1, "month").endOf("month");
  const lap = useScreenWidth("isLaptop");
  const commonStyles = `${lap ? "diamond-72" : "diamond-46"} center cursor-pointer`;
  const spanMnthClasses = `-rotate-45 futura-cond color-brown ${lap ? "font-size-24" : "font-size-20"}`;

  useEffect(() => {
    if (state.siteData.contact_info) {
      let donutArr = [];
      state.siteData.upcoming_donut_schedule[0].map(item => {
        let payload = {};
        item.map(el => {
          payload = { ...payload, [el.header.toLowerCase().replace(/-| /g, "_")]: el.content }
          return el;
        })
        donutArr.push(payload);
        return item;
      })
      setDonuts(donutArr);
    }
  }, [state.siteData])

  const filterDonuts = (date) => {
    let showDonut = false;
    const begin = isThisMonth ? beginningOfMonth : beginningOfNextMonth;
    const end = isThisMonth ? endOfMonth : endOfNextMonth;
    if (dayjs(date).isSameOrAfter(begin) && dayjs(date).isSameOrBefore(end)) {
      showDonut = true;
    }
    return showDonut;
  }

  return (
    state.isMenuLoading ?
      <div className="height-50 m-t-40">
        <LoadingState loadText={"Loading Schedule..."} />
      </div>
      :
      <Grid2 container xs={12} className="m-t-45">
        <Grid2 container xs={12} className="m-b-50">
          <div
            onClick={() => setIsMonth(true)}
            className={`${commonStyles} ${isThisMonth ? "background-color-gold" : "background-color-white"} m-r-35`}
          >
            <span className={`${spanMnthClasses}`}>{dayjs(beginningOfMonth).format("MMM")}</span>
          </div>
          <div
            onClick={() => setIsMonth(false)}
            className={`${commonStyles} ${!isThisMonth ? "background-color-gold" : "background-color-white"}`}
          >
            <span className={`${spanMnthClasses}`}>{dayjs(beginningOfNextMonth).format("MMM")}</span>
          </div>

        </Grid2>
        {lap &&
          <Grid2 container xs={12}>
            <Grid2 xs={4}>
              <h3>Date</h3>
            </Grid2>
            <Grid2 xs={4}>
              <h3>New Inspired </h3>
            </Grid2>
            <Grid2 xs={4}>
              <h3>Jam-filled</h3>
            </Grid2>
          </Grid2>
        }
        <Grid2 container xs={12}>
          {donuts.map((item, i) => (
            <Fragment key={i}>
              {filterDonuts(item.date) &&
                <Fragment>
                  <Grid2 xs={12} className="height-2 background-color-tan m-t-12" />
                  <SingleDonut data={item} />
                </Fragment>
              }
              {
                i === donuts.length - 1 &&
                <Grid2 xs={12} className="height-2 background-color-tan m-t-12" />
              }
            </Fragment>
          ))}
        </Grid2>
      </Grid2>
  )
}

export default withLocalContext(ScheduledDonuts);