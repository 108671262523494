import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../constants/colors";
import { calcTip } from "../Common/Functions/CalculateTotals";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { setOrderTip } from "../store/actions";
import withLocalContext from "../store/withLocalContext";

const tipPercents = [
  "No tip",
  "10%",
  "15%",
  "20%"
]

const tipNumbers = [
  "No tip",
  "$1",
  "$2",
  "$3"
]

const StyledTipButtons = styled.button`
  ${props => props.lap === "true" && `
  &:hover {
    background-color: ${props => props.isactive === "true" ? COLORS.hoverBrown : COLORS.hoverWhite};
  }
  `}
`;

const StyledDiv = styled.div`
    &:focus-visible {
      outline: none;
      border: 1px solid ${COLORS.red};
    }
    &:hover {
      border: 1px solid ${COLORS.red};
    }

    >input {
      &:focus-visible {
        outline: none;
        border: 1px solid ${COLORS.white};
      }
      &:hover {
        border: 1px solid ${COLORS.white};
      }
    }
`;

const Tips = ({ total, context: { dispatch } }) => {
  const [activeIdx, setActiveIdx] = useState(2);
  const [tipInput, setTipInput] = useState("");
  const tipTypes = (total / 100) * 0.15 < 1 ? tipNumbers : tipPercents;

  const lap = useScreenWidth("isLaptop");

  useEffect(() => {
    setTipInput("");
    if (activeIdx !== -1) {
      let input = tipTypes[activeIdx].replace("$", "");
      dispatch(setOrderTip(calcTip(total, String(input))));
    }
  }, [tipTypes, total, activeIdx, dispatch])

  const checkInput = (val) => {

    let inputToTest = val;
    let isDecimal = /^\d*\.?\d*$/.test(inputToTest);

    if (isDecimal) {
      setTipInput(inputToTest);
      dispatch(setOrderTip(calcTip(total, String(inputToTest))));
    }
  }
  return (
    <Grid2 container xs={12}>
      <Grid2 xs={12}>
        <h3 className="font-size-20 text-align-left">Add a tip</h3>
      </Grid2>
      <Grid2 container xs={12} className="m-t-17">
        <Grid2 container xs={12} justifyContent={"space-between"}>
          {tipTypes.map((type, i) => (
            <Grid2
              key={type}
              onClick={() => {
                setActiveIdx(i);
              }}
              sx={{
                width: "calc(25% - 10px)"
              }}
            >
              <StyledTipButtons
                lap={String(lap)}
                isactive={String(activeIdx === i)}
                className={`${activeIdx === i ? "background-color-red color-white border-red" : "background-color-white color-brown border-tan"} ${tipTypes.length - 1 !== i && "m-r-9"} center cursor-pointer height-36 font-size-18 futura p-width-100 ${i === 0 ? "m-l-0" : "m-r-5"} ${i === tipTypes.length - 1 ? "m-r-0" : "m-r-5"} ${lap ? "" : "p-x-5"}`}
              >
                {type}
              </StyledTipButtons>
            </Grid2>
          ))}
        </Grid2>
        <Grid2 container xs={12} className="m-t-14">
          <div
            className="d-flex border-tan background-color-white p-width-100"
            onClick={() => setActiveIdx(-1)}
          >
            <div className="min-width-fit">
              <StyledTipButtons
                isactive={String(activeIdx === -1)}
                className={`${activeIdx === -1 ? "background-color-red color-white" : "background-color-white color-brown"} cursor-pointer border-r-tan p-l-12 p-r-12 p-height-100 futura font-size-18 center`}
              >
                Custom tip
              </StyledTipButtons>
            </div>
            <StyledDiv className="d-flex p-width-100">
              <span className="color-brown d-flex m-l-5" style={{ alignSelf: "center" }}>$</span>
              <input
                type={lap ? "text" : "tel"}
                id="tip_input"
                value={tipInput}
                title="only decimal numbers are allowed"
                placeholder="add custom tip amount..."
                className="thin-input border-white p-width-100 font-size-18 p-l-5 p-r-18"
                onChange={(e) => checkInput(e.target.value)}
              />
            </StyledDiv>
          </div>
        </Grid2>
      </Grid2>
    </Grid2>
  )
}
export default withLocalContext(Tips);