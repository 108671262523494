import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useEffect } from "react";
import { inputWrappers } from "..";
import { TextInput } from "../../Inputs";
import { usePersonInfo } from "../Provider";

const Contact = ({ sectionId, setSectionErrors }) => {
  const {
    state,
    addFirstName,
    addLastName,
    addPhone,
    addEmail
  } = usePersonInfo();

  useEffect(() => {
    setSectionErrors(state.sectionErrors)
  })

  const convertTel = (value, key) => {
    let tempVal = value.replace(/-/g, "");
    let newVal = "";
    if (key === "deleteContentBackward") {
      newVal = value;
    } else {
      for (let i = 0; i < tempVal.length; i++) {
        const el = tempVal[i];
        newVal = newVal + el;
        if ((i === 2) || (i === 5)) {
          newVal = newVal + "-";
        }
      }
    }
    if (tempVal.length <= 10) {
      addPhone(newVal);
    }
  }

  return (
    <Grid2 container xs={12}>
      <Grid2 xs={12}>
        <h3>Contact</h3>
      </Grid2>
      <Grid2 xs={12}>
        <div className={`${inputWrappers}`}>
          <TextInput
            id="firstName"
            maxWidth="33%"
            label="First name"
            value={state.firstName}
            onChange={(e) => addFirstName(e.target.value)}
            sectionId={sectionId}
            hasProvider
          />
          <TextInput
            id="lastName"
            maxWidth="33%"
            label="Last name"
            value={state.lastName}
            onChange={(e) => addLastName(e.target.value)}
            sectionId={sectionId}
            hasProvider
          />
        </div>
        <div className={`${inputWrappers}`}>
          <TextInput
            id="phone"
            maxWidth="33%"
            label="Phone"
            value={state.phone}
            onChange={(e) => convertTel(e.target.value, e.nativeEvent.inputType)}
            sectionId={sectionId}
            type="tel"
            hasProvider
          />
          <TextInput
            id="email"
            maxWidth="33%"
            label="Email"
            value={state.email}
            onChange={(e) => addEmail(e.target.value)}
            type="email"
            sectionId={sectionId}
            hasProvider
          />
        </div>
      </Grid2>
    </Grid2>
  )
}

export default Contact;