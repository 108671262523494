import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Fragment, useEffect, useState } from "react";
import { toUSD } from "../Functions/toUSD";
import { StyledLabel } from "../Functions/StyledComponents";
import Checkbox from "./Checkbox";
import { useScreenWidth } from "../Functions/useScreenWidth";

export const List = (
  {
    options = [],
    category = "",
    initialOpt = "",
    selectedValue,
    singleSelect,
    isCartConfig,
    isDisabled
  }) => {

  const lap = useScreenWidth("isLaptop");
  const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    if (!initialOpt) {
      setInitialValues([]);
    } else if (initialOpt.length > 1) {
      setInitialValues(initialOpt);
    } else {
      setInitialValues([initialOpt]);
    }
  }, [initialOpt])

  const setOptions = (opt) => {
    let tempInitialValues = initialValues;

    if (singleSelect) {
      if (tempInitialValues.some(item => item.name === opt.name)) {
        if (initialOpt === "") {
          let filteredVals = tempInitialValues.filter((item) => item.name !== opt.name);
          setInitialValues(filteredVals);
          selectedValue([{ listName: category, remove: true }]);
        }
      } else {
        setInitialValues([opt]);
        selectedValue([opt]);
      }
    } else {
      if (tempInitialValues.some(item => item.name === opt.name)) {
        let filteredVals = tempInitialValues.filter((item) => item.name !== opt.name);
        setInitialValues(filteredVals);
        if (filteredVals.length > 0) {
          selectedValue(filteredVals);
        } else {
          selectedValue([{ listName: category, remove: true }]);
        }
      } else {
        setInitialValues([...tempInitialValues, opt]);
        selectedValue([...tempInitialValues, opt]);
      }
    }
  };

  return (
    <Grid2 container className={`text-align-left ${isCartConfig ? "m-t-0" : "m-t-30"} ${isDisabled ? "pointer-none" : ""} highlight-none`}>
      {category &&
        <Grid2 xs={12} className="m-b-15">
          <StyledLabel className={`futura-cond`}>{category} <span className="lowercase futura">({singleSelect ? "select one" : "select all that apply"})</span></StyledLabel>
        </Grid2>
      }
      <Grid2 xs={12}>
        <ul>
          {options.length > 0 && options.map((opt, i) => (
            <li
              key={opt.id ? opt.id : i}
              className={`${i !== options.length - 1 && `m-b-22`} d-flex ${!isCartConfig && "align-center"} ${opt.disabled ? "pointer-none color-grey" : "cursor-pointer color-brown"} user-select-none p-relative`}
              onClick={() => {
                setOptions(opt)
              }}
            >
              <Checkbox
                isCartConfig={isCartConfig}
                singleSelect={singleSelect}
                isChecked={initialValues.some(item => item.name === opt.name)}
              />
              {isCartConfig ?
                <div className={`${lap ? `d-flex` : ""} ${opt.description ? "flex-direction-column d-flex" : ""}`}>
                  <span>{opt.name}</span>
                  {initialValues.some(item => item.name === opt.name) &&
                    <div className={`${lap ? "m-l-25" : opt.description ? "m-t-4" : "m-t-20"}`}>
                      <p className="futura-cond">{opt.title}</p>
                      <p className="max-width-200">{opt.address}</p>
                    </div>
                  }
                  <em className={`color-blue ${lap ? "m-t-5" : ""}`}>{opt.description}</em>
                </div>
                :
                <Fragment>
                  <span className={`${lap ? "" : "width-155"}`}>{opt.name} <span className="futura-cond font-size-14 m-l-4">{opt.disabled ? opt.disabledText : ""}</span></span>
                  {opt.price > "0" &&
                    <span className="p-absolute right-0">{toUSD(opt.price)}</span>
                  }
                </Fragment>
              }
            </li>
          ))}
        </ul>
      </Grid2>
    </Grid2>
  )
}

export default List;