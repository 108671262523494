import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";
import ItemBtn from "../../Common/Components/ItemBtn";
import { setMenuItem } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";
import ModifierVals from "./ModifierVals";
import Options from "./Options";
import Dropdown from "../../Common/Components/Dropdown";

const Modifiers = ({ data, modifyData, context: { dispatch, state } }) => {

  const [classics, setClassics] = useState([]);

  const lap = useScreenWidth("isLaptop");
  let id, imageUrl, name, selectedOptions, itemOptions, variations, selectedMods, modifierListInfo, orderTypes, category;

  if (modifyData) {
    let menuItem = "";
    state.menu.map(cat => {
      cat.subCategories.map(subCat => {
        let filteredItem = subCat.items.filter(item => item.id === modifyData.id);
        if (filteredItem.length > 0) {
          menuItem = filteredItem[0];
          return subCat;
        }
        return subCat;
      })
      return cat;
    })

    id = modifyData.id;
    imageUrl = modifyData.imageUrl;
    name = modifyData.name;
    variations = modifyData.variations;
    selectedMods = modifyData.modifierList;
    modifierListInfo = menuItem.itemData.modifierListInfo;
    selectedOptions = modifyData.options;
    itemOptions = menuItem.itemData.itemOptions;
    orderTypes = menuItem.orderTypes;
    category = modifyData.category;
  } else {
    id = data.id;
    imageUrl = data.imageUrl;
    name = data.itemData.name;
    variations = data.itemData.variations;
    modifierListInfo = data.itemData.modifierListInfo;
    itemOptions = data.itemData.itemOptions;
    orderTypes = data.orderTypes;
    category = data.category;
  }

  useEffect(() => {
    dispatch(setMenuItem({ name: name, id: id, variation: variations[0], variations: variations, imageUrl: imageUrl, orderTypes: orderTypes, category: category }))
  }, [name, id, variations, imageUrl, category, orderTypes, dispatch])

  useEffect(() => {
    if (state.donutDropdownItems.includes(name)) {
      setClassics(state.menu.filter(item => item.category === "donuts")[0].subCategories.filter(el => el.subCategory === "Classic Donuts")[0].items);
    }
  }, [state.donutDropdownItems, state.menu, name])

  return (
    <Grid2 container justifyContent={"center"} className="p-width-100">
      <Grid2 container className={`${lap ? "p-l-23 p-r-20" : "p-r-10"}`}>
        <Grid2 xs={12} className={`${lap ? "top-0" : "-top-1"} background-color-white p-sticky z-index-201`}>
          <h3 className={`color-blue ${lap ? "font-size-29" : "font-size-20 "} p-b-10 m-a-0`}>{name}</h3>
        </Grid2>
        {itemOptions !== "no options" &&
          <Grid2 xs={12}>
            <Options optionsToRender={itemOptions} variations={variations} selectedOptions={selectedOptions} />
          </Grid2>
        }
        {modifierListInfo !== "no modifiers" &&
          <Grid2 xs={12}>
            <ModifierVals modsToRender={modifierListInfo} selectedMods={selectedMods} />
          </Grid2>
        }
        {classics.length > 0 &&
          <Grid2 xs={12}>
            <Dropdown
              options={classics}
              initialOpt={modifyData ? modifyData.modifierList.filter(item => item.category === "Donut Pick")[0].modifiers[0] : classics[0]}
              category="Donut Pick"
              selectedValue={(value) => {
                const payload = {
                  id: value[0].id,
                  listName: "Donut Pick",
                  modifierListId: value[0].category.id,
                  name: value[0].name,
                  ordinal: 1,
                  price: "0",
                }
                dispatch(setMenuItem([payload]))
              }}
              catId="classics-dropdown"
            />
          </Grid2>
        }
        <Grid2 xs={12} className="popup-button-gradient">
          <ItemBtn
            item={state.menuItem}
            price={state.menuItem.price ? state.menuItem.price : 0} modifyData={modifyData}
          />
        </Grid2>
      </Grid2>
    </Grid2>
  )
}
export default withLocalContext(Modifiers);