import { Fragment, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../../constants/colors";
import { element_sizes } from "../../../constants/elementSizes";
import { ORDER_PSEUDO_NAMES } from "../../../constants/orderTypes";
import ExternalLink from "../../Common/Components/ExternalLink";
// import Footer from "../../Footer";
import CartConfiguration from "../../Popup/CartConfiguration";
import { setPopupHTML } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";

const StyledLineOne = styled.div`
  top: calc(${props => props.isopen === "true" ? "50%" : "0%"} - 2px);
  transform: rotate(${props => props.isopen === "true" ? "-90deg" : "0deg"});
`;
const StyledLineTwo = styled.div`
top: calc(50% - 2px); 
`;
const StyledLineThree = styled.div`
top: calc(${props => props.isopen === "true" ? "50%" : "100%"} - 2px);
transform: rotate(${props => props.isopen === "true" ? "-90deg" : "0deg"});
`;

const StyledBg = styled.div`
  transition: all 0.3s;
`;

const titleClasses = "color-white text-decoration-none sentinel font-size-20 m-b-12 d-flex";
const subtitleClasses = "text-decoration-none futura-cond font-size-16 m-b-10 d-flex";

const StyledSubtitle = styled(Link)`
  color: ${COLORS.white};
`;

const MobileMenu = ({ menu, isOpen, setIsOpen, context: { dispatch } }) => {

  const location = useLocation();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
    // eslint-disable-next-line 
  }, [location.pathname]);

  const openCartConfigPopup = (input) => {
    if (input.titleLink) {
      input.link = input.titleLink;
    }
    if (input.link.includes("cart_configuration")) {
      let query = input.link.split("=");
      query = query[query.length - 1]
      dispatch(setPopupHTML(<CartConfiguration orderType={ORDER_PSEUDO_NAMES.filter(item => item.id === query)[0]} pickup={query.includes("same")} />))
    }
  }

  const menuClasses = `transition p-absolute p-width-100 height-4 border-radius-50 ${isOpen ? "background-color-white" : "background-color-red"}`;
  return (
    <Fragment>
      <StyledBg
        onClick={() => setIsOpen(!isOpen)}
        className={`${isOpen ? "background-color-brown" : "background-color-white"} p-height-100 p-width-100 center`}
      >
        <div
          className={`p-relative d-flex height-25 width-27 transition ${isOpen ? "rotate-45" : "rotate-0"}`}
        >
          <StyledLineOne
            isopen={String(isOpen)}
            className={`${menuClasses}`}
          />
          <StyledLineTwo
            isopen={String(isOpen)}
            className={`${menuClasses}`}
          />
          <StyledLineThree
            isopen={String(isOpen)}
            className={`${menuClasses}`}
          />
        </div>
      </StyledBg>
      <div className={`${isOpen ? `height-${window.innerHeight - element_sizes.headerHgt.small} overflow-scroll` : "height-0 overflow-hidden"} transition top-80 left-0 v-width-100 p-absolute background-color-brown`}>
        <div className={`min-height-${window.innerHeight - element_sizes.headerHgt.small} d-flex flex-direction-column justify-start`}>
          <div className={`text-align-left p-x-45 p-t-20 max-width-${window.innerWidth - 90}`}>
            {menu.map(item => (
              <div key={item.title} className="width-fit">
                {item.link ?
                  <Link
                    className={`${titleClasses}`}
                    to={item.link}
                    onClick={() => setIsOpen(false)}
                    state={{ fromNav: true }}
                  >
                    {item.title}
                  </Link>
                  :
                  <div>
                    {item.titleLink ?
                      <StyledSubtitle
                        className={`${titleClasses}`}
                        onClick={() => openCartConfigPopup(item)}
                        to={item.titleLink}
                        state={{ fromNav: true }}
                      >
                        {item.title}
                      </StyledSubtitle>
                      :
                      <p className={`${titleClasses}`} >{item.title}</p>
                    }
                    {item.pages.map((el, i) => (
                      <div
                        key={el.title}
                        onClick={() => setIsOpen(false)}
                      >
                        {el.link.includes("http") ?
                          <ExternalLink
                            text={el.title}
                            href={el.link}
                            classes={`${subtitleClasses} color-white`}
                          />
                          :
                          <StyledSubtitle
                            className={`${i === item.pages.length - 1 ? subtitleClasses.replace("m-b-10", "m-b-20") : subtitleClasses}`}
                            onClick={() => openCartConfigPopup(el)}
                            to={el.link}
                            state={{ fromNav: true }}
                          >
                            {el.title}
                          </StyledSubtitle>
                        }
                      </div>
                    ))}
                  </div>
                }
              </div>
            ))}
          </div>
          {/* <div>
            <Footer />
          </div> */}
        </div>
      </div>
    </Fragment>
  )
}

export default withLocalContext(MobileMenu);