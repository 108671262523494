import styled from "styled-components";
import { modifyCart, setAddToLS, setBanner, setMenuItem, setPopupHTML } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";
import { toUSD } from "../Functions/toUSD";

const StyledDiv = styled.div`
${props => props.isdonutpicks &&
    `box-shadow: 6px 6px 8px 2px rgba(0, 0, 0, 0.25);`
  }
`;

const ItemBtn = ({ price, item, modifyData, context: { dispatch } }) => {

  return (
    <StyledDiv isdonutpicks={item.donutPicks} className="m-t-10 center border-2-red">
      <button onClick={() => dispatch(setPopupHTML(""))} className="red-button-flat max-width-100">Cancel</button>
      <button
        className="red-button justify-between min-width-150"
        onClick={() => {
          dispatch(setAddToLS(true));
          dispatch(modifyCart({ item: item, quantity: modifyData ? 0 : 1 }))
          dispatch(setMenuItem({ msg: "clear" }));
          dispatch(setBanner(modifyData ? `${item.name} modified!` : `Added ${item.name} to your cart!`))
          dispatch(setPopupHTML(""));
        }}
      >
        <span>{modifyData ? "Modify item" : "Add to cart"}</span>
        <span>{toUSD(price)}</span>
      </button>
    </StyledDiv>
  )
}

export default withLocalContext(ItemBtn);