import { ORDER_PSEUDO_NAMES } from "../../constants/orderTypes";

/**
 * Checks if an item should be disabled based on the cart configuration and order types.
 * @param {Object} cartConfig - The cart configuration object.
 * @param {Array} orderTypes - The array of order types.
 * @returns {boolean} - Returns true if the item should be disabled, false otherwise.
 */

export const disableItem = (cartConfig, orderTypes) => {
  let isAvailable = false;
  ORDER_PSEUDO_NAMES.map(item => {
    if (cartConfig.orderType.id === item.id) {
      orderTypes.map(el => {
        if (item.pseudoNames.includes(el)) {
          if (cartConfig.location) {
            let location = cartConfig.location.split(" ");
            let elMatch = el.includes("CC") ? "City Center" : el;
            if (elMatch.match(location[0])) {
              isAvailable = true;
            }
          } else {
            isAvailable = true;
          }
        }
        return el;
      })
    }
    return item;
  });
  return isAvailable;
}