export const TERMS_DATA = [
  {
    title: "",
    description: "By using the site, you are accepting the practices described in this privacy policy. These practices may be changed, but any changes will be posted and changes will only apply to activities and information on a going forward, not retroactive basis. You are encouraged to review the privacy policy whenever you visit the site to make sure that you understand how any personal information you provide will be used. Note: The privacy practices set forth in this privacy policy are for this web site only. If you link to other web sites, please review the privacy policies posted at those sites. "
  },
  {
    title: "Collection of Information ",
    description: "We collect personally identifiable information, like names, postal addresses, email addresses, etc., when voluntarily submitted by our visitors. The information you provide is used to fulfill your order or directly contact you with Cardigan Donuts promotions. This information is only used to fulfill your order, unless you give us permission to use it in another manner, for example to add you to one of our mailing lists."
  },
  {
    title: "Cookie/Tracking Technology",
    description: "The site may use cookie and tracking technology depending on the features offered. Cookie and tracking technology are useful for gathering information such as browser type and operating system, tracking the number of visitors to the Site, and understanding how visitors use the Site. Cookies can also help customize the Site for visitors. Personal information cannot be collected via cookies and other tracking technology, however, if you previously provided personally identifiable information, cookies may be tied to such information."
  },
  {
    title: "Distribution of Information",
    description: "CARDIGAN DONUTS does not share customer information with third party sources. CARDIGAN DONUTS may share information with governmental agencies or other companies assisting us in fraud prevention or investigation. We may do so when: (1) permitted or required by law; or, (2) trying to protect against or prevent actual or potential fraud or unauthorized transactions; or, (3) investigating fraud which has already taken place."
  },
  {
    title: "Commitment to Data Security",
    description: "Your personally identifiable information is kept secure. Only authorized employees, agents and contractors (who have agreed to keep information secure and confidential) have access to this information. All emails and newsletters from this site allow you to opt out of further mailings."
  }
];