import get from "lodash/get";
import dayjs from "dayjs";
import { local_storage } from "../../constants/localStorage";

export const getReport = (store) => get(store, "report", {});
export const getIsAuthorized = () => {
  const authorizedUser = JSON.parse(localStorage.getItem("authorization"));
  if (authorizedUser) {
    return dayjs().isBefore(authorizedUser.expiresIn);
  }
  return false;
};

export const getHasCart = () => {
  const hasCart = JSON.parse(localStorage.getItem(local_storage.cart));
  if (hasCart) {
    return dayjs().isBefore(hasCart.expiresIn);
  }
  return false;
};
