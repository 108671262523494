import { manageCart } from "../Common/Functions/manageCart";
import { manageItem } from "../Common/Functions/manageItem";

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case "add_report":
      return { ...state, report: action.payload };
    case "add_products":
      return { ...state, menu: action.payload };
    case "add_taxes":
      return { ...state, taxes: action.payload };
    case "create_payments":
      return { ...state, payments: action.payload };
    case "set_add_to_ls":
      return { ...state, canAddtoLS: action.payload };
    case "set_order_message":
      return { ...state, orderMessage: action.payload };
    case "set_order_instructions":
      return { ...state, orderInstructions: action.payload };
    case "hide_floating_cart":
      return { ...state, hideFloatingCart: action.payload };
    case "is_active_cat":
      return { ...state, activeCat: action.payload };
    case "active_sub_cat":
      return { ...state, activeSubCat: action.payload };
    case "is_menu_loading":
      return { ...state, isMenuLoading: action.payload };
    case "set_popup_html":
      return { ...state, popupHTML: action.payload };
    case "set_options":
      return { ...state, options: action.payload };
    case "set_cart_config":
      return { ...state, cartConfig: action.payload };
    case "set_delivery_methods":
      return { ...state, deliveryMethods: action.payload };
    case "add_item_to_cart":
      return { ...state, cart: JSON.parse(JSON.stringify(manageCart(state.cart, action.payload))) };
    case "load_cart_from_storage":
      return { ...state, cart: action.payload };
    case "set_menu_item":
      return { ...state, menuItem: manageItem(state.menuItem, action.payload) };
    case "set_order_totals":
      return { ...state, orderTotals: action.payload };
    case "set_order_discount":
      return { ...state, orderDiscount: action.payload };
    case "set_order_tip":
      return { ...state, orderTip: action.payload };
    case "set_banner":
      return { ...state, banner: action.payload };
    case "set_gift_card":
      return { ...state, giftCards: action.payload };
    case "set_confirmation":
      return { ...state, confirmation: action.payload };
    case "set_unavailable_items":
      return { ...state, unavailableItems: action.payload };
    case "set_site_data":
      return { ...state, siteData: action.payload };
    case "set_site_announcment":
      return { ...state, siteAnnouncement: action.payload };
    case "set_header_title":
      return { ...state, headerTitle: action.payload };
    case "set_available_for_metro_delivery":
      return { ...state, availableForMetroDelivery: action.payload };
    case "set_available_for_same_day":
      return { ...state, availableForSameDay: action.payload };
    case "set_device_details":
      return { ...state, deviceDetails: action.payload };
    default:
      return state;
  }
};
