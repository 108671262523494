import dayjs from "dayjs";

export let CORPORATE_FORM_DATA = {
  successMsg: "Your request has been sent!",
  failedMsg: "Your request failed for some reason. Please refresh the page and try again.",
  submitBtnTxt: "Submit",
  formTitle: "Request a consultation",
  formId: ["customer-catering-confirmation", "internal-catering-confirmation"],
  inputs: [
    {
      type: "text",
      title: "Full Name",
      required: true,
      col: 4,
      value: ""
    },
    {
      type: "email",
      title: "Email",
      required: true,
      col: 4,
      value: ""
    },
    {
      type: "tel",
      title: "Phone Number",
      required: true,
      col: 4,
      value: ""
    },
    {
      type: "dropdown",
      title: "Type of Event",
      required: true,
      col: 4,
      value: { name: "select" },
      dropdown: {
        dropdownOpts: [
          {
            id: "employee-appreciation",
            name: "Employee Appreciation"
          },
          {
            id: "corporate-catering-conferences",
            name: "Corporate Catering (conferences)"
          },
          {
            id: "Marketing-event",
            name: "Marketing Event"
          },
          {
            id: "press-announcement",
            name: "Press Announcement"
          },
          {
            id: "other",
            name: "Other"
          }
        ]
      }
    },
    {
      type: "cal",
      title: "Date of Event",
      required: true,
      col: 4,
      value: dayjs().add((dayjs().day() === 6 ? 2 : 1), 'day').format("MM/DD/YYYY")
    },
    {
      type: "text",
      title: "Time of Event",
      required: false,
      col: 4,
      value: ""
    },
    {
      type: "address",
      title: "Location of Event",
      required: false,
      col: 4,
      value: ""
    },
    {
      type: "text",
      title: "Expected attendance",
      required: true,
      col: 4,
      value: ""
    },
    {
      type: "checkbox",
      title: "Interested in renting the Cardigan Donut Wall?",
      required: false,
      col: 4,
      value: false
    },
    {
      type: "textarea",
      title: "Additional notes",
      required: false,
      col: 8,
      value: ""
    },
  ]
}