import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect } from "react";
import withLocalContext from "../../../../store/withLocalContext";
import { usePersonInfo } from "../Provider";
import { initializeCardPayment } from "./CardFunctions";

const CreditCard = ({ context: { state: globalState } }) => {

  const paymentContainer = "credit-card-payment-container";
  const { addCCToken } = usePersonInfo();

  const setCard = async () => {
    let locationPayment = globalState.payments[0];
    if (globalState.cartConfig) {
      if (globalState.cartConfig.orderType.id === "same") {
        if (globalState.cartConfig.location.includes("IDS")) {
          locationPayment = globalState.payments[1];
        }
      }
    }

    let card = await initializeCardPayment(locationPayment, paymentContainer);

    if (card) {
      addCCToken(card);
    }
  }

  useEffect(() => {

    if (globalState.payments) {
      setCard();
    }
    // eslint-disable-next-line 
  }, [])

  return (
    <div className="m-t-8">
      <form id="payment-form">
        <Grid2 container xs={12}>
          <Grid2 id={paymentContainer} xs={12} />
        </Grid2>
      </form>
      <div id="payment-status-container"></div>
    </div>
  )
}

export default withLocalContext(CreditCard);