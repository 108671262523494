import React, { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import withLocalContext from "../../../store/withLocalContext";
import LoadingState from "../LoadingState";
import dayjs from 'dayjs';
import { datePickerStyles } from './styles';
import OpenIcon from './OpenIcon';
import { COLORS } from '../../../../constants/colors';

const CardiganDatePicker = ({ context: { state }, tabIndex, onChange, value, isDisabled }) => {

  const [blackoutDates, setBlackoutDates] = useState([]);

  useEffect(() => {
    if (state.siteData.blackout_dates) {
      const sortedBlackoutDates = state.siteData.blackout_dates[0].items.sort();
      setBlackoutDates(sortedBlackoutDates);
    }
  }, [state.siteData]);
  const handleDateChange = (date) => {
    onChange(date);
  };

  return (
    state.isMenuLoading ?
      <div className="height-38">
        <LoadingState />
      </div>
      :
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          disablePast
          dayOfWeekFormatter={(date) => date.charAt(0).toUpperCase() + date.charAt(1).toLowerCase()}
          shouldDisableDate={
            (day) => day.day() === 0 ||
              dayjs(day).isBefore(dayjs().add(1, 'd'), "day") ||
              blackoutDates.includes(day.format("YYYY-MM-DD"))}
          PopperProps={{
            sx: {
              ...datePickerStyles
            }
          }}
          components={{ OpenPickerIcon: OpenIcon }}
          value={value}
          onChange={handleDateChange}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                tabIndex={tabIndex}
                disabled={isDisabled}
                sx={{
                  '& .MuiInputBase-input': {
                    ':hover': {
                      border: 'none'
                    },
                    ':focus-visible': {
                      border: 'none'
                    }
                  },
                  "& .MuiOutlinedInput-input": {
                    color: COLORS.brown,
                  },
                  "& .MuiOutlinedInput-root": {
                    height: { xs: '38px', lg: '46px' },
                  }
                }}
              />
            )
          }}
        />
      </LocalizationProvider>
  );
}
export default withLocalContext(CardiganDatePicker);