import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { menuClasses } from "@mui/material/Menu";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { COLORS } from '../../../constants/colors';
import { InputLabel } from '@mui/material';

const CardiganMenuItem = ({ index, ...props }) => {
  return (
    <MenuItem
      sx={{
        fontFamily: "Futura-Med",
        color: COLORS.brown,
        height: { xs: "38px", lg: "46px" },
        borderTop: index === 0 ? "none" : `1px solid ${COLORS.black}`,
        "&:hover": {
          backgroundColor: "#0000001a",
        }
      }}
      {...props}
    />
  );
}

export default function CardiganSelect({ tabIndex, onChange, options, value }) {
  const [option, setOption] = useState('');

  const handleChange = (event) => {
    let value = event.target.value;
    setOption(value);

    value = value.split("-").map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(" ");
    onChange(value);
  };

  useEffect(() => {
    if (value === "Select") {
      setOption("");
    }
  }, [value])

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel
          id="cardign-select-label"
          sx={{
            height: { xs: "38px", lg: "46px" },
            transform: { xs: "translate(14px, 8px) scale(1)", lg: "translate(14px, 12px) scale(1)" },
            color: COLORS.brown,
            "&[data-shrink='true']": {
              display: "none"
            }
          }}
        >
          Select
        </InputLabel>
        <Select
          tabIndex={tabIndex}
          displayEmpty
          labelId="cardign-select-label"
          id="cardign-select"
          value={option}
          label="Option"
          onChange={handleChange}
          MenuProps={{
            sx: {
              [`& .${menuClasses.paper}`]: {
                border: `1px solid ${COLORS.black}`,
                borderRadius: "0px",
              },
              [`& .${menuClasses.list}`]: {
                padding: "0px",
              }
            }
          }}
          sx={{
            "& .MuiSelect-select": {
              color: COLORS.brown,
              fontFamily: "Futura-Med"
            },
            "&.MuiOutlinedInput-root": {
              height: { xs: "38px", lg: "46px" }
            },
            "& .MuiSelect-icon": {
              color: COLORS.red,
              width: "2rem",
              height: "2rem",
              top: "calc(50% - 1rem)"
            }
          }}
        >
          {options.map((item, i) => (
            <CardiganMenuItem key={item.id} value={item.id} index={i}>{item.name}</CardiganMenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}