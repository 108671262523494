import { createTheme } from '@mui/material/styles';
import { COLORS } from './constants/colors';
// import { COLORS } from "./constants/colors";

// const defaultTheme = createTheme();

export const theme = createTheme({
  overrides: {
    MuiAutocomplete: {
      popper: {
        zIndex: 4000,
      },

    },
    MuiInputAdornment: {
      root: {
        fontSize: "40px"
      }
    },
    MuiInputBase: {
      root: {
        borderRadius: "0px !important",
        border: `1px solid ${COLORS.brown}`,
        fontWeight: "400",
        height: "45px",
        // [defaultTheme.breakpoints.up("md")]: {
        //   height: "75px",
        // },
        "&:hover": {
          border: `1px solid ${COLORS.red}`,
        },
        "&:focus": {
          border: `1px solid ${COLORS.red}`,
        },
      },
      input: {
        // color: COLORS.BLACK,
        opacity: "1",
        backgroundColor: "#fff",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: "none",
        "&:hover": {
          border: "none",
        }
      },
      input: {
        padding: "0px !important",
      }
    },

  }
});
