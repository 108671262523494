export const manageItem = (menuItem, values) => {

  // logic for when modifier popup closes
  if (values.msg === "clear") {
    return { name: "", id: "", modifierList: [], variation: {}, variations: [], options: [], orderTypes: [], category: {} };
  }

  let payload = menuItem;

  // adds id, name, and variation if present
  if (values.variation) {
    payload.id = values.id;
    payload.name = values.name;
    payload.variations = values.variations;
    payload.imageUrl = values.imageUrl;
    payload.orderTypes = values.orderTypes;
    payload.category = values.category;

    if (values.variations.length <= 1) {
      payload.variation = values.variation;
    }
    const price = calcPrice(payload);

    payload.price = price;
    return payload;
  }

  let updatedPayload = createMenuItem(payload, values);

  return updatedPayload;
}

const createMenuItem = (payload, values) => {

  // check if values are variation options or modifiers
  if (values[0].type) {

    // map through values and replace variation with new variation
    // the following code checks manages the options arr
    // then checks the variations arr to see if the options are in there. If it is a complete match then the variation is updated
    values.map(value => {
      if (payload.options.some(item => item.category === value.category)) {
        payload.options.splice(payload.options.findIndex(item => item.category === value.category), 1, value)
      } else {
        payload.options.push(value);
      }
      if (value.variations) {
        payload.variations = value.variations;
      }
      payload.variations.map(variation => {
        let matchCount = 0;

        variation.itemVariationData.itemOptionValues.map(val => {

          if (payload.options.some(item => item.id === val.itemOptionValueId)) {
            matchCount++;
          }
          return val;
        })

        if (matchCount === variation.itemVariationData.itemOptionValues.length) {
          return payload.variation = variation;
        }
        return variation;
      })
      return value;
    })

  } else {
    // make mod obj

    let modCat = {
      category: values[0].listName ? values[0].listName : values[0][0].listName,
      modifiers: values
    };

    // if mod category already exists in array, replace with new modCat obj
    if (payload.modifierList.some(item => item.category === modCat.category)) {

      // unless values have been unselected, then remove from modifierList
      if (values[0].remove) {
        payload.modifierList
          .splice(payload.modifierList
            .findIndex(item => item.category === modCat.category), 1);

      } else {
        payload.modifierList
          .splice(payload.modifierList
            .findIndex(item => item.category === modCat.category), 1, modCat);
      }
      // otherwise, add mod obj to arr
    } else {
      payload.modifierList.push(modCat);
    }
  }


  payload.price = calcPrice(payload);

  return payload;
}

const calcPrice = (payload) => {

  let priceArr = [];
  let varPrice = 0;

  if (Object.keys(payload.variation).length !== 0) {
    varPrice = payload.variation.itemVariationData.priceMoney.amount;
  }
  priceArr.push(varPrice);

  payload.modifierList.map(cat => {
    cat.modifiers.map(mod => {
      if (Array.isArray(mod)) {
        mod.map(item => {
          return priceArr.push(item.price);
        })
        return mod;
      } else {
        return priceArr.push(mod.price);
      }
    })
    return cat;
  })

  return priceArr.reduce((total, num) => Number(num) + Number(total));
}