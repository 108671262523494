import { useEffect, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { checkGiftCard } from "../../services";
import withLocalContext from "../store/withLocalContext";
import { cardStyle } from "../../constants/cardStyles";
import { setGiftCard } from "../store/actions";
import { toUSD } from "../Common/Functions/toUSD";

const SingleCard = ({ paymentMethod, context: { state, dispatch } }) => {

  const [giftCardAmount, setGiftCardAmount] = useState(0);
  const [giftCardMessage, setGiftCardMessage] = useState("");
  const [showOrderTotalMsg, setShowOrderTotalMsg] = useState(false);
  const [cardObj, setCardObj] = useState("");
  const [isValidGiftCard, setIsValidGiftCard] = useState(false);
  const [totals, setTotals] = useState(0);

  const addPaymentMethod = async (bypass) => {

    if (!cardObj.cardObj || bypass) {
      let payments = state.payments;
      if (payments) {
        let locationPayment = state.payments[0];
        if (state.cartConfig) {
          if (state.cartConfig.orderType.id === "same") {
            if (state.cartConfig.location.includes("IDS"))
              locationPayment = state.payments[1];
          }
        }
        let card = await locationPayment.giftCard({ style: cardStyle });
        await card.attach(`#${paymentMethod}-container`);
        setCardObj({ type: paymentMethod, cardObj: card });
      }
    }
  }

  useEffect(() => {
    if (state.payments) {
      addPaymentMethod();
    }
    // eslint-disable-next-line 
  }, [state.payments])

  useEffect(() => {
    setTotals(
      state.orderTotals.prod +
      state.orderTotals.tax +
      state.orderTotals.tip +
      state.orderTotals.discount +
      state.orderTotals.delivery)
  }, [state.orderTotals])

  async function tokenize(cardObjForToken) {
    const tokenResult = await cardObjForToken.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    }
  }

  // const triggerRemovePaymentMethod = () => {
  //   removePaymentMethod(index);
  // }

  const resetGiftCardInput = async () => {
    setCardObj("");
    await cardObj.cardObj.destroy();
    dispatch(setGiftCard([]));
    setIsValidGiftCard(false);
    addPaymentMethod(true);
    setGiftCardMessage();
    setShowOrderTotalMsg(false);
  }

  const checkBalance = async () => {
    const token = await tokenize(cardObj.cardObj);

    let giftRes = await checkGiftCard({ body: token });

    if (giftRes.data.code) {
      setGiftCardMessage("No gift card associated with that number!");
      setGiftCardAmount(0);
      setShowOrderTotalMsg(false);
    } else {
      if (Number(giftRes.data.giftCard.balanceMoney.amount) > 0) {
        setGiftCardMessage("");
        setShowOrderTotalMsg();
        setIsValidGiftCard(true);
        setGiftCardAmount(Number(giftRes.data.giftCard.balanceMoney.amount));
        dispatch(setGiftCard([...state.giftCards, giftRes.data.giftCard]));
        setShowOrderTotalMsg(true);
      } else {
        setShowOrderTotalMsg(false);
        setGiftCardMessage("No funds available!");
      }
    }
  }
  return (
    <Grid2 container xs={12}>
      <Grid2 id={`${paymentMethod}-container`} xs={12} />
      <Grid2 xs={12}>
        <div className="p-width-100">
          <span
            onClick={() => {
              isValidGiftCard ?
                resetGiftCardInput()
                :
                checkBalance(`${paymentMethod}-container`)
            }}
            className={`brown-link font-size-16`}
          >
            {isValidGiftCard ?
              "Check a different card"
              :
              "Verify and apply amount"
            }
          </span>
        </div>
        {showOrderTotalMsg &&
          <p className={`color-blue font-size-16 m-auto m-t-10`}>
            Applied
            <span className="color-red m-x-4">
              {totals >= giftCardAmount ? toUSD(giftCardAmount) : toUSD(totals)}
            </span>
            to your order with
            <span className="color-red m-x-4">
              {totals >= giftCardAmount ? toUSD(0) : toUSD(giftCardAmount - totals)}
            </span>
            remaining on your card.</p>
        }
        <span className="color-brown max-width-200 m-r-8">{giftCardMessage}</span>
      </Grid2>
    </Grid2>
  );
}

export default withLocalContext(SingleCard);