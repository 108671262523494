import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";
import { setPopupHTML } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";
import JobForm from "../JobForm";

const JobDescription = ({ position, description, context: { dispatch } }) => {
  const lap = useScreenWidth("isLaptop");
  return (
    <Grid2 id="job-description-popup" container xs={12} justifyContent="center">
      <h3 className={`sentinel ${lap ? "font-size-30" : "font-size-20"} color-blue m-b-20`}>{position}</h3>
      {description}
      <Grid2
        container
        xs={12}
        justifyContent="center"
        className="popup-button"
      >
        <button onClick={() => dispatch(setPopupHTML(""))} className="red-button-flat max-width-90">Close</button>
        <button onClick={() => {
          dispatch(setPopupHTML(<JobForm />));
        }} className="red-button max-width-90">Apply</button>
      </Grid2>
    </Grid2>
  )
}

export default withLocalContext(JobDescription);