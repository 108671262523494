import dayjs from "dayjs";

export const findInitialDate = (blackoutDates, initialDate) => {

  const sortedBlackoutDates = blackoutDates.sort();
  let tempDate = initialDate;

  sortedBlackoutDates.map(item => {
    if (item === dayjs(tempDate).format("YYYY-MM-DD")) {
      tempDate = dayjs(tempDate).add(1, "d");
      // add another day if date is on a Sunday
      if (dayjs(tempDate).day() === 0) {
        tempDate = dayjs(tempDate).add(1, "d");
      }
    }
    return item;
  })
  return tempDate;
}