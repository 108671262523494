import { useEffect } from "react";
import { getHasCart } from "../store/selectors";
import withLocalContext from "../store/withLocalContext";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import { local_storage } from "../../constants/localStorage";
import { loadCartFromStorage, setCartConfig, setOrderInstructions, setOrderMessage } from "../store/actions";
import dayjs from "dayjs"
import { ORDER_PSEUDO_NAMES } from "../../constants/orderTypes";

const LocalStorage = ({ context: { state, dispatch } }) => {
  // Set local storage if previous storage has expired
  useEffect(() => {
    const addCartToLocalStorage = async () => {
      try {
        let hasCart = getHasCart();
        if (!hasCart) {
          await AsyncLocalStorage.setItem(local_storage.cart, JSON.stringify(
            {
              cart: [],
              cartConfig: "",
              orderMessage: "",
              expiresIn: dayjs().endOf("d").format()
            }
          ));
        }
        return;
      } catch (err) {
        console.log("error setting cardigan_cart LS data", err);
      }
    }
    addCartToLocalStorage();
  })

  // load cart from local storage
  useEffect(() => {
    const addLocalCartData = async () => {
      let localCartData = await AsyncLocalStorage.getItem(local_storage.cart);
      localCartData = JSON.parse(localCartData);
      dispatch(loadCartFromStorage(localCartData.cart))
      dispatch(setOrderMessage(localCartData.orderMessage))
      dispatch(setOrderInstructions(localCartData.orderInstructions))

      if (!state.availableForSameDay) {
        if (localCartData.cartConfig.orderType === ORDER_PSEUDO_NAMES.filter(item => item.id === "same")[0].name) {
          dispatch(setCartConfig(""));
        } else {
          dispatch(setCartConfig(localCartData.cartConfig))
        }
      } else {
        dispatch(setCartConfig(localCartData.cartConfig))
      }
    }
    addLocalCartData();
  }, [dispatch, state.availableForSameDay])

  // update local stoarage each time cart changes
  useEffect(() => {
    if (state.canAddtoLS) {
      const updateCartToLocalStorage = async () => {
        let cartExpiration = await AsyncLocalStorage.getItem(local_storage.cart);
        cartExpiration = JSON.parse(cartExpiration);
        await AsyncLocalStorage.setItem(
          local_storage.cart,
          JSON.stringify({
            ...cartExpiration,
            cart: state.cart,
            cartConfig: state.cartConfig,
            orderMessage: state.orderMessage,
            orderInstructions: state.orderInstructions,
          }
          )
        );
      }
      updateCartToLocalStorage();
    }
  }, [state.cart, state.canAddtoLS, state.cartConfig, state.orderMessage, state.orderInstructions])

  return null;
}

export default withLocalContext(LocalStorage);