import { cardStyle } from "../../../../../constants/cardStyles";
import { payZeroOrder, processPayment } from "../../../../../services";

export const initializeCardPayment = async (squarePayments, paymentContainer) => {

  let card;
  try {
    card = await squarePayments.card({ style: cardStyle });
    let input = document.getElementById(paymentContainer);
    if ([...input.children].length === 0) {
      await card.attach(`#${paymentContainer}`);
      return card;
    }
  } catch (e) {
    console.error('Initializing Card failed', e);
    return;
  }
}

// The handlePaymentMethodSubmission function calls three other functions 
// in order to verify the buyer and then process payment
// Note that prior to this function being called there was another function called that created an order in Square

// The first function, tokenize, creates a unique and secure token using paymentMethod that can be used instead of the actual card details

// The second function, verifyBuyer, provides SCA to the transaction
// The buyers billing is need for this one

// The third function, createPayment, submits the payment with the orderAmount and orderId.
export const handlePaymentMethodSubmission = async (squarePayments, paymentMethod, orderAmount, tip, giftCard, orderId, billingDetails) => {

  try {
    let gcAmt = 0;

    let ccAmt = 0;

    if (giftCard) {
      if (orderAmount >= giftCard.amount) {
        gcAmt = giftCard.amount;
      } else {
        gcAmt = orderAmount;
      }
      if (paymentMethod) {
        ccAmt = orderAmount - gcAmt;
      }
    } else {
      ccAmt = orderAmount;
    }

    let paymentTokens = [];
    let verifyResults = "";

    if (paymentMethod) {

      const token = await tokenize(paymentMethod);
      if (!token.success) {
        return token.data;
      }

      verifyResults = await verifyBuyer(squarePayments, token.data.token, billingDetails, orderAmount);

      if (!verifyResults.token) {
        return { success: false, message: "There was an issue with your card. Please try entering it again or try a different card." };
      }

      paymentTokens = [{ sourceId: token.data.token, amount: ccAmt }];
    }

    if (giftCard) {
      paymentTokens = [...paymentTokens, { ...giftCard, amount: gcAmt }];
    }
    const locationId = squarePayments.paymentOptions.locationId;
    let paymentResults = "";

    if (orderAmount === 0 && tip === 0) {
      paymentResults = await payZeroOrder({ orderId: orderId });
    } else {
      paymentResults = await createPayment(paymentTokens, verifyResults.token, orderId, tip, locationId);
    }

    return paymentResults.data;


  } catch (e) {
    const payload = {
      sucess: false,
      results: e.message
    }
    return payload;
  }
}

const tokenize = async (cardObjForToken) => {
  const tokenResult = await cardObjForToken.tokenize();
  if (tokenResult.status === 'OK') {
    return { success: true, data: tokenResult };
  } else {
    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
    if (tokenResult.errors) {
      errorMessage += ` and errors: ${JSON.stringify(
        tokenResult.errors
      )}`;
    }
    return { success: false, data: errorMessage };
  }
}

const verifyBuyer = async (payments, token, billingDetails, orderAmount) => {

  let amount = Number(orderAmount) / 100;
  const verificationDetails = {
    amount: String(amount),
    /* collected from the buyer */
    billingContact: {
      addressLines: [billingDetails.billingAddress, billingDetails.billingApt ? billingDetails.billingApt : ""],
      familyName: billingDetails.billingLastName,
      givenName: billingDetails.billingFirstName,
      email: billingDetails.billingEmail,
      country: 'US',
      phone: billingDetails.billingPhone.replace(/-/g, ""),
      state: billingDetails.billingState,
      city: billingDetails.billingCity,
    },
    currencyCode: 'USD',
    intent: 'CHARGE',
  };

  const verificationResults = await payments.verifyBuyer(
    token,
    verificationDetails
  );
  return verificationResults;
}

const createPayment = async (tokens, verificationToken, orderId, tip, locationId) => {
  const payload = {
    tokens: tokens,
    verificationToken: verificationToken,
    orderId: orderId,
    tip: tip,
    locationId: locationId
  };
  const paymentResponse = await processPayment(payload);
  return paymentResponse.data;
}