import { ClickAwayListener } from "@mui/material";
import styled from "styled-components";
import { COLORS } from "../../constants/colors";
import { element_sizes } from "../../constants/elementSizes";
import { isIOS } from "react-device-detect"
// import { setMenuItem, setPopupHTML } from "../store/actions";
import withLocalContext from "../store/withLocalContext";
// import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
// import { useNavigate } from "react-router-dom";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { useEffect, useState } from "react";

const StyledBackground = styled.div`
  background-color: ${COLORS.gold}bf;
  z-index: ${props => props.islap === "true" ? 1000 : 1500};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;
const Popup = ({ context: { state } }) => {
  // const navigate = useNavigate();
  const lap = useScreenWidth("isLaptop");
  const [isVisible, setIsVisible] = useState(false);


  useEffect(() => {
    if (state.popupHTML) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [state.popupHTML])

  return (
    // Background div
    <StyledBackground islap={String(lap)} className={`${isVisible ? "v-width-100 v-height-100" : "v-width-0 v-height-0"} p-absolute center p-fixed`}>
      <ClickAwayListener
        onClickAway={() => {
          // dispatch(setMenuItem({ msg: "clear" }));
          // dispatch(setPopupHTML(""));
          // navigate(window.location.pathname);
        }}
      >
        {/* Popup wrapper */}
        <Grid2
          container
          id="cardiganPopup"
          sx={{
            minWidth: lap ? 450 : 0,
            width: lap ? "min-content" : "auto",
            maxWidth: lap ? "587px" : "calc(100vw - 30px)",
            minHeight: lap ? "min-content" : "100px",
            justifyContent: "center",
            boxShadow: "10px 10px 10px rgb(0 0 0 / 25%)",
            backgroundColor: COLORS.white,
            marginTop: isIOS ? "-80px" : "105px"
          }}
        >
          {/* 
          Padding will push content to right below close icon. 
          If you want content to be in line with close icon then use styling in individual popup to achieve that.
          */}
          <Grid2
            container
            xs={12}
            sx={{
              position: "relative",
              maxHeight: `calc(100vh - ${lap ? element_sizes.headerHgt.large : element_sizes.headerHgt.small + 50}px - 50px)`,
              padding: lap ? "30px 50px" : "30px 35px",
            }}
          >
            {/* <CloseRoundedIcon
              sx={{
                fontSize: lap ? 37 : 27,
                color: COLORS.red,
                cursor: "pointer",
                zIndex: 2,
                position: "absolute",
                right: lap ? 21 : 8,
                top: lap ? 21 : 10
              }}
              fontSize=""
              onClick={() => {
                dispatch(setMenuItem({ msg: "clear" }));
                dispatch(setPopupHTML(""));
                navigate(window.location.pathname);
              }}
            /> */}
            <Grid2
              container
              xs={12}
              sx={{
                height: "102%",
                overflow: "scroll"
              }}
              justifyContent="center"
            >
              {state.popupHTML}
            </Grid2>
          </Grid2>
        </Grid2>
      </ClickAwayListener>
    </StyledBackground >
  )
}

export default withLocalContext(Popup);