import { Box } from "@mui/material";
import dateSvg from "../../../../assets/Date_Black.svg";

export default function OpenIcon() {
  return (
    <Box
      component={'img'}
      alt='calendar icon'
      src={dateSvg}
      sx={{
        width: { xs: '16px', lg: '25px' },
        cursor: 'pointer'
      }}
    />
  )
}