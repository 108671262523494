
import React, { useEffect } from "react";
import withLocalContext from "../store/withLocalContext";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Billboard from "./Billboard";
import Products from "./Products";
import Newsletter from "./Newsletter";
import GiftCardAndEvents from "./GiftCardAndEvents";
import Map from "./Map";
import Instagram from "./Instagram";
import { setCarouselBtns } from "../Common/Components/CarouselBtns";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { setHeaderTitle } from "../store/actions";
import { usePageTracking } from "../Tracking";

export const LandingPage = ({ context: { dispatch } }) => {
  const lap = useScreenWidth("isLaptop");
  useEffect(() => {
    setCarouselBtns();
  })

  const title = "home";

  useEffect(() => {
    dispatch(setHeaderTitle(title))
  }, [dispatch]);

  usePageTracking();

  return (
    <Grid2 container className="">
      <Grid2 container xs={12}>
        <Billboard />
      </Grid2>
      <Grid2 container xs={12} justifyContent="center">
        <Products />
      </Grid2>
      <Grid2 container xs={12} className={`${lap ? "p-l-120 p-r-120" : "p-l-30 p-r-30"} m-t-30`}>
        <Grid2 container xs={12} className="m-b-55">
          <Newsletter />
        </Grid2>
        <Grid2 container xs={12}>
          <GiftCardAndEvents />
        </Grid2>
        <Grid2 container xs={12}>
          <Map />
        </Grid2>
        <Grid2 container xs={12} className={`${lap ? "m-b-60" : "m-b-10"}`}>
          <Instagram />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default withLocalContext(LandingPage);
