import { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants/colors";
import { Dropdown } from "../../Common/Components/Dropdown";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";
import { CheckoutAddress } from "./CheckoutAddress";
import { usePersonInfo } from "./PersonalInfo/Provider";
const StyledDiv = styled.div`
  max-width: calc(${props => props.maxwidth} - ${props => props.maxwidth === "100%" ? "0px" : "25px"});
  >div>div>div {
    padding-top: 9px !important;
    padding-bottom: 8px !important;
    >input {
      color: ${COLORS.brown};
      font-family: Futura;
    }
  }
`;
export const TextInput = ({
  id,
  label,
  maxWidth,
  notRequired,
  type,
  onChange,
  value,
  options,
  sectionId,
  containerId,
  isDisabled,
  isControlled,
  hasProvider
}) => {

  const { state, addSectionErrors } = usePersonInfo();
  const lap = useScreenWidth("isLaptop");

  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const checkValue = (value) => {
    if (!notRequired) {
      if (type === "email") {
        if (!/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\b/.test(value)) {
          setHasError(true);
          setErrorMsg("Please format email correctly");

          if (hasProvider) controlErrors(true);
        } else {
          setHasError(false);
          if (hasProvider) controlErrors(false);
          setErrorMsg("");
        }
      } else {
        if (value === "") {
          setHasError(true);
          setErrorMsg("Please fill out field");
          if (hasProvider) controlErrors(true);
        } else {

          setHasError(false);
          if (hasProvider) controlErrors(false);
          setErrorMsg("");
        }
      }
    }
  }

  const controlErrors = (isError) => {

    if (isError) {
      if (state) {

        if (!state.sectionErrors[sectionId].some(item => item === id)) {
          addSectionErrors({ ...state.sectionErrors, [sectionId]: [...state.sectionErrors[sectionId], id] })
        }
      } else {

        let filteredErrorList = state.sectionErrors[sectionId].filter(item => item !== id);
        addSectionErrors({ ...state.sectionErrors, [sectionId]: filteredErrorList })
      }
    } else {
      // check if id is in section errors
      if (state.sectionErrors[sectionId].some(item => item === id)) {

        // remove id from sectionErrors
        let filteredErrorList = state.sectionErrors[sectionId].filter(item => item !== id);
        addSectionErrors({ ...state.sectionErrors, [sectionId]: filteredErrorList })
      }

    }
  }

  useEffect(() => {
    setHasError(false);
    if (hasProvider) addSectionErrors({ ...state.sectionErrors, [sectionId]: [] })
    setErrorMsg("");
    // eslint-disable-next-line
  }, [isDisabled])

  return (
    <StyledDiv
      maxwidth={lap ? maxWidth : "100%"}
      className={`p-relative d-flex flex-direction-column min-width-200 p-width-100 m-b-10 ${lap ? "m-r-25" : ""}`}
    >
      {label &&
        <label className={`color-brown ${lap ? "m-b-9" : "m-b-6"}`} htmlFor={(type === "dropdown" ? "ul_" : "") + id}>
          {label}
          {!notRequired && <span className="color-red">*</span>}
        </label>
      }
      {type === "address" ?
        <CheckoutAddress
          initialValue={value}
          selectedValue={onChange}
          height={"height-36"}
          onBlur={(e) => checkValue(e)}
        />
        :
        type === "dropdown" ?
          // below span is to protect if from styles that being applied to <CheckoutAddress/>
          <span>
            <Dropdown
              options={options}
              catId={id}
              containerId={containerId}
              selectedValue={onChange}
              initialOpt={value}
              isControlled={isControlled}
              styles={{
                contMT: "m-t-0",
                disContHeight: "height-36",
                disPadL: "p-l-10",
                contWidth: "min-width-200",
                arrWidth: "width-14",
                pointer: isDisabled ? "pointer-none" : ""
              }}
            />
          </span>
          :
          <input
            id={id}
            autoComplete="on"
            required={notRequired ? false : true}
            type={type ? type : "text"}
            value={value}
            onChange={onChange}
            onBlur={(e) => checkValue(e.target.value)}
            placeholder={label ? label.toLowerCase() + "..." : ""}
            className={`border-brown thin-input ${isDisabled ? "pointer-none" : ""}`}
          />
      }
      {hasError &&
        <span className="p-absolute -bottom-18 futura-cond  font-size-14 color-red">{errorMsg}</span>
      }
    </StyledDiv>
  )
}
