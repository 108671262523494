import withLocalContext from "../../../store/withLocalContext";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { Input } from "../Input";
import { useEffect, useState } from "react";
import LoadingState from "../LoadingState";
import dayjs from "dayjs";
import { useScreenWidth } from "../../Functions/useScreenWidth";
import { setPopupHTML } from "../../../store/actions";
import { findInitialDate } from "../../Functions/findInitialDate";

const Form = ({ data, submitData, context: { state: globalState, dispatch } }) => {
  const [state, setState] = useState(JSON.parse(JSON.stringify(data)));
  const lap = useScreenWidth("isLaptop");
  const success = "success";
  const submitting = "submitting";
  const failed = "failed";
  const error = "error";

  const successStyles = `${lap ? "font-size-20" : "font-size-16"} color-blue height-fit`;
  const submittingStyles = "height-48";
  const failedStyles = successStyles.replace("color-blue", "color-red");
  const errorStyles = failedStyles;

  const [formState, setFormState] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputErrors, setInputErrors] = useState([]);
  const [isInPopup, setIsInPopup] = useState(false);

  const submitForm = async () => {
    // setIsDisabled(true);
    let inputRes = checkInputs();
    if (inputRes) {
      setFormState(submitting);
      const formData = formatData();
      let response = await submitData(formData);
      if (response) {
        setFormState(success)
        setState(JSON.parse(JSON.stringify(data)));
      } else {
        setFormState(failed)
      }
    } else {
      setFormState(error);
      setIsDisabled(false);
    }
  }

  const checkInputs = () => {
    let errors = inputErrors;
    if (inputErrors.length === 0) {
      state.inputs.map(item => {
        if (item.required) {
          if (!item.value || JSON.stringify(item.value).includes("select")) {
            errors.push(item);
          }
        }
        return item;
      })
    }
    return errors.length > 0 ? false : true;
  }

  const formatData = () => {
    let templateContent = [];
    state.inputs.map(item => {
      return templateContent.push({
        name: item.title.replace(/\p{P}+$/gu, "").replace(/\p{P}| /gu, "_"),
        content: item.type === "checkbox" ? (item.value ? "yes" : "no") : item.value
      });
    })
    const payload = {
      template_name: state.formId,
      template_content: templateContent,
      message: {
        to: [{ email: state.inputs.filter(item => item.type === "email")[0].value }]
      },
    };
    return payload;
  }

  useEffect(() => {
    if (globalState.siteData.blackout_dates) {
      const dateInputs = state.inputs.filter(item => item.type === "cal")
      dateInputs.map(el => {
        const newDate = findInitialDate(globalState.siteData.blackout_dates[0].items, dayjs(el.value));
        return el.value = dayjs(newDate).format("MM/DD/YYYY")
      })
    }
  }, [globalState.siteData, state.inputs]);

  useEffect(() => {
    let popup = document.getElementById("cardiganPopup");

    if (popup) {
      setIsInPopup(true);
    }
  }, []);

  return (
    <Grid2 id={state.formId} container xs={12} sx={{ maxWidth: "1105px", minWidth: lap ? "450px" : "" }} className="text-align-left">
      {state.formTitle &&
        <Grid2 xs={12} className="m-b-45">
          <h3 className={`${lap ? "" : "font-size-20"}`}>{state.formTitle}</h3>
        </Grid2>
      }
      <Grid2 container xs={12} sx={{ width: "calc(100% - 10px)" }}>
        {state.inputs.map((item, i) => {
          const objId = "form-" + state.formId + "-" + item.title.replace(/ /g, "-").toLowerCase();
          return (
            <Grid2 container key={item.title} xs={12} md={item.col}>
              <Input
                tabIndex={i + 100}
                id={objId}
                maxWidth="100%"
                isDisabled={isDisabled}
                type={item.type}
                label={item.title}
                value={item.value}
                isControlled={formState === submitting ? true : false}
                notRequired={!item.required}
                options={item.dropdown ? item.dropdown.dropdownOpts : ""}
                containerId={state.formId}
                onChange={(val) => {
                  let tempInputs = state.inputs;
                  tempInputs[tempInputs.findIndex(el => el.title === item.title)]
                    .value = item.type === "cal" ?
                      dayjs(val).format("YYYY-MM-DD")
                      :
                      val;
                  setState({ ...state, inputs: [...tempInputs] })
                }}
                hasInputError={(val, id) => {
                  let tempErrors = inputErrors;
                  if (val) {
                    if (!tempErrors.some(item => item === val)) {
                      tempErrors.push(id);
                    }
                  } else {
                    tempErrors = tempErrors.filter(item => item !== id);
                  }
                  setInputErrors(tempErrors);
                }}
              />
            </Grid2>
          )
        })}
        <Grid2 xs={12} md={isInPopup ? 12 : "auto"} container className="m-b-18 popup-button">
          <Grid2 xs={12} md={isInPopup ? 12 : 5} container alignContent={"flex-end"} justifyContent={isInPopup ? "center" : "flex-start"}>
            {isInPopup &&
              <button onClick={() => dispatch(setPopupHTML(""))} className="red-button-flat max-width-118">Cancel</button>
            }
            <button
              className="red-button min-width-130 max-width-130"
              onClick={() => submitForm()}
              disabled={isDisabled}
            >
              {state.submitBtnTxt}
            </button>
          </Grid2>
          <Grid2 xs={12} md={isInPopup ? 12 : 7} container alignContent={"flex-end"} paddingLeft={"8px"}>
            {
              formState === submitting ?
                <div className={submittingStyles}>
                  <LoadingState />
                </div>
                :
                formState === success ?
                  <p className={successStyles}>{state.successMsg}</p>
                  :
                  formState === failed ?
                    <p className={failedStyles}>{state.failedMsg}</p>
                    :
                    formState === error ?
                      <p className={errorStyles}>Some fields have not been filled out correctly.</p>
                      :
                      ""
            }
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  )
}

export default withLocalContext(Form);