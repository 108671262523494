import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { smallBlueUpperClasses } from "../";
import { contClasses, StyledSectionCont } from "../../Order/Checkout/PersonalInfo";
import VISA from "../../../assets/PaymentLogos/VISA.svg";
import MASTERCARD from "../../../assets/PaymentLogos/MASTERCARD.svg";
import AMERICAN_EXPRESS from "../../../assets/PaymentLogos/AMERICAN_EXPRESS.svg";
import JCB from "../../../assets/PaymentLogos/JCB.svg";
import DISCOVER from "../../../assets/PaymentLogos/DISCOVER.png";
import SQUARE from "../../../assets/PaymentLogos/SQUARE.png";
import withLocalContext from "../../store/withLocalContext";
import { toUSD } from "../../Common/Functions/toUSD";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { setConfirmation } from "../../store/actions";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";

const LOGOS = [
  {
    brand: "VISA",
    logo: VISA
  },
  {
    brand: "MASTERCARD",
    logo: MASTERCARD
  },
  {
    brand: "AMERICAN_EXPRESS",
    logo: AMERICAN_EXPRESS
  },
  {
    brand: "JCB",
    logo: JCB
  },
  {
    brand: "DISCOVER",
    logo: DISCOVER
  },
  {
    brand: "SQUARE",
    logo: SQUARE
  }
];

const secMgT = "m-t-30";

const subtitle = smallBlueUpperClasses + " m-b-15";

const PaymentConfirm = ({ context: { state, dispatch } }) => {
  const navigate = useNavigate();
  const lap = useScreenWidth("isLaptop");

  const { contact:
    { billing: {
      billingAddress,
      billingApt,
      billingCity,
      billingState,
      billingZipcode
    },
      delivery: {
        deliveryAddress,
        deliveryApt,
        deliveryCity,
        deliveryState,
        deliveryZipcode
      }
    },
    order: {
      lineItems,
      totalTaxMoney,
      totalMoney,
      totalDiscountMoney
    },
    payment,
    tip
  } = state.confirmation;

  const makeAddress = (address, apt, city, state, zipcode) => {
    const fullAddress = `${address ? `${address}, ` : ""}${apt ? `${apt}, ` : ""}${city ? `${city}, ` : ""}${state ? `${state}, ` : ""}${zipcode ? `${zipcode}` : ""}`;
    return fullAddress;
  }

  const fullBillingAddress = makeAddress(billingAddress, billingApt, billingCity, billingState, billingZipcode);
  const fullDeliveryAddress = makeAddress(deliveryAddress, deliveryApt, deliveryCity, deliveryState, deliveryZipcode);

  const delivery = lineItems.some(item => item.name === "Delivery") ? lineItems.filter(item => item.name === "Delivery")[0].basePriceMoney.amount : 0;
  const discount = Number(totalDiscountMoney.amount) !== 0 ? Number(totalDiscountMoney.amount) * -1 : Number(totalDiscountMoney.amount);

  const orderTotals = [
    {
      title: "Product total",
      amount: Number(totalMoney.amount) + Number(totalDiscountMoney.amount) - Number(totalTaxMoney.amount)
    },
    {
      title: "Discounts",
      amount: discount
    },
    {
      title: "Delivery/Shipping",
      amount: Number(delivery),
    },
    {
      title: "Subtotal",
      amount: Number(totalMoney.amount) - Number(totalTaxMoney.amount)
    },
    {
      title: "Tax",
      amount: Number(totalTaxMoney.amount)
    },
    {
      title: "Tip",
      amount: Number(tip)
    },
    {
      title: "Total",
      amount: Number(totalMoney.amount) + Number(tip)
    }
  ]

  const btn = <button
    onClick={() => {
      dispatch(setConfirmation(""));
      window.scrollTo(0, 0);
      navigate(ROUTES.LANDING_PAGE);
    }}
    className="red-button max-width-200"
  >
    Back to home
  </button>;

  return (
    <Fragment>
      <StyledSectionCont container className={`${contClasses()}`}>
        {/* payment section */}
        {payment.length !== 0 &&
          <Grid2 xs={12}>
            <h4 className={`${subtitle}`}>Payment</h4>
            <div className="width-fit d-flex flex-direction-column align-end">
              {Array.isArray(payment) &&
                payment.map(item => (
                  <div key={item.id} className="d-flex align-center">
                    <div className="m-r-4">
                      {
                        LOGOS.some(e => item.cardDetails.card.cardBrand.replace(/_/g, " ").includes(e.brand)) ?
                          <img
                            className="d-flex height-30 max-width-50 object-fit-contain"
                            alt=""
                            src={LOGOS.filter(e => {
                              let cardBrand = item.cardDetails.card.cardBrand.replace(/_/g, " ");
                              return cardBrand.includes(e.brand)
                            })[0].logo}
                          />
                          :
                          <p>{item.cardDetails.card.cardBrand.replace(/_/g, " ")}</p>
                      }
                    </div>
                    <p>ending in {item.cardDetails.card.last4}</p>
                  </div>
                ))}
            </div>
          </Grid2>
        }
        {billingAddress &&
          <Grid2 xs={12} className={`${secMgT}`}>
            <h4 className={`${subtitle}`}>Billing address</h4>
            <p>{fullBillingAddress}</p>
          </Grid2>
        }
        {fullDeliveryAddress &&
          <Grid2 xs={12} className={`${secMgT}`}>
            <h4 className={`${subtitle}`}>Delivery address</h4>
            <p>{fullDeliveryAddress}</p>
          </Grid2>
        }
        <Grid2 container xs={12} className={`${secMgT}`}>
          <Grid2 xs={lap ? 6 : 12}>
            <div className="max-width-322">
              {orderTotals.map((item, i) => (
                item.amount !== 0 &&
                <Fragment key={item.title}>
                  {item.title === "Subtotal" &&
                    <div className="height-1 background-color-tan -m-b-3 m-t-15" />
                  }
                  {orderTotals.length - 1 === i &&
                    <div className="height-3 background-color-tan m-y-20" />
                  }
                  <div className={`m-t-8 d-flex justify-between align-center ${orderTotals.length - 1 === i ? "color-red" : "color-brown"}`}>
                    <span>{item.title}</span>
                    <span>{toUSD(item.amount)}</span>
                  </div>
                </Fragment>
              ))}
            </div>
          </Grid2>
          {lap &&
            <Grid2 xs={6} container alignContent={"flex-end"}>
              {btn}
            </Grid2>
          }
        </Grid2>
      </StyledSectionCont>
      {!lap &&
        <Grid2 xs={12} container sx={{ marginTop: "35px" }} justifyContent={"center"}>
          {btn}
        </Grid2>
      }
    </Fragment>
  )
}

export default withLocalContext(PaymentConfirm);