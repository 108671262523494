import { pad2 } from "../../../Pad2";
import { sortDonuts } from "../../../sortDonuts";

export const donutPicksLineItem = (donuts, donutPicks) => {

  const maxDonuts = donutPicks.classics + donutPicks.premiums + donutPicks.inspireds;
  let sortedDonuts = sortDonuts(donuts);
  const classics = sortedDonuts.classics;
  const premiums = sortedDonuts.premiums;
  const inspired = sortedDonuts.inspireds;

  let html = "";
  html = "";
  html += `<div style="text-align: left; margin-top: 10px;">`;
  if (classics.length > 0) {
    classics.map((classics, i) => html += `<div style="height: 1px; background-color: #E8D7C0; margin: 6px 0 10px 0;"></div>
      <p style="font-size: 16px; color: #624C42; margin-left:20px;"><span style="margin-right: 5px; color: #DCB964;">${pad2(i + 1)}</span>${classics.itemData.name}</p>`)
  }
  if (premiums.length > 0) {
    premiums.map((premium, i) => html += `<div style="height: 1px; background-color: #E8D7C0; margin: 6px 0 10px 0;"></div>
        <p style="font-size: 16px; color: #624C42; margin-left: 20px;"><span style="margin-right: 5px; color: #DCB964;">${pad2(classics.length + i + 1)}</span>${premium.itemData.name}</p>`)
  }
  if (inspired.length > 0) {
    inspired.map((insp, i) => html += `<div style="height: 1px; background-color: #E8D7C0; margin: 6px 0 10px 0;"></div>
        <p style="font-size: 16px; color: #624C42; margin-left:20px;"><span style="margin-right: 5px; color: #DCB964;">${pad2(classics.length + premiums.length + i + 1)}</span>${insp.itemData.name}</p>`)
  }
  if (donuts.length < maxDonuts) {
    html += `<p style="margin-top: 5px; color: #D85028; margin-left: 20px;">Let the store pick the rest</p>`;
  }
  html += `</div>`
  return html;
}