import { toUSD } from "../Common/Functions/toUSD";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { Fragment } from "react";

const ModiferList = ({ modifierList }) => {
  const lap = useScreenWidth("isLaptop");
  return (
    modifierList.map(modCat => (
      <div key={modCat.category} className="text-align-left m-t-10">
        {lap &&
          <p className="futura-cond m-b-3">{modCat.category}</p>
        }
        {modCat.modifiers.map(mod => (
          Array.isArray(mod) ?
            mod.map(e => (
              <Fragment key={e.name}>
                {!lap &&
                  <div className="height-1 background-color-tan m-t-6 m-b-10" />
                }
                <p className={`color-brown ${lap ? "font-size-14" : "font-size-16"}`}>{e.name} {e.price > 0 && "(" + toUSD(e.price) + ")"}</p>
              </Fragment>
            ))
            :
            <Fragment key={mod.name}>
              {!lap &&
                <div className="height-1 background-color-tan m-t-6 m-b-10" />
              }
              <p className={`color-brown ${lap ? "font-size-14" : "font-size-16"}`}>{mod.name} {mod.price > 0 && "(" + toUSD(mod.price) + ")"}</p>
            </Fragment>
        ))}
      </div>
    ))
  )
}

export default ModiferList;