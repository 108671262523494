import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import { StyledLabel } from "../Functions/StyledComponents";
import MultiListDropdownMenu from "./MultiListDropdownMenu";

const MultiListDropdown = ({ options, category, initialOpts, selectedValue }) => {
  const [optionsInList, setOptionsInList] = useState(initialOpts === "" ? [] : initialOpts);
  const [selectedOptions, setSelectedOptions] = useState(initialOpts === "" ? [] : initialOpts);
  const [optionsInDropdown, setOptionsInDropdown] = useState(initialOpts === "" ? options : options.filter(item => {
    let initOp = initialOpts.filter(opt => opt.id === item.id);
    if (initOp.length === 0) {
      return item;
    }
    return item;
  }));

  useEffect(() => {

    let tempDropdownOptions = [];

    options.map((item, i) => {
      if (!optionsInList.some(el => el.id === item.id)) {
        tempDropdownOptions.push(item);
      }
      return item;
    });

    setOptionsInDropdown(tempDropdownOptions);

  }, [optionsInList, options])


  const setOptions = (obj) => {
    // set options for list
    const temptOpts = optionsInList;
    let index = temptOpts.findIndex(item => item.id === obj.current.id);

    temptOpts.splice(index, 1, obj.new);
    setOptionsInList([...temptOpts]);

    // set selections in list
    // setSelections cannot be called as it only toggles values on or off.
    setSelectedOptions([...temptOpts]);
    selectedValue([...temptOpts]);

    // set options in dropdown
    let tempDropdownOptions = optionsInDropdown;

    tempDropdownOptions.splice(tempDropdownOptions.findIndex(item => item.id === obj.new.id), 1, obj.current)
    setOptionsInDropdown([...tempDropdownOptions]);
  }

  const setSelections = (opt) => {

    let tempInitialValues = selectedOptions;

    if (tempInitialValues.some(item => item.name === opt.name)) {
      setSelectedOptions(tempInitialValues.filter((item) => item.name !== opt.name));
      selectedValue(tempInitialValues.filter((item) => item.name !== opt.name));
    } else {
      setSelectedOptions([...tempInitialValues, opt]);
      selectedValue([...tempInitialValues, opt]);
    }
  };

  return (
    <Grid2 container className="text-align-left m-t-30">
      <Grid2 xs={12} className="m-b-15">
        <StyledLabel className="futura-cond">{category} INCLUDED <span className="lowercase futura">(uncheck to exclude)</span></StyledLabel>
      </Grid2>
      <Grid2 xs={12}>
        <ul>
          {optionsInList.map((opt, i) => (
            <li
              key={opt.id}
              className={`${i !== optionsInList.length - 1 && "m-b-22"} color-brown d-flex justify-between align-center cursor-pointer p-relative`}

            >
              <span
                className="d-flex align-center"
                onClick={() => {
                  setSelections(opt)
                }}
              >
                <span className="height-20 width-20 border-red center m-r-16">
                  {selectedOptions.some(item => item.name === opt.name) &&
                    <span className="height-16 width-16 background-color-red d-flex"></span>
                  }
                </span>
                {opt.name}
              </span>
              <MultiListDropdownMenu
                category={category}
                id={category + "_" + i}
                items={optionsInDropdown}
                setOptions={(val) => setOptions({ current: opt, new: val })} />
            </li>
          ))}
        </ul>
      </Grid2>
    </Grid2>
  )
}

export default MultiListDropdown;