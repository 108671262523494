import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
// https://www.cluemediator.com/scroll-to-the-top-of-the-page-after-render-in-reactjs
function ScrollToTop({ children }) {
  const { state, pathname } = useLocation();
  const pathnameRef = useRef("");

  useEffect(() => {

    if (state) {
      if (state.fromNav) {
        if (pathname !== pathnameRef.current)
          window.scrollTo(0, 0);
      }
    }
    pathnameRef.current = pathname;

  }, [pathname, state]);

  return children;
}

export default ScrollToTop;