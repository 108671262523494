import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { COLORS } from "../../constants/colors";
import withLocalContext from "../store/withLocalContext";
// import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { setPopupHTML } from "../store/actions";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { Dropdown } from "../Common/Components/Dropdown";
import { ORDER_FROM_LOCATIONS } from "../../constants/locations";
import { Fragment, useEffect, useState } from "react";
import { ORDER_PSEUDO_NAMES } from "../../constants/orderTypes";
import { toUSD } from "../Common/Functions/toUSD";
import CartConfiguration from "../Popup/CartConfiguration";
import ClearCart from "../Popup/ClearCart";
import { element_sizes } from "../../constants/elementSizes";

const CartConfigBanner = ({ context: { state, dispatch } }) => {
  const lap = useScreenWidth("isLaptop");
  const [orderTypes, setOrderTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [fulfillmentLocation, setFulfillmentLocation] = useState();
  const [fulfillmentOrderType, setFulfillmentOrderType] = useState();

  // set orderTypes
  useEffect(() => {
    let tempOrderTypes = ORDER_PSEUDO_NAMES;
    tempOrderTypes = tempOrderTypes.filter(item => item.id !== state.cartConfig.orderType.id);
    if (!state.availableForSameDay) {
      tempOrderTypes = tempOrderTypes.filter(item => item.id !== "same");
    }
    setOrderTypes(tempOrderTypes);
    setFulfillmentOrderType(state.cartConfig.orderType);
  }, [state.cartConfig, state.availableForSameDay])

  // set locations
  useEffect(() => {
    let tempLocations = [];
    if (state.cartConfig.orderType.id === "delivery") {
      if (state.deliveryMethods) {
        tempLocations = state.deliveryMethods.itemData.variations;
        tempLocations = tempLocations.filter(item => item.id !== state.cartConfig.deliveryType.id);
        tempLocations.map(item => {
          return item.name = `${item.itemVariationData.name} (${toUSD(item.itemVariationData.priceMoney.amount)})`;
        })
        setFulfillmentLocation(state.cartConfig.deliveryType);
      }
    } else {
      tempLocations = ORDER_FROM_LOCATIONS;
      tempLocations = tempLocations.filter(item => item.name !== state.cartConfig.location);
      if (state.cartConfig.orderType.id === "same") {
        tempLocations = tempLocations.filter(item => item.name !== "FRGMNT");
      }
      setFulfillmentLocation({ name: state.cartConfig.location });
    }
    setLocations(tempLocations);
  }, [state.cartConfig, state.deliveryMethods]);


  const btnEl = <button
    className={`underline futura m-r-20 ${lap ? "color-brown" : "color-blue p-t-10 p-b-6"}`}
    onClick={() => dispatch(setPopupHTML(<ClearCart />))}
  >
    Clear all order configurations
  </button>;


  if (!state.cartConfig) {
    return null;
  }
  return (
    <Grid2
      container
      xs={12}
      alignItems="center"
      justifyContent={lap ? "flex-end" : "center"}
      sx={{
        position: "fixed",
        top: lap ? "unset" : 80 + (state.siteAnnouncement ? element_sizes.announcement : 0) + "px",
        backgroundColor: lap ? COLORS.tan : COLORS.white,
        padding: `${lap ? "8px 14px" : "0px 14px 0px 14px"}`,
        zIndex: 5,
        display: state.cartConfig ? "flex" : "none",
      }}
    >
      {lap &&
        <Fragment>
          <em className="m-r-30">May affect item availability. Not all products are available at all locations</em>
          {btnEl}
        </Fragment>
      }
      <div className={`center p-y-2`}>
        <div className="m-r-10">
          <Dropdown
            initialOpt={fulfillmentOrderType}
            options={orderTypes}
            color={"blue"}
            containerId="menu-container"
            catId="cart-config-banner-method"
            isControlled
            cannotChangeVal
            selectedValue={(val) => {
              dispatch(setPopupHTML(<CartConfiguration proposedNewConfig={{ orderType: val[0] }} />));
            }}
            styles={{
              disContHeight: "height-30",
              disPadL: "p-l-13",
              arrowTop: "top-10",
              contWidth: "width-198",
              contMT: "m-t-0",
              fontSize: "font-size-13"
            }}
          />
        </div>
        <Dropdown
          initialOpt={fulfillmentLocation}
          options={locations}
          color={"blue"}
          containerId="menu-container"
          isControlled
          cannotChangeVal
          selectedValue={(val) => {
            let payload = "";
            if (state.cartConfig.orderType.id === "delivery") {
              payload = {
                orderType: state.cartConfig.orderType,
                deliveryType: val[0]
              }
            } else {
              payload = {
                orderType: state.cartConfig.orderType,
                location: val[0].name
              }
            }
            dispatch(setPopupHTML(<CartConfiguration proposedNewConfig={payload} />));
          }}
          catId="cart-config-banner-location"
          styles={{
            disContHeight: "height-30",
            disPadL: "p-l-13",
            arrowTop: "top-10",
            contWidth: "width-150",
            contMT: "m-t-0",
            fontSize: "font-size-13"
          }}
        />
      </div>
      {!lap &&
        btnEl
      }
    </Grid2>
  )
}

export default withLocalContext(CartConfigBanner);