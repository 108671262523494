import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import BackgroundDiamond from "../Common/Components/BackgroundDiamond";
import LoadingState from "../Common/Components/LoadingState";
import { removePeriods } from "../Common/Functions/removePeriods";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import withLocalContext from "../store/withLocalContext";
import { TERMS_DATA } from "./TermsData";
import { setHeaderTitle } from "../store/actions";

const TermsAndPrivacy = ({ context: { state, dispatch } }) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const lap = useScreenWidth("isLaptop");
  const title = "Terms + Privacy";
  const titleClasses = `${lap ? "font-size-22" : "font-size-20"} futura-cond m-b-10 m-t-30`;
  const descripClasses = "line-height-21";
  const linkClasses = "color-red m-l-5 m-r-5";

  useEffect(() => {
    dispatch(setHeaderTitle(title))
  }, [dispatch])

  useEffect(() => {
    if (state.siteData.contact_info) {
      state.siteData.contact_info[0].map(item => {
        item.map(el => {
          if (el.content === "Hello") {
            setEmail(item.filter(a => a.header === "Email")[0].content);
          }
          if (el.content === "City Center") {
            setPhone(item.filter(a => a.header === "Phone")[0].content);
          }
          return el;
        })
        return item;
      })
    }
  }, [state.siteData])

  return (
    <BackgroundDiamond>
      <Grid2 container xs={12} sx={{ maxWidth: "1300px" }} className={`${lap ? "p-l-145 p-r-145 m-t-85" : "p-l-30 p-r-30 "} m-b-65 text-align-left`}>
        {lap &&
          <Grid2 xs={12}>
            <h1>{title}</h1>
          </Grid2>
        }
        <Grid2 xs={12} className="m-t-25">
          {TERMS_DATA.map((item, i) => (
            <div key={i}>
              {item.title &&
                <h3 className={titleClasses}>{item.title}</h3>
              }
              {item.description &&
                <p className={descripClasses}>{item.description}</p>
              }
            </div>
          ))}
          <div>
            <h3 className={titleClasses}>Privacy Contact Information</h3>
            <p className={descripClasses}>If you have any questions, concerns, or comments about our privacy policy you may contact us using the information below:</p>
            {state.isMenuLoading ?
              <div className="height-50">
                <LoadingState loadText={"Loading contact info..."} />
              </div> :
              state.siteData.contact_info &&
              <div className={`${lap ? "d-flex" : ""} m-y-15`}>
                <p>
                  By email:
                  <a className={`${linkClasses} underline`} href={`mailto:${email}`}>{email}</a>
                </p>
                <p>
                  By phone:
                  <a className={linkClasses} href={`tel:${removePeriods(phone)}`}>{phone}</a>
                </p>
              </div>
            }
            <p>We reserve the right to make changes to this policy. Any changes to this policy will be posted.</p>
          </div>
        </Grid2>
      </Grid2>
    </BackgroundDiamond>
  )
}

export default withLocalContext(TermsAndPrivacy);