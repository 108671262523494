import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { blueUpperClasses } from "../Order/Cart";
import withLocalContext from "../store/withLocalContext";
import ContactConfirm from "./ContactConfirm";
import PaymentConfirm from "./PaymentConfirm";
import { usePageTracking } from "../Tracking";

export const smallBlueUpperClasses = () => {
  return blueUpperClasses().replace("font-size-20", "font-size-16");
};

const Confirmation = ({ context: { state } }) => {
  const navigate = useNavigate();
  const lap = useScreenWidth("isLaptop");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!state.confirmation) {
      navigate(ROUTES.LANDING_PAGE);
    }
  })

  usePageTracking();

  return (
    <Grid2 container className={`text-align-left`} sx={lap ? {} : { width: "calc(100% + 30px)", marginX: "-15px" }}>
      <Grid2 xs={12}>
        <ContactConfirm />
        <PaymentConfirm />
      </Grid2>
    </Grid2>
  )
}
export default withLocalContext(Confirmation);