import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import withLocalContext from "../store/withLocalContext"
import dayjs from "dayjs";
import logo from "../../assets/cardigan_logomark_gold.webp";
import { BottomLines } from "../Header/BottomLines";
import { ROUTES } from "../../constants/routes";
import { Link } from "react-router-dom";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";

import instagram from "../../assets/socials/instagram.webp";
import facebook from "../../assets/socials/facebook.webp";
import tiktok from "../../assets/socials/tiktok.webp";
import { COLORS } from "../../constants/colors";
import ExternalLink from "../Common/Components/ExternalLink";

const LINKS = [
  {
    title: `© Cardigan Donuts ${dayjs().year()}`,
    href: ""
  },
  {
    title: "Donut schedule",
    href: ROUTES.UPCOMING_DONUT_SCHEDULE,
    ariaLabel: "See the upcoming inspired donut schedule"
  },
  {
    title: "Contact us",
    href: ROUTES.GET_IN_TOUCH,
    ariaLabel: "Get details for locations and hours"
  },
  {
    title: "Gift cards",
    href: ROUTES.SQUARE_GIFT_CARD,
    ariaLabel: "Buy a gift card"
  },
  {
    title: "Terms + Privacy",
    href: ROUTES.TERMS,
    ariaLabel: "Read the terms and privacy"
  },
  {
    title: "Work with us",
    href: ROUTES.JOB_OPENINGS,
    ariaLabel: "See information for potential job openings"
  },
  {
    title: "Wiki page",
    href: "https://en.wikipedia.org/wiki/Cardigan_Donuts",
    ariaLabel: "Go to the Cardigan Donuts Wikipedia page"
  },
];

const SOCIALS = [
  {
    image: instagram,
    href: "https://www.instagram.com/cardigandonuts",
    ariaLabel: "Go to Cardigan Donuts Instagram page"
  },
  {
    image: facebook,
    href: "https://www.facebook.com/CardiganDonuts",
    ariaLabel: "Go to Cardigan Donuts Facebook page"
  },
  {
    image: tiktok,
    href: "https://www.tiktok.com/@cardigandonuts",
    ariaLabel: "Go to Cardigan Donuts TikTok page"
  }
]

const Footer = () => {

  const lap = useScreenWidth("isLaptop");
  return (
    <Grid2 container className="p-relative background-color-red color-white p-t-26">
      <BottomLines yAxis={"top-5"} />
      <Grid2 container xs={12} justifyContent="center" alignItems="center" color={COLORS.white} className={`${lap ? "font-size-24 m-t-45" : "font-size-16 m-t-13"} futura-cond`}>
        <span>MPLS</span>
        <Link to={ROUTES.LANDING_PAGE}><img alt="Cardign Donuts Logo" src={logo} className={`${lap ? "width-83 m-l-28 m-r-28" : "width-46 m-l-14 m-r-14"}`} /></Link>
        <span>MINN</span>
      </Grid2>
      <Grid2 container xs={12} justifyContent={lap ? "center" : "flex-start"} className={`${lap ? "p-l-50 p-r-50 m-b-40" : "p-l-65 p-r-65 m-b-20"}`}>
        {LINKS.map((item, i) => (
          (!item.title.includes("©") || lap) &&
          <div key={item.title} className={`${i !== LINKS[LINKS.length - 1] ? lap ? "m-r-27" : "" : ""} ${lap ? "m-t-30" : "m-t-10 width-120 d-flex align-start"}`}>
            {item.title.includes("©") ?
              <p className={`${lap ? "font-size-20" : "font-size-14"} color-white pointer-none`}>
                {item.title}
              </p>
              :
              item.href.includes("http") ?
                <ExternalLink
                  text={item.title}
                  href={item.href}
                  ariaLabel={item.ariaLabel}
                  classes={`center color-white ${lap ? "font-size-20" : "font-size-14"}`}
                />
                :
                <Link
                  className={`white-link ${lap ? "font-size-20" : "font-size-14"}`}
                  state={{ fromNav: true }}
                  to={item.href}
                  aria-label={item.ariaLabel}
                >
                  {item.title}
                </Link>
            }
          </div>
        ))}
      </Grid2>
      <Grid2 container xs={12} justifyContent="center" className={`${lap ? "m-b-40" : "m-b-20"}`}>
        {!lap &&
          <div>
            <p className={`${lap ? "" : "font-size-14"} color-white`}>{LINKS[0].title}</p>
          </div>
        }
        <div className={`${lap ? "width-150" : "width-92 m-l-25"} d-flex justify-between`}>
          {SOCIALS.map(item => (
            <a
              key={item.href}
              href={item.href}
              target="_blank"
              aria-label={item.ariaLabel}
              rel="noopener noreferrer"
            >
              <div className={`${lap ? "diamond-30" : "diamond-20"} background-color-gold center`}>
                <img
                  alt={item.href}
                  src={item.image}
                  className={`${lap ? "height-21 " : "height-13"} -rotate-45`}
                />
              </div>
            </a>
          ))}
        </div>
      </Grid2>
    </Grid2>
  )
}

export default withLocalContext(Footer);