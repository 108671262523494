import { Fragment, useEffect, useState } from "react";
import LoadingState from "../Common/Components/LoadingState";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { removePeriods } from "../Common/Functions/removePeriods";
import withLocalContext from "../store/withLocalContext";

const ContactInfo = ({ context: { state } }) => {

  const [email, setEmail] = useState("");
  const [IDSTel, setIDSTel] = useState("");
  const [CCTel, setCCTel] = useState("");

  useEffect(() => {
    if (state.siteData.contact_info) {
      state.siteData.contact_info[0].map(item => {
        item.map(el => {
          if (el.content === "Hello") {
            setEmail(item.filter(a => a.header === "Email")[0].content)
          }
          if (el.content === "City Center") {
            setCCTel(item.filter(a => a.header === "Phone")[0].content)
          }
          if (el.content === "IDS") {
            setIDSTel(item.filter(a => a.header === "Phone")[0].content)
          }
          return el;
        })
        return item;
      })
    }
  }, [state.siteData]);

  const lap = useScreenWidth("isLaptop");

  return (
    <Fragment>
      {state.isMenuLoading ?
        <div className="height-40 m-b-40">
          <LoadingState loadText={"Loading contact info..."} />
        </div>
        :
        <Grid2 xs={12} className="text-align-left m-b-60">
          <div className="m-b-5">
            <p className={`${lap ? "" : "d-flex flex-direction-column"}`}>
              <span className={`futura-cond m-r-5 ${lap ? "font-size-24" : "font-size-20"}`}>Email:</span>
              <a className={`color-brown ${lap ? "font-size-24" : "font-size-16"}`} href={`mailto:${email}`}>{email}</a>
            </p>
          </div>
          <div className={`${lap ? "center" : "d-flex flex-direction-column m-t-20"} width-fit`}>
            <span className={`${lap ? "font-size-24" : "font-size-20"} futura-cond m-r-5`}>Phone(s):</span>
            <div className={`${lap ? "d-flex align-center" : ""}`}>
              <p className={`${lap ? "font-size-24 m-r-6" : "font-size-16"}`}>
                <a className={`m-r-3 color-red`} href={`tel:${removePeriods(CCTel)}`}>{CCTel}</a>
                <span>(City Center)</span>
              </p>
              {lap && <span className="diamond-15 d-flex background-color-gold" />}
              <p className={`${lap ? "font-size-24 m-l-6" : "font-size-16"}`}>
                <a className="color-red m-r-3" href={`tel:${removePeriods(IDSTel)}`}>{IDSTel}</a>
                <span>(IDS Center)</span>
              </p>
            </div>
          </div>
        </Grid2>
      }
    </Fragment>
  )
}
export default withLocalContext(ContactInfo);