import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import dayjs from "dayjs";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { calcDelivery, calcDiscounts, calcDonutDiscount, calcProdTotal, calcTax } from "../Common/Functions/CalculateTotals";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { toUSD } from "../Common/Functions/toUSD";
import ChooseNewTime from "../Popup/ChooseNewTime";
import ClearCart from "../Popup/ClearCart";
import OutOfStock from "../Popup/OutOfStock";
import { setAddToLS, setOrderDiscount, setPopupHTML } from "../store/actions";
import withLocalContext from "../store/withLocalContext";

const simClasses = "d-flex p-width-100 justify-between m-t-15";
const OrderTotals = ({
  lineInputs,
  btnInfo,
  setProdTotal,
  setTaxTotal,
  setDiscountTotal,
  setDonutDiscountTotal,
  setDeliveryTotal,
  setTotal,
  setGiftCardTotal,
  showBtn,
  context: { state, dispatch }
}) => {
  const navigate = useNavigate();
  const lap = useScreenWidth("isLaptop");

  // * * * * The following totals are in a precise order. Lines should not be changed unless absolutely certain * * * * \\

  // set product total
  const prodTotal = Number(calcProdTotal(state.cart));

  // check for shipping total
  const deliveryTotal = Number(state.deliveryMethods &&
    state.cartConfig &&
    state.cartConfig.deliveryType ?
    calcDelivery(state.cartConfig, state.deliveryMethods)
    :
    "0");

  const donutDiscount = calcDonutDiscount(state.cart);

  // check for discounts
  const discountTotal = Number(calcDiscounts((prodTotal + deliveryTotal), state.orderDiscount ? state.orderDiscount : 0));

  // set sub total
  const subTotal = Number(prodTotal - discountTotal - donutDiscount.amount + Number(deliveryTotal));

  // set tax total
  const taxTotal = Number(calcTax(subTotal, state.taxes ? state.taxes[0].taxData.percentage : 0));

  //calc tip total
  const tipTotal = Number((state.orderTip * 100));

  let giftCardTotal = Number(state.giftCards.length > 0 ? state.giftCards[0].balanceMoney.amount : "");
  let adjustedGiftCardTotal = giftCardTotal;
  if ((prodTotal + deliveryTotal + taxTotal + discountTotal + tipTotal) < giftCardTotal) {
    adjustedGiftCardTotal = (prodTotal + taxTotal + deliveryTotal + discountTotal + tipTotal);
  }

  // add it all together
  const checkoutObj = {
    products: {
      name: "Product Total",
      value: prodTotal,
      showZero: true
    },
    discount: {
      name: "Discount",
      value: (discountTotal + donutDiscount.amount) * -1,
      showZero: false
    },
    subtotal: {
      name: "Subtotal",
      value: subTotal,
      showZero: true
    },
    delivery: {
      name: "Delivery/Shipping",
      value: deliveryTotal,
      showZero: false
    },
    tax: {
      name: "Tax",
      value: taxTotal,
      showZero: true
    },
    tip: {
      name: "Tip",
      value: tipTotal,
      showZero: true
    },
    giftCard: {
      name: "Gift card",
      value: adjustedGiftCardTotal * -1,
      showZero: false
    }
  }

  const checkoutTotal = lineInputs.reduce((total, item) => Number(checkoutObj[item].value) + total, 0) - subTotal;

  useEffect(() => {
    if (setProdTotal) {
      setProdTotal(prodTotal);
    }

    if (setTaxTotal) {
      setTaxTotal(taxTotal);
    }

    if (setTotal) {
      setTotal(checkoutTotal);
    }
    if (setDiscountTotal) {
      setDiscountTotal(discountTotal);
    }
    if (setDonutDiscountTotal) {
      setDonutDiscountTotal(donutDiscount.amount);
    }
    if (setDeliveryTotal) {
      setDeliveryTotal(deliveryTotal);
    }
    if (setGiftCardTotal) {
      setGiftCardTotal(adjustedGiftCardTotal);
    }
  })

  let discountArr = [];

  if (state.orderDiscount && state.orderTotals) {
    discountArr.push({ name: state.orderDiscount.name + " discount", amount: discountTotal * -1, remove: true })
  }
  if (donutDiscount.amount > 0) {
    discountArr.push({ name: donutDiscount.name, amount: donutDiscount.amount * -1, remove: false })
  }

  const checkCart = () => {
    let unavailableItems = [];
    let orderType = state.cartConfig.orderType;

    if (orderType.id === "same") {
      let timeToCheck = state.cartConfig.time;
      let strippedPropTime = timeToCheck.substring(0, 5);
      let propTimeAtt = strippedPropTime.split(":")
      let minute = Number(propTimeAtt[1]);
      let hour;
      if (timeToCheck.includes("PM")) {
        hour = Number(propTimeAtt[0]) + 12;
      } else {
        hour = Number(propTimeAtt[0])
      }

      let proposedTime = dayjs().hour(hour).minute(minute).startOf("minute");
      let timeDiff = dayjs(proposedTime).diff(dayjs().startOf("minute"), "minute");

      if (timeDiff < 15) {
        dispatch(setAddToLS(true));
        return dispatch(setPopupHTML(<ChooseNewTime />))
      }
    }
    let menuIds = [];
    state.menu.map(category => {
      return category.subCategories.map(subCategory => {
        return subCategory.items.map(item => {
          return menuIds.push(item)
        })
      })
    })

    state.cart.map(item => {
      // check for unavailable items
      if (!menuIds.some(el => el.id === item.id)) {
        unavailableItems.push(item);
      }

      // check for out of stock items
      let outOfStockItem = menuIds.filter(el => el.id === item.id);

      if (outOfStockItem.length > 0) {
        if (outOfStockItem[0].outOfStock) {
          unavailableItems.push(outOfStockItem[0]);
        }
      }
      return item;
    })

    if (unavailableItems.length > 0) {
      return dispatch(setPopupHTML(<OutOfStock items={unavailableItems} />))
    }
    navigate(btnInfo.route)
  }

  const LineObj = ({ name, amount, remove }) => {
    return (
      <div className={`${simClasses}`}>
        <p>{name}</p>
        {remove &&
          <button onClick={remove} className="color-red underline">remove</button>
        }
        <p>{toUSD(amount)}</p>
      </div>
    )
  }

  return (
    <Grid2 container xs={12} className="color-brown align-start">
      <Grid2 xs={12} className={`${lap ? "m-t-50" : "m-t-40"}`}>
        {lineInputs.map(item => (
          (checkoutObj[item].showZero || Number(checkoutObj[item].value) !== 0) &&
          <Fragment key={item}>
            {item === "subtotal" &&
              <div className="height-1 background-color-tan -m-b-10 m-t-20" />
            }
            {
              item === "discount" ?
                discountArr.map(el => (
                  <LineObj
                    key={el.name}
                    name={el.name}
                    remove={el.remove ? () => dispatch(setOrderDiscount("")) : false}
                    amount={el.amount}
                  />
                ))
                :
                <LineObj
                  key={checkoutObj[item].name}
                  name={checkoutObj[item].name}
                  amount={checkoutObj[item].value}
                />
            }
          </Fragment>
        ))}
      </Grid2>
      <Grid2 xs={12} container justifyContent={lap ? "flex-start" : "center"}>
        {showBtn ?
          <Fragment>
            <button
              className={`red-button d-flex center m-t-35 ${lap ? "" : "max-width-250"}`}
              disabled={state.isMenuLoading}
              onClick={() => checkCart()}
            >
              <span className="m-r-6">{btnInfo.text}</span>
              <span className="m-l-6">{toUSD(checkoutTotal)}</span>
            </button>
            {!lap &&
              <button
                className="red-button-flat max-width-140 m-t-15"
                onClick={() => dispatch(setPopupHTML(<ClearCart />))}
              >
                Clear cart
              </button>
            }
          </Fragment>
          :
          <div className="p-width-100">
            <div className="height-3 p-width-100 background-color-tan m-y-20" />
            <div className="d-flex justify-between">
              <p className="color-red">Total</p>
              <p className="color-red">{toUSD(checkoutTotal)}</p>
            </div>
          </div>
        }
      </Grid2>
    </Grid2>
  )
}

export default withLocalContext(OrderTotals);