import withLocalContext from "../../store/withLocalContext"
import GiftCardPromo from "./GiftCardPromo";
import OrderTotals from "../OrderTotals";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import Tips from "../../Tips";
import { COLORS } from "../../../constants/colors";
import PersonalInfo from "./PersonalInfo";
import { setHeaderTitle, setOrderTotals } from "../../store/actions";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";
import { usePageTracking } from "../../Tracking";

const Checkout = ({ context: { state, dispatch } }) => {
  const [prodTotal, setProdTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [donutDiscountTotal, setDonutDiscountTotal] = useState(0);
  const [deliveryTotal, setDeliveryTotal] = useState(0);
  const [giftCardTotal, setGiftCardTotal] = useState(0);

  const lap = useScreenWidth("isLaptop");
  const tab = useScreenWidth("isTablet");

  useEffect(() => {
    dispatch(setOrderTotals({
      prod: prodTotal,
      tax: taxTotal,
      total: total,
      tip: Number(state.orderTip.replace(".", "")),
      discount: discountTotal,
      donutDiscount: donutDiscountTotal,
      delivery: deliveryTotal,
      giftCard: giftCardTotal
    }));
  }, [total, prodTotal, taxTotal, discountTotal, deliveryTotal, donutDiscountTotal, giftCardTotal, dispatch, state.orderTip])

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setHeaderTitle(""));
  }, [dispatch]);

  usePageTracking();

  return (
    <Grid2 id="cardigan_checkout" container sx={{ width: "calc(100% + 30px)", margin: tab ? "0 -15px 0 30px" : "0 -15px" }}>
      <Grid2 container xs={12} sx={{ border: `3px solid ${COLORS.tan}` }} className={`${lap ? "p-a-55" : "p-a-15"}`}>
        <Grid2 container xs={lap ? 6 : 12} className={`${lap ? "p-r-30" : ""}`}>
          <GiftCardPromo total={total} />
          <Grid2 xs={12}>
            <Tips total={prodTotal + taxTotal} />
          </Grid2>
        </Grid2>
        <Grid2 container xs={lap ? 6 : 12} className={`${lap ? "p-l-30" : "p-l-25 p-r-25"}`}>
          {state.cart.length > 0 &&
            <OrderTotals
              lineInputs={[
                "products",
                "delivery",
                "discount",
                "subtotal",
                "tax",
                "tip",
                "giftCard"
              ]}
              setProdTotal={(val) => setProdTotal(val)}
              setTaxTotal={(val) => setTaxTotal(val)}
              setTotal={(val) => setTotal(val)}
              setDiscountTotal={(val) => setDiscountTotal(val)}
              setDonutDiscountTotal={(val) => setDonutDiscountTotal(val)}
              setDeliveryTotal={(val) => setDeliveryTotal(val)}
              setGiftCardTotal={(val) => setGiftCardTotal(val)}
            />
          }
        </Grid2>
      </Grid2>
      <Grid2 container xs={12} className="">
        <PersonalInfo
          total={total}
        />
      </Grid2>
    </Grid2>
  )
}

export default withLocalContext(Checkout);