import { ClickAwayListener } from "@mui/material";
import { useState } from "react";
import { adjustMenu } from "../Functions/adjustMenu";

const MultiListDropdownMenu = ({ items, category, setOptions, id }) => {
  const [isVisible, setIsVisible] = useState(false);
  const catId = id.replace(/ /g, "_").toLowerCase();
  const ulId = "ul_" + catId;

  return (
    <ClickAwayListener onClickAway={() => setIsVisible(false)}>
      <div
        className="p-relative right-0 color-gold underline cursor-pointer"
      >
        <span
          id={catId}
          onClick={() => {
            setIsVisible(!isVisible)
            adjustMenu("", catId, ulId, !isVisible);
          }}>
          swap for another topping
        </span>
        <ul
          id={ulId}
          className={`p-absolute m-t-5 background-color-white p-l-0 p-width-100 max-width-493 transition ${isVisible ? `height-${items.length * 45} max-height-300 border-brown dropdown-menu-box-shadow z-index-1 overflow-scroll` : "height-0 border-white -z-index-1 overflow-hidden"}`}
        >
          <li className="futura-cond p-l-35 m-t-23 m-b-10">{category}</li>
          {items.map((drop, i) => (
            <li
              key={drop.id + String(i)}
              onClick={() => { setOptions(drop); setIsVisible(false); }}
              className="height-45 color-brown d-flex align-center p-l-35 dropdown-item"
            >
              {drop.name}
            </li>
          ))}
        </ul>
      </div >
    </ClickAwayListener>
  )
}

export default MultiListDropdownMenu;