export const adjustMenu = (containerId, anchorId, menuId, isVisible, isLaptop = "") => {

  let dropdownContainer = "";
  if (containerId) {
    dropdownContainer = document.getElementById(containerId);
  } else {
    dropdownContainer = document.getElementById("cardiganPopup");
  }
  const dropdown = document.getElementById(anchorId);

  const initialMenuBottom = dropdownContainer.getBoundingClientRect().bottom - dropdown.getBoundingClientRect().bottom;

  let ulEl = document.getElementById(menuId);

  const menuHeight = ulEl.children.length * (isLaptop ? 46 : 38);

  let bottom = "unset";
  if (isVisible) {
    if (initialMenuBottom < 300) {
      if (menuHeight > initialMenuBottom) {
        let bottomPxs = (initialMenuBottom - 30) * -1 + "px";
        bottom = bottomPxs;
      }
    }
  }
  ulEl.style.bottom = bottom;
}