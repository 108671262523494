import React from "react";
import { Routes, Route } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import LandingPage from "../LandingPage";
import LoginPage from "../LoginPage";
import Cart from "../Order/Cart";
import Checkout from "../Order/Checkout";
import Menu from "../Menu";
import MenuItem from "../MenuItem";
import CorporateEvents from "../CorporateEvents";
import Weddings from "../Weddings";
import JobOpenings from "../JobOpenings";
import GetInTouch from "../GetInTouch";
import AboutUs from "../AboutUs";
import UpcomingDonuts from "../UpcomingDonuts";
import MailMeDonuts from "../MailMeDonuts";
import TermsAndPrivacy from "../TermsAndPrivacy";
import Order from "../Order";
import Confirmation from "../Confirmation";
import ScrollToTop from "../ScrollToTop";
import MobileMenuCategories from "../MobileMenuCategories";
import EmailTemplate from "../EmailTemplate";

const ComponentRoutes = () => (
  <ScrollToTop>
    <Routes>
      <Route path={ROUTES.LOGIN_IN} element={<LoginPage />} />
      <Route path={ROUTES.LANDING_PAGE} element={<LandingPage />} />
      <Route element={<Order />} >
        <Route path={ROUTES.CART} element={<Cart />} />
        <Route path={ROUTES.CHECKOUT} element={<Checkout />} />
        <Route path={ROUTES.CONFIRMATION} element={<Confirmation />} />
      </Route>
      <Route path={ROUTES.MENU} element={<Menu />} />
      <Route path={ROUTES.MENU_CATEGORIES} element={<MobileMenuCategories />} />
      <Route path={ROUTES.CORPORATE_EVENTS} element={<CorporateEvents />} />
      <Route path={ROUTES.WEDDINGS} element={<Weddings />} />
      <Route path={ROUTES.JOB_OPENINGS} element={<JobOpenings />} />
      <Route path={ROUTES.GET_IN_TOUCH} element={<GetInTouch />} />
      <Route path={ROUTES.MAIL_ME_DONUTS} element={<MailMeDonuts />} />
      <Route path={ROUTES.UPCOMING_DONUT_SCHEDULE} element={<UpcomingDonuts />} />
      <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
      <Route path={ROUTES.EMAIL_TEMPLATE} element={<EmailTemplate />} />
      <Route path={ROUTES.TERMS} element={<TermsAndPrivacy />} />
      <Route path={`${ROUTES.PRODUCTS}/:slug`} element={<MenuItem />} />
    </Routes>
  </ScrollToTop>
);
export default ComponentRoutes;