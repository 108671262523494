import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { productArr } from "../../constants/carousels";
import linen from "../../assets/linenBg.svg";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../constants/colors";
import { setPageDots } from "../Common/Functions/setPageDots";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { ROUTES } from "../../constants/routes";

const StyledBg = styled.div`
  height: ${props => Math.round(props.height)}px;
  background: url(${linen}) no-repeat center/cover;
`;

const Products = () => {

  const location = useLocation();
  const [contWidth, setContWidth] = useState(0);
  const carId = "productsCarousel";
  const lap = useScreenWidth("isLaptop");
  const [isCarousel, setIsCarousel] = useState(true);

  useEffect(() => {

    if (window.Flickity) {
      var elem = document.querySelector('.products-carousel');
      let carousel = new window.Flickity(elem, {
        draggable: true,
        wrapAround: true,
        pageDots: false,
        groupCells: true,
        cellAlign: 'center',
        imagesLoaded: true
      });
      setPageDots(carId, carousel, lap ? 3 : 5);
      setContWidth(getContWidth);
    } else {
      setIsCarousel(false)
    }
  }, [location, lap])

  const containerWidth = window.innerWidth;

  // decimal of 0.33 based on figma designs
  const height = lap ? Math.round(containerWidth * 0.33) : 288;

  const getContWidth = () => {
    let container = document.getElementById(carId);
    if (container) {
      return Math.round(container.offsetWidth / 3);
    }
  }

  return (
    isCarousel ?
      <Grid2
        container
        xs={12}
        sx={{
          height: height,
          maxWidth: "1240px"
        }}
        justifyContent="center"
        className={`m-t-100 m-b-45`}
      >
        <div id={carId} className="products-carousel carousel p-width-100">
          {productArr.map(item => (
            <div key={item.title} className={`carousel-cell  width-${lap ? contWidth : 185}`}>
              <div className={`${lap ? "p-x-21" : "p-x-10"}`}>
                <StyledBg height={height}>
                  <Link
                    className="text-decoration-none"
                    to={!lap ? item.link.href === ROUTES.MENU ? ROUTES.MENU_CATEGORIES : item.link.href : item.link.href}
                    aria-label={item.ariaLabel}
                    state={{ fromNav: item.link.href === ROUTES.MENU ? true : false }}
                  >
                    <Grid2
                      container
                      alignItems="flex-end"
                      justifyContent="center"
                      sx={{
                        backgroundColor: COLORS.white + "40"
                      }}
                      className={`p-r-8 p-l-8 p-height-100`}
                    >
                      <Grid2 xs={12}>
                        <img alt="" src={item.image} className={`${lap ? `height-${item.dimensions.height} width-${item.dimensions.width}` : `height-${Math.round(item.dimensions.height * .42)} width-${Math.round(item.dimensions.width * .42)}`}`} />
                      </Grid2>
                      <Grid2 xs={12} className="m-b-15">
                        <h3 className={`${lap ? "" : "font-size-16"} color-brown m-b-20`}>{item.title}</h3>
                        <p className={`${lap ? "font-size-20 height-85" : "font-size-13 height-70"} color-brown max-width-300 m-auto`}>{item.description}</p>
                        <p className={`${lap ? "font-size-20 height-57" : "font-size-13 height-30"} p-x-10 d-flex justify-center underline color-red`}>{item.link.title}</p>
                      </Grid2>
                    </Grid2>
                  </Link>
                </StyledBg>
              </div>
            </div>
          ))}
        </div>
      </Grid2>
      :
      null
  )
}

export default Products;