import { ThemeProvider, createTheme } from "@mui/material";

// https://mui.com/material-ui/react-grid2/#custom-breakpoints
// https://mui.com/material-ui/customization/breakpoints/#default-breakpoints

const CustomBreakpoints = ({ children, customBreakpoints }) => {
  return (
    <ThemeProvider
      theme={createTheme({
        breakpoints: {
          values: customBreakpoints,
        },
      })}
    >
      {children}
    </ThemeProvider>
  )
}

export default CustomBreakpoints;