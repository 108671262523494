import dayjs from "dayjs";

export const JSDateToExcelDate = (inDate, includeTime) => {
  var returnDateTime = 25569.0 + ((inDate.getTime() - (inDate.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
  return returnDateTime.toString().substring(0, includeTime ? 20 : 5);
}

export const timeDateToExcel = (date, time) => {
  if (time) {
    const splitTime = time.split(" ");
    const splitHourMinute = splitTime[0].split(":");
    const dateTime = dayjs(date).hour(Number(splitHourMinute[0]) + (splitTime[1] === "AM" ? 0 : 12)).minute(splitHourMinute[1]).startOf('minute');
    return Number(JSDateToExcelDate(dayjs(dateTime).toDate(), true));
  } else {
    return "";
  }
}