import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import DiamondPageHeader from "../Common/Components/DiamondPageHeader";
import withLocalContext from "../store/withLocalContext";
import { ABOUT_DATA } from "./AboutData";
import aboutUsHeaderImage from "../../assets/aboutUsHeaderImage.webp";
import NewsArticle from "./NewsArticle";
import { useEffect } from "react";
import { setHeaderTitle } from "../store/actions";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import styled from "styled-components";
import { usePageTracking } from "../Tracking";
// import axios from "axios";

const StyledImg = styled.img`
${props => props.islap === "false" && `
    width: calc(100% + 60px);
  `}
`;

const AboutUs = ({ context: { dispatch } }) => {

  const lap = useScreenWidth("isLaptop");
  const desk = useScreenWidth("isDesktop");
  useEffect(() => {
    dispatch(setHeaderTitle(title))
  }, [dispatch])

  // useEffect(() => {
  //   async function getHomePage() {
  //     const data = await axios.get("http://store.cardigandonuts.com/api/home-page");
  //     console.log('data', data.data.data.attributes.LocationsBtnTitle);
  //   }
  //   getHomePage();
  // });

  usePageTracking();

  const title = "Our Story";
  const imageHtmlContent = <StyledImg islap={String(lap)} className={`object-fit-cover ${lap ? "p-width-100" : "-m-x-30"}`} alt="" src={aboutUsHeaderImage} />;

  const diamondHtmlContent = <div className="max-width-300 center flex-direction-column">
    <h1 className={`color-white ${desk ? "font-size-50" : "font-size-35"}`}>{title}</h1>
  </div>;

  return (
    <Grid2 container xs={12} className={`m-b-65 ${lap ? "m-t-85 p-l-100 p-r-100" : "p-l-30 p-r-30"}`}>
      <Grid2 container xs={12}>
        <DiamondPageHeader imageHtmlContent={imageHtmlContent} diamondHtmlContent={diamondHtmlContent} />
      </Grid2>
      <Grid2
        container
        xs={12}
        className={`${lap ? "m-t-95" : "m-t-24 text-align-left"} m-b-35`}
        sx={{ ">p": { maxWidth: "1090px" } }}
      >
        <h2 className="color-red">Astonishing Donuts, Food, & Hospitality</h2>
        <p className="m-b-55 font-size-24 text-align-left m-t-25">Cardigan's mission since opening in 2017 has been to sweeten life for all through astonishing donuts, food and hospitality.  Along the way, our story has been featured and talked about by local press within the Twin Cities.  Take a look below  for some key moments over the years.</p>
        <h3 className={`${lap ? "font-size-24" : "font-size-20"} color-blue`}>Media coverage over the years...</h3>
      </Grid2>
      <Grid2 container justifyContent={"space-between"} xs={12} sx={{ gap: "20px" }}>
        {ABOUT_DATA.map((item, i) => (
          <NewsArticle key={i} data={{ ...item, index: i }} />
        ))}
      </Grid2>
    </Grid2>
  )
}
export default withLocalContext(AboutUs);