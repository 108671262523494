import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { COLORS } from "../../../constants/colors";
import { modifyCart, setBanner, setCartConfig, setPopupHTML } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";

const titleClasses = "futura-cond font-size-20 text-align-right";
const divClasses = "text-align-left m-l-20 d-flex align-start justify-center flex-direction-column";
const OrderTypeItemsUnavailable = ({ items, proposedConfig, context: { state, dispatch } }) => {

  const removeItems = () => {
    let tempCart = state.cart;
    items.map(prod => {
      return tempCart.splice(tempCart.findIndex(item => item.id === prod.id), 1)
    })
    dispatch(modifyCart(tempCart));
    dispatch(setCartConfig(proposedConfig));
    dispatch(setBanner("Cart configured"));
    dispatch(setPopupHTML(""));
  }

  return (
    <Grid2 container className="max-width-400" justifyContent={"center"}>
      <Grid2 container xs={12} justifyContent="center" className="p-sticky top-0 z-index-250 background-color-white">
        <h3>Order Type</h3>
      </Grid2>
      <Grid2 container xs={12} justifyContent="center" alignContent={"center"} className="p-t-22 p-b-12">
        <h4 className={`${titleClasses} max-width-125`}>Previous order type</h4>
        <div className={`${divClasses}`}>
          <p>{state.cartConfig.orderType.name}</p>
          <p>
            {state.cartConfig.location ?
              state.cartConfig.location
              :
              state.cartConfig.deliveryType ?
                state.cartConfig.deliveryType.name
                :
                ""
            }
          </p>
        </div>
      </Grid2>
      <Grid2 container xs={12} justifyContent="center" alignContent={"center"} sx={{ backgroundColor: `${COLORS.tan}40` }} className="border-2-b-tan p-t-12 p-b-12">
        <h4 className={`${titleClasses} max-width-125`}>Updated order type</h4>
        <div className={`${divClasses}`}>
          <p>{proposedConfig.orderType.name}</p>
          <p>
            {proposedConfig.location ?
              proposedConfig.location
              :
              proposedConfig.deliveryType ?
                proposedConfig.deliveryType.name
                :
                ""
            }
          </p>
        </div>
      </Grid2>
      <Grid2 container xs={12} className="m-t-25">
        <Grid2 container xs={12} justifyContent="center">
          <p className="font-size-20 max-width-300">As a result you will not be able to keep these items in your cart:</p>
        </Grid2>
        <Grid2 container xs={12} justifyContent="center">
          <div className="width-fit m-t-18">
            {items.map((prod, i) => (
              <p key={prod.name + i} className="text-align-left font-size-20">{i + 1}. {prod.name}</p>
            ))}
          </div>
        </Grid2>
      </Grid2>
      <Grid2 container xs={12} className="m-t-40">
        <Grid2 container xs={12} justifyContent="center">
          <h3 className="font-size-20">Are you sure you want to proceed?</h3>
        </Grid2>
        <Grid2 container xs={12} justifyContent="center">
          <div className="center m-t-20">
            <button
              className="red-button-outline min-width-110 m-r-5"
              onClick={() => dispatch(setPopupHTML(""))}
            >
              Cancel
            </button>
            <button
              className="red-button min-width-110 m-l-5"
              onClick={() => removeItems()}
            >
              Yes
            </button>
          </div>
        </Grid2>
      </Grid2>
    </Grid2>
  )
}

export default withLocalContext(OrderTypeItemsUnavailable);