import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useEffect, useState } from "react";
import { inputWrappers } from "..";
import { STATES } from "../../../../../constants/states";
import Checkbox from "../../../../Common/Components/Checkbox";
import SubmitBtn from "../../SubmitBtn";
import { TextInput } from "../../Inputs";
import { usePersonInfo } from "../Provider";
import { useScreenWidth } from "../../../../Common/Functions/useScreenWidth";

const Delivery = ({ sectionId, setSectionErrors, showSubmitBtn, total }) => {
  const {
    state,
    addDeliveryFirstName,
    addDeliveryLastName,
    addDeliveryAddress,
    addDeliveryApt,
    addDeliveryCity,
    addDeliveryState,
    addDeliveryZipcode
  } = usePersonInfo();

  const [isChecked, setIsChecked] = useState(false);
  const lap = useScreenWidth("isLaptop");

  useEffect(() => {
    setSectionErrors(state.sectionErrors)
  })

  useEffect(() => {
    setDeliveryAddress();
    // eslint-disable-next-line
  }, [isChecked])

  const setDeliveryAddress = () => {
    addDeliveryFirstName(isChecked ? state.billingFirstName : "");
    addDeliveryLastName(isChecked ? state.billingLastName : "");
    addDeliveryAddress(isChecked ? state.billingAddress : "");
    addDeliveryApt(isChecked ? state.billingApt : "");
    addDeliveryCity(isChecked ? state.billingCity : "");
    addDeliveryState(isChecked ? state.billingState : "");
    addDeliveryZipcode(isChecked ? state.billingZipcode : "");
  }

  return (
    <Grid2 container xs={12}>
      <Grid2 xs={12}>
        <h3>Delivery address</h3>
      </Grid2>
      <Grid2 xs={12}>
        <div
          className="cursor-pointer user-select-none d-flex align-center m-b-35 width-fit"
          onClick={() => setIsChecked(!isChecked)}
        >
          <Checkbox
            isChecked={isChecked}
          />
          <p>Same as billing address</p>
        </div>
      </Grid2>
      <Grid2 xs={12}>
        <div className={`${inputWrappers} ${lap ? "m-r-100" : ""}`}>
          <TextInput
            id="deliveryFirstName"
            maxWidth="50%"
            label="First name"
            value={state.deliveryFirstName}
            onChange={(e) => addDeliveryFirstName(e.target.value)}
            sectionId={sectionId}
            isDisabled={isChecked}
            hasProvider
          />
          <TextInput
            id="deliveryLastName"
            maxWidth="50%"
            label="Last name"
            value={state.deliveryLastName}
            onChange={(e) => addDeliveryLastName(e.target.value)}
            sectionId={sectionId}
            isDisabled={isChecked}
            hasProvider
          />
        </div>
        <div className={`${inputWrappers}`}>
          {isChecked ?
            <TextInput
              id="deliveryAddress"
              maxWidth="66%"
              label="Address"
              value={state.deliveryAddress}
              onChange={(e) => addDeliveryAddress(e.target.value)}
              sectionId={sectionId}
              isDisabled={isChecked}
              hasProvider
            />
            :
            <TextInput
              id="deliveryAddress"
              maxWidth="66%"
              label="Delivery Address"
              value={state.deliveryAddress}
              onChange={(val) => {
                if (val !== "") {
                  if (val.components.city) {
                    addDeliveryCity(val.components.city)
                  }
                  if (val.components.state) {
                    addDeliveryState({ name: val.components.state })
                  }
                  if (val.components.postal_code) {
                    addDeliveryZipcode(val.components.postal_code)
                  }
                  addDeliveryAddress(val.short_add)
                } else {
                  addDeliveryAddress(val)
                }
              }}
              sectionId={sectionId}
              type="address"
              hasProvider
            />
          }
          <TextInput
            id="deliveryApt"
            maxWidth="33%"
            label="Apartment, Suite, ect."
            value={state.deliveryApt}
            onChange={(e) => addDeliveryApt(e.target.value)}
            sectionId={sectionId}
            notRequired
            isDisabled={isChecked}
            hasProvider
          />
        </div>
        <div className={`${inputWrappers}`}>
          <TextInput
            id="deliveryCity"
            maxWidth="33%"
            label="City"
            value={state.deliveryCity}
            onChange={(e) => addDeliveryCity(e.target.value)}
            sectionId={sectionId}
            isDisabled={isChecked}
            hasProvider
          />
          <TextInput
            id="deliveryState"
            maxWidth="33%"
            label="State"
            value={state.deliveryState}
            onChange={(val) => addDeliveryState(val[0])}
            sectionId={sectionId}
            containerId={"cardigan_checkout"}
            type="dropdown"
            options={STATES}
            isDisabled={isChecked}
            isControlled
            hasProvider
          />
          <TextInput
            id="deliveryZipcode"
            maxWidth="33%"
            label="Zipcode"
            value={state.deliveryZipcode}
            onChange={(e) => addDeliveryZipcode(e.target.value)}
            sectionId={sectionId}
            isDisabled={isChecked}
            hasProvider
          />
        </div>
      </Grid2>
      {showSubmitBtn &&
        <Grid2 container xs={12} justifyContent={`${lap ? "flex-end" : "center"}`} className="m-t-30" >
          <SubmitBtn total={total} />
        </Grid2>
      }
    </Grid2>
  )
}

export default Delivery;