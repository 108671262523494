import { toUSD } from "../../../../../../Common/Functions/toUSD";
import { COLORS } from "../../../../../../../constants/colors";

export const paymentConfirm = (confirmation) => {

  const { contact:
    { billing: {
      billingAddress,
      billingApt,
      billingCity,
      billingState,
      billingZipcode
    },
      delivery: {
        deliveryAddress,
        deliveryApt,
        deliveryCity,
        deliveryState,
        deliveryZipcode
      }
    },
    order: {
      lineItems,
      totalTaxMoney,
      totalMoney,
      totalDiscountMoney
    },
    payment,
    tip
  } = confirmation;

  const makeAddress = (address, apt, city, state, zipcode) => {
    const fullAddress = `${address ? `${address}, ` : ""}${apt ? `${apt}, ` : ""}${city ? `${city}, ` : ""}${state ? `${state}, ` : ""}${zipcode ? `${zipcode}` : ""}`;
    return fullAddress;
  }

  const fullBillingAddress = makeAddress(billingAddress, billingApt, billingCity, billingState, billingZipcode);
  const fullDeliveryAddress = makeAddress(deliveryAddress, deliveryApt, deliveryCity, deliveryState, deliveryZipcode);

  const delivery = lineItems.some(item => item.name === "Delivery") ? lineItems.filter(item => item.name === "Delivery")[0].basePriceMoney.amount : 0;
  const discount = Number(totalDiscountMoney.amount) !== 0 ? Number(totalDiscountMoney.amount) * -1 : Number(totalDiscountMoney.amount);

  const orderTotals = [
    {
      title: "Product total",
      amount: Number(totalMoney.amount) + Number(totalDiscountMoney.amount) - Number(totalTaxMoney.amount)
    },
    {
      title: "Discounts",
      amount: discount
    },
    {
      title: "Delivery/Shipping",
      amount: Number(delivery),
    },
    {
      title: "Subtotal",
      amount: Number(totalMoney.amount) - Number(totalTaxMoney.amount)
    },
    {
      title: "Tax",
      amount: Number(totalTaxMoney.amount)
    },
    {
      title: "Tip",
      amount: Number(tip)
    },
    {
      title: "Total",
      amount: Number(totalMoney.amount) + Number(tip)
    }
  ]

  let html = `<div style="padding: 25px 30px;">`;

  if (Array.isArray(payment)) {
    html += `<div>
  <h4 style="color: ${COLORS.blue}; text-transform: uppercase; font-family: 'Open Sans', sans-serif; font-size: 16px; margin-bottom: 15px;">Payment</h4>
          <div style="width: fit-content; display: flex; flex-direction: column; align-content: flex-end;">`;

    payment.map(item => {
      html += `<div style="display:flex; align-items: center;">
                    <div style="margin-right: 4px;">`;
      html += `<p>${item.cardDetails.card.cardBrand.replace(/_/g, " ")}</p>`

      return html += `</div><p>ending in ${item.cardDetails.card.last4}</p></div>`;
    })
    html += `</div></div>`
  }
  if (billingAddress) {
    html += `<div style="margin-top: 30px;">
  <h4 style="color: ${COLORS.blue}; text-transform: uppercase; font-family: 'Open Sans', sans-serif; font-size: 16px; margin-bottom: 15px;">Billing address</h4>
  <p>${fullBillingAddress}</p>
</div>`
  }
  if (fullDeliveryAddress) {
    html += `<div style="margin-top: 30px;">
      <h4 style="color: ${COLORS.blue}; text-transform: uppercase; font-family: 'Open Sans', sans-serif; font-size: 16px; margin-bottom: 15px;">Delivery address</h4>
      <p>${fullDeliveryAddress}</p>
    </div>`;
  }
  html += `<div style="display: flex; margin-top: 30px;">
    <div>
      <div style="max-width: 322px;">`;

  orderTotals.map((item, i) => {
    if (item.amount !== 0) {
      if (item.title === "Subtotal") {
        html += `<div style="height: 1px; background-color: ${COLORS.tan}; margin-bottom: -3px; margin-top: 15px;"></div>`;
      }
      if (orderTotals.length - 1 === i) {
        html += `<div style="height: 3px; background-color: ${COLORS.tan}; margin: 20px 0;"></div>`;
      }
      html += `<div style="margin-top: 8px; display: flex; justify-content: space-between; align-content: center; ${orderTotals.length - 1 === i ? `color: ${COLORS.red};` : `color: ${COLORS.brown};`}">
              <span>${item.title}</span>
              <span style="margin-left: 5px;">${toUSD(item.amount)}</span>
            </div>`;
    }
    return item;
  })
  html += `</div></div></div></div>`;

  return html;
}