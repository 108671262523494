export const specificOrderTypes = {
  preDel: "Pre-Order Delivery",
  prePickCC: "Pre-Order Pickup CC",
  prePickFRG: "Pre-Order Pickup FRGMNT",
  prePickIDS: "Pre-Order Pickup IDS",
  sameIDS: "Same Day Pick-up IDS",
  sameCC: "Same Day Pick-up CC"
}

// do not change the order of this array
export const ORDER_PSEUDO_NAMES = [
  {
    id: "same",
    name: "Pick-up today",
    pseudoNames: ["Same day pick-up (today only)", "Same Day Pick-up", "Same Day Pick-up IDS", "Same Day Pick-up CC", "Pick-up today"],
    externalId: "Pick-up today"
  },
  {
    id: "prepick",
    name: "Pick-up (tomorrow or later)",
    pseudoNames: ["Pre-order pick-up (tomorrow or another day)", "Pre-order Pick-up", "Pre-Order Pickup FRGMNT", "Pre-Order Pickup IDS", "Pre-Order Pickup CC"],
    externalId: "Schedule a pick-up (tomorrow or another day)"
  },
  {
    id: "delivery",
    name: "Delivery (tomorrow or later)",
    pseudoNames: ["Pre-order delivery (tomorrow or another day)", "Pre-Order Delivery"],
    externalId: "Schedule a delivery (tomorrow or another day)"
  }
]