import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";
import { setPopupHTML } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";

const Message = ({ title, message, context: { dispatch } }) => {
  const lap = useScreenWidth("isLaptop");
  return (
    <Grid2 container xs={12} justifyContent="center">
      <h3 className={`sentinel ${lap ? "font-size-20" : "font-size-20"} color-red m-b-20`}>{title}</h3>
      {message &&
        message
      }
      <Grid2 xs={12} sx={{ marginTop: "20px" }}>
        <button onClick={() => dispatch(setPopupHTML(""))} className="red-button">Close</button>
      </Grid2>
    </Grid2>
  )
}

export default withLocalContext(Message);