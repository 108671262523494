import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import styled from "styled-components";
import { useScreenWidth } from "../../Functions/useScreenWidth";

const StyledDiamondCont = styled.div`
  top: 50%;
  transform: translate(0,-50%);
`;
const DiamondPageHeader = ({ imageHtmlContent, diamondHtmlContent }) => {

  const lap = useScreenWidth("isLaptop");
  const height = Math.round(window.innerWidth * (lap ? 0.25 : 0.38));
  const diamondSize = Math.round(height * 0.81);
  const contMarginLeft = Math.round(diamondSize / 2);

  return (
    <Grid2 container xs={12} sx={{ maxWidth: "1312px" }} className="p-relative">
      <div className={`p-width-100 d-flex max-height-${height} ${lap ? `m-l-${contMarginLeft}` : ""}`}>
        {imageHtmlContent}
      </div>
      {lap ?
        <StyledDiamondCont className="p-absolute">
          <div className={`diamond-${diamondSize} background-color-brown center`}>
            <div className="-rotate-45 center height-fit width-fit">
              {diamondHtmlContent}
            </div>
          </div>
        </StyledDiamondCont>
        :
        ""
      }
    </Grid2>
  )
}

export default DiamondPageHeader;