import React, { useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import TextField from "@material-ui/core/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import withLocalContext from "../../store/withLocalContext";
import { COLORS } from "../../../constants/colors";

const StyledTextField = styled(TextField)`
  >div{
    ${props => props.height ? `height: 100%;` : ``}
  }

  > div > input {
    &::placeholder {
      color: ${COLORS.brown} !important;
      font-size: 16px;
      font-family: Futura;
      opacity: 0.8;
      ${(props) => (props.initialvalue && `
      opacity:1;
      `)}
    }
    &:hover {
      border: none;
    }
  }

  .MuiOutlinedInput-inputAdornedEnd {
    padding-left: 5px !important;
  }
`;

export const CheckoutAddress = ({ selectedValue, initialValue, height, onBlur }) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      componentRestrictions: { country: ["us"] },
      types: ["address"],
    }
  });

  const getAddressDeets = (addy) => {
    let addyComponents = {};
    placesService.getDetails(
      {
        placeId: addy.place_id,
      },
      (placeDetails) => {
        placeDetails.address_components.map(component => {
          return component.types.map(type => {
            switch (type) {
              case "postal_code":
                addyComponents = { ...addyComponents, postal_code: component.long_name };
                break;
              case "street_number":
                addyComponents = { ...addyComponents, street_number: component.long_name };
                break;
              case "route":
                addyComponents = { ...addyComponents, street: component.long_name };
                break;
              case "locality":
                addyComponents = { ...addyComponents, city: component.long_name };
                break;
              case "administrative_area_level_1":
                addyComponents = { ...addyComponents, state: component.short_name };
                break;
              default:
                break;
            }
            return type
          })
        });
        let payload = {
          short_add: addy.structured_formatting.main_text,
          components: addyComponents
        };
        selectedValue(payload);
      }
    );
  };

  return (
    <Autocomplete
      className={`d-flex justify-start ${height ? height : ""}`}
      onBlur={() => {
        onBlur(initialValue)
      }}
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      getOptionSelected={(option, value) => {
        return option.description === value.description
      }}
      popupIcon={null}
      getOptionLabel={(option) => option.structured_formatting.main_text}
      onChange={(evt, val) => {
        if (val) getAddressDeets(val);
      }}
      id="address-lookup"
      options={placePredictions}
      loading={isPlacePredictionsLoading}
      renderOption={(option) => (
        <Grid2 container alignItems="center">
          <Grid2>
            <FontAwesomeIcon icon={faMapMarkerAlt} color={COLORS.red} />
          </Grid2>
          <Grid2 className="m-l-20">
            <p className="color-brown">{option.description}</p>
          </Grid2>
        </Grid2>
      )}
      renderInput={(params) => (
        <StyledTextField
          initialvalue={initialValue}
          height={height}
          {...params}
          className="max-width-457"
          placeholder={initialValue ? initialValue : "address..."}
          variant="outlined"
          onChange={(evt) => getPlacePredictions({ input: evt.target.value })}
          InputProps={{
            ...params.InputProps
          }}
        />
      )
      }
      onInputChange={() => selectedValue("")}
    />
  );
};

export default withLocalContext(CheckoutAddress);
