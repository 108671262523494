import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import withLocalContext from "../store/withLocalContext";
import LineItem from "../LineItem";
import OrderNav from "./OrderNav";
import { Outlet, useLocation } from "react-router-dom";
import { element_sizes } from "../../constants/elementSizes";
import { useEffect, useState } from "react";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { setPopupHTML } from "../store/actions";
import { StyledGrid2 } from "../Menu/MenuCategories";
import ClearCart from "../Popup/ClearCart";

const Order = ({ context: { state, dispatch } }) => {
  const location = useLocation();
  const lap = useScreenWidth("isLaptop");

  const [enableEdit, setEnableEdit] = useState(true);
  const [showSummary, setShowSummary] = useState();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    setEnableEdit(location.pathname === "/cart" ? true : false);
    setCart(location.pathname === "/confirmation" ? state.confirmation.cart : state.cart)
  }, [location, enableEdit, state.cart, state.confirmation.cart])

  useEffect(() => {
    setShowSummary(false);
    cart.map(cartItem => {
      if (cartItem.variation || cartItem.modifierList || cartItem.donutPicks) {
        setShowSummary(true);
      }
      return cartItem;
    })
  }, [state.cart, cart])

  return (
    <Grid2 container className="m-b-50">
      <StyledGrid2
        container
        justifyContent={"space-between"}
        xs={8}
        md={12}
        className={`${lap ? `p-l-45 p-r-165 p-t-20 p-b-20 m-b-10 p-sticky top-${element_sizes.headerHgt.large + (state.siteAnnouncement ? element_sizes.announcement : 0)}` : "p-l-30 p-fixed top-30"} z-index-1 background-color-white`}
        sx={lap ? {} : { zIndex: 2000 }}
      >
        <OrderNav />
        {lap &&
          <button
            className="red-button max-width-140"
            onClick={() => dispatch(setPopupHTML(<ClearCart />))}
          >
            Clear cart
          </button>
        }
      </StyledGrid2>
      <Grid2 container xs={12} className={`${lap ? "p-l-100 p-r-100" : "p-l-30 p-r-30"} top-100`}>
        {cart.length === 0 &&
          <Grid2 xs={12}>
            <p className="color-blue futura  max-width-330">No donuts here!</p>
          </Grid2>
        }
        <Grid2 xs={12} md={4}>
          <Grid2 xs={12}>
            {cart.map((cartItem, i) => (
              <LineItem key={cartItem.id + i} cartItem={cartItem} index={i} enableEdit={enableEdit} isCollapsed={lap ? false : isCollapsed} />
            ))}
          </Grid2>
          {!lap &&
            showSummary &&
            <Grid2 xs={12} className="m-b-40 m-t-55">
              <p className="color-blue font-size-20 center">
                {Array(6).fill('a').map((el, i) => (
                  <span key={i} className={`d-flex ${i === 0 ? "width-4" : "width-6"} height-2 border-radius-1 background-color-blue m-r-2 m-t-10`} />
                ))}
                <span className="underline m-x-10" onClick={() => setIsCollapsed(!isCollapsed)}>{isCollapsed ? "expand" : "collapse"} order summary</span>
                {Array(6).fill('a').map((el, i) => (
                  <span key={i} className={`d-flex ${i === 5 ? "width-4" : "width-6"} height-2 border-radius-1 background-color-blue m-r-2 m-t-10`} />
                ))}
              </p>
            </Grid2>
          }
        </Grid2>
        <Grid2 container xs={12} md={8}>
          <Outlet />
        </Grid2>
      </Grid2>
    </Grid2>
  )
}

export default withLocalContext(Order);