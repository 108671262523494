import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { isActiveCat } from "../store/actions";
import withLocalContext from "../store/withLocalContext";
import { element_sizes } from "../../constants/elementSizes";


const NavItem = ({ item, context: { state, dispatch } }) => {
  const lap = useScreenWidth("isLaptop");
  const navItemMarginTop = lap ? 10 : 20;
  const [hover, setHover] = useState(false);
  const [isCartConfig, setIsCartConfig] = useState(false);

  useEffect(() => {
    setIsCartConfig(state.cartConfig ? true : false)
  }, [state.cartConfig])

  const setActive = () => {
    dispatch(isActiveCat(item.category))
    if (lap) {
      let elToScrollTo = document.getElementById(item.category);
      const scrollTo = elToScrollTo.offsetTop - (isCartConfig ? 50 : 0) - (state.siteAnnouncement ? element_sizes.announcement : 0);
      window.scrollTo({ top: scrollTo, behavior: "auto" });
    }
  }

  useEffect(() => {
    if (window.location.hash) {
      let hash = window.location.hash.replace("#", "");
      dispatch(isActiveCat(hash))
    }
  }, [dispatch])

  const catImg = <img
    className={`${lap ? item.category === "coffee-+-tea" ? "m-l-10" : "" : ""} width-auto ${lap ? "max-width-60 max-height-50" : "max-width-60 max-height-60"}`}
    src={state.activeCat === item.category ? item.activeIcon : hover ? item.activeIcon : item.icon}
    alt=""
  />;
  return (
    <Link
      id={`nav-item-${item.category}`}
      to={`${lap ? "" : ROUTES.MENU}#${item.category}`}
      aria-label={item.ariaLabel}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => setActive()}
      className={`text-decoration-none m-b-10 ${lap ? "" : "p-width-100"}`}
    >
      <div className={`${lap ? "" : `d-flex justify-between align-center height-60`} ${item.category === "donuts" ? "" : `m-t-${navItemMarginTop}`}`}>
        {lap ?
          catImg
          :
          <div className={`width-60 d-flex justify-center ${item.category === "coffee-+-tea" ? "m-l-6" : ""}`}>
            {catImg}
          </div>
        }
        <p className={`${lap ? "font-size-18 m-t-3" : "font-size-20 width-150 text-align-left"} color-red`}>
          {item.category.split("-").map(el => el.replace(el[0], el[0].toUpperCase())).join(" ")}
        </p>
      </div>
    </Link>
  )
}

export default withLocalContext(NavItem);