
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import { billboardArrDesktop, billboardArrMobile } from "../../constants/carousels";
import { setPageDots } from "../Common/Functions/setPageDots";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";

const shuffledDesktop = billboardArrDesktop.sort((a, b) => 0.5 - Math.random());
const shuffledMobile = billboardArrMobile.sort((a, b) => 0.5 - Math.random());

const Billboard = () => {
  const lap = useScreenWidth("isLaptop");
  const prodId = "billboardCarousel";
  const heightPct = 39;

  const [isCarousel, setIsCarousel] = useState(true);

  useEffect(() => {
    if (window.Flickity) {
      var elem = document.querySelector('.billboard-carousel');
      let carousel = new window.Flickity(elem, {
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        pageDots: false,
      });
      setPageDots(prodId, carousel, shuffledDesktop.length);
    } else {
      setIsCarousel(false);
    }
  }, []);

  return (
    <Grid2 container xs={12} sx={{ height: `calc((100vw *.${heightPct})` }} justifyContent="center">
      {isCarousel ?
        <div id={prodId} className="billboard-carousel carousel p-width-100">
          {shuffledDesktop.map((item, i) => (
            <div key={item} className="carousel-cell">
              <img alt="" src={lap ? item : shuffledMobile[i]} style={{ height: `calc((100vw *.${heightPct})`, objectPosition: "bottom" }} className="v-width-100 object-fit-cover" />
            </div>
          ))}
        </div>
        :
        <div>
          <img alt="" src={lap ? shuffledDesktop[0] : shuffledMobile[0]} style={{ height: `calc((100vw *.${heightPct})`, objectPosition: "bottom" }} className="v-width-100 object-fit-cover" />
        </div>
      }
    </Grid2>
  )
}

export default Billboard;