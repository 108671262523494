import { COLORS } from "../../../../constants/colors";

const calendarHeaderStyles = {
  "& .MuiPickersCalendarHeader-root": {
    "& .MuiPickersCalendarHeader-labelContainer": {
      "& .MuiPickersCalendarHeader-switchViewButton": {
        color: COLORS.red,
      },
    },
    '& .MuiPickersCalendarHeader-label': {
      color: COLORS.red,
      fontFamily: 'Futura-Med'
    }
  }
};

const dayHeaderStyles = {
  '& .MuiDayPicker-header': {
    '& .MuiDayPicker-weekDayLabel': {
      color: COLORS.brown,
    },
  }
}

const dayStyles = {
  '& .MuiPickersDay-root': {
    borderRadius: '5px',
    color: COLORS.brown,
    ":disabled": {
      color: COLORS.gold,
      opacity: 0.5
    },
    '&.Mui-selected': {
      backgroundColor: COLORS.red,
      color: COLORS.white,
    }
  }
}

const arrowSwitcherStyles = {
  '& .MuiPickersArrowSwitcher-root': {
    "& .MuiButtonBase-root": {
      backgroundColor: COLORS.red,
      color: COLORS.white,
      borderRadius: '5px',
      padding: '2px',
      ":hover": {
        opacity: 0.9
      },
      ":disabled": {
        opacity: 0.5
      }
    }
  }
}

const yearPickerStyles = {
  "& .PrivatePickersYear-yearButton": {
    color: COLORS.brown,
    borderRadius: '5px',
    '&.Mui-selected': {
      backgroundColor: COLORS.red,
      color: COLORS.white,
    }
  }
}

const popperStyles = {
  '&.MuiPickersPopper-root': {
    zIndex: 2500,
  }
}

export const datePickerStyles = {
  ...calendarHeaderStyles,
  ...dayHeaderStyles,
  ...dayStyles,
  ...arrowSwitcherStyles,
  ...popperStyles,
  ...yearPickerStyles
}