import { Box } from "@mui/material";
import { InfoWindow as GoogleInfoWindow } from "@vis.gl/react-google-maps";

const InfoWindow = ({ coords, info, setOpen }) => {
  return (<GoogleInfoWindow position={coords} onCloseClick={() => setOpen(false)}>
    <p className='bold color-black'>{info.title}</p>
    <p className='max-width-150 color-black'>{info.address}</p>
    <Box
      component={"a"}
      sx={{
        color: "#1a73e8",
        textDecoration: "none",
        fontWeight: 400
      }}
      href={info.link}
      target="_blank"
    >
      View on Google Maps
    </Box>
  </GoogleInfoWindow>)
}

export default InfoWindow;