import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import withLocalContext from "../store/withLocalContext";
import { element_sizes } from "../../constants/elementSizes";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { useEffect } from "react";

const Announcement = ({ context: { state } }) => {
  const lap = useScreenWidth("isLaptop");

  useEffect(() => {
    if (state.siteAnnouncement) {
      let app = document.getElementById("App");

      app.style.paddingTop = (lap ? element_sizes.headerHgt.large : element_sizes.headerHgt.small) + element_sizes.announcement + "px";
      app.style.height = window.innerHeight - ((lap ? element_sizes.headerHgt.large : element_sizes.headerHgt.small) + element_sizes.announcement) + "px";

    }
  }, [state.siteAnnouncement, lap])

  if (!state.siteAnnouncement) {
    return null;
  }

  return (
    <Grid2
      id="site_announcement"
      container
      className={`p-fixed top-${lap ? element_sizes.headerHgt.large : element_sizes.headerHgt.small} background-color-blue v-width-100 font-size-20 height-${element_sizes.announcement} align-center`}
      sx={{
        zIndex: 900
      }}
    >
      <p className={`text-align-left color-white ${lap ? "m-l-35" : "m-x-30 font-size-16"}`}>{state.siteAnnouncement}</p>
    </Grid2>
  )
}

export default withLocalContext(Announcement);