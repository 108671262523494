import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import LoadingState from "../Common/Components/LoadingState";
import { removePeriods } from "../Common/Functions/removePeriods";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import withLocalContext from "../store/withLocalContext";

const Contact = ({ context: { state } }) => {

  const lap = useScreenWidth("isLaptop");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (state.siteData.contact_info) {
      state.siteData.contact_info[0].map((item, i) => {
        item.map(el => {
          if (el.content === "Wedding Events") {
            setPhone(item.filter(a => a.header === "Phone")[0].content)
            setEmail(item.filter(a => a.header === "Email")[0].content)
            setName(item.filter(a => a.header === "Name")[0].content)
          }
          return el;
        });
        return item;
      })
    }
  }, [state.siteData])

  return (
    state.isMenuLoading ?
      <div className={`${lap ? "height-50" : "height-30"}`}>
        <LoadingState loadText={"Loading contact info..."} />
      </div>
      :
      <Grid2 xs={12} className="text-align-left m-t-80">
        <h3 className={`${lap ? "" : "font-size-20"}`}>Still have questions?</h3>
        <div className={`${lap ? "center m-t-45" : "m-t-25"} width-fit`}>
          <p className={`futura-cond ${lap ? "font-size-24" : "font-size-20"}`}>{name}</p>
          {lap && <span className="d-flex diamond-16 background-color-gold m-x-10" />}
          <p className={`${lap ? "font-size-24" : "font-size-16 m-t-10"}`}>Event Coordinator</p>
        </div>
        <div className={`${lap ? "font-size-20" : ""} m-t-20`}>
          <p>
            <a
              className="color-brown"
              href={`mailto:${email}`}
            >
              {email}
            </a>
          </p>
          <p>
            <a
              className={`${lap ? "m-t-5" : "m-t-10"} d-flex color-brown`}
              href={`tel:${removePeriods(phone)}`}
            >
              {phone}
            </a>
          </p>
        </div>
      </Grid2>
  )
}

export default withLocalContext(Contact);