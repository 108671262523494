import { Fragment } from "react";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";

const Variation = ({ variation }) => {
  const lap = useScreenWidth("isLaptop");
  return (
    <div className="text-align-left m-t-10">
      {variation.itemVariationData.itemOptionValues &&
        <Fragment>
          {lap &&
            <p className="futura-cond m-b-3">
              {variation.itemVariationData.itemOptionValues[0].optionName}
            </p>
          }
          {!lap &&
            <div className="height-1 background-color-tan m-t-6 m-b-10" />
          }
          <p className={`color-brown ${lap ? "font-size-14" : "font-size-16"}`}>{variation.itemVariationData.name}</p>
        </Fragment>
      }
    </div>
  )
}

export default Variation;