import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import woodGrain from "../../assets/woodgrain.webp";
import weddingImage from "../../assets/weddingImage.webp";
import giftCard from "../../assets/giftCard.webp";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { Fragment } from "react";

const StyledGiftCard = styled.img`
filter: drop-shadow(8px 8px 4px rgba(0, 0, 0, 0.25));
`;
const GiftCardAndEvents = () => {

  const navigate = useNavigate();
  const lap = useScreenWidth("isLaptop");
  const divBorder = <div className="height-1 p-width-100 background-color-tan m-y-25" />;
  const contWidth = lap ? "calc(50% - 9px)" : "100%";
  const contHeight = lap ? 'auto' : "258px";
  const titleClasses = `max-width-250 text-align-left color-white ${lap ? "p-absolute top-40 left-40 font-size-30" : "font-size-20"}`;
  const btnClasses = `red-button sentinel max-width-fit ${lap ? "p-absolute bottom-40 right-40 font-size-24" : "font-size-16"}`;

  const weddingTitle = <h3 className={`${lap ? titleClasses : titleClasses.replace("white", "brown")}`}>Sweeten your special day</h3>
  const weddingBtn = <button
    className={`${btnClasses}`}
    onClick={() => navigate(ROUTES.WEDDINGS)}
    aria-label="Get an estimate for an event"
  >
    Get a quote
  </button>;

  const giftCardTitle = <h3 className={`${lap ? titleClasses : titleClasses.replace("white", "blue")}`}>Treat someone you love</h3>;
  const giftCardBtn = <button
    className={`${btnClasses}`}
    onClick={() => window.open(ROUTES.SQUARE_GIFT_CARD)}
    aria-label="Buy a gift card"
  >
    Buy now
    <OpenInNewIcon
      className='m-l-3'
    />
  </button>;

  return (
    <Grid2 container xs={12} className={`${lap ? "height-482" : ""}`} gap={lap ? "18px" : "9px"}>
      {
        !lap &&
        <Grid2 container xs={12}>
          {divBorder}
          {weddingTitle}
        </Grid2>
      }
      <Grid2 container sx={{ background: `url(${woodGrain}) center/cover no-repeat`, width: contWidth, height: contHeight }} className="p-relative center">
        {lap && weddingTitle}
        <div className={`p-relative ${lap ? "diamond-260" : "diamond-168"} rotate-45 center overflow-hidden`}>
          <img alt="wedding couple" src={weddingImage} className={`${lap ? "max-width-370" : "max-width-235"} max-height-none -rotate-45`} />
        </div>
        {lap && weddingBtn}
      </Grid2>
      {
        !lap &&
        <Fragment>
          <Grid2 container justifyContent={"center"} xs={12}>
            {weddingBtn}
            {divBorder}
          </Grid2>
          <Grid2 xs={12}>
            {giftCardTitle}
          </Grid2>
        </Fragment>
      }
      <Grid2 container sx={{ width: contWidth, height: contHeight }} className="p-relative background-color-blue center">
        {lap && giftCardTitle}
        <div>
          <StyledGiftCard alt="gift card" src={giftCard} className="p-width-80 max-width-425 -rotate-12 border-radius-15" />
        </div>
        {lap && giftCardBtn}
      </Grid2>
      {
        !lap &&
        <Grid2 container justifyContent={"center"} xs={12}>
          {giftCardBtn}
          {divBorder}
        </Grid2>
      }
    </Grid2>
  )
}

export default GiftCardAndEvents;