import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import dayjs from "dayjs";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";

const SingleDonut = ({ data }) => {

  const lap = useScreenWidth("isLaptop");
  const titleClasses = `color-blue ${lap ? "font-size-20" : "font-size-18"}`;
  const contentClasses = `max-width-315 line-height-21 ${lap ? "m-t-17" : "m-t-10"}`;
  return (
    <Grid2 container xs={12} className="m-t-50 m-b-42" height={"fit-content"}>
      <Grid2 container xs={12} md={4} flexDirection="column" justifyContent={"flex-end"}>
        <p className={`futura-cond ${lap ? "color-brown font-size-24" : "color-red font-size-20"}`}>{dayjs(data.date).format("dddd")}</p>
        <p className={`${lap ? "font-size-24" : "font-size-16 color-red"}`}>{dayjs(data.date).format("MMMM D")}</p>
      </Grid2>
      <Grid2 container xs={12} md={4} flexDirection="column" justifyContent={"space-between"}>
        <h5 className={`${titleClasses} ${lap ? "" : "m-t-20"}`}>{data.new_inspired_title}</h5>
        <p className={contentClasses}>{data.new_inspired_content}</p>
      </Grid2>
      <Grid2 container xs={12} md={4} flexDirection="column" justifyContent={"space-between"}>
        <h5 className={`${titleClasses} ${lap ? "" : "m-t-20"}`}>{data.jam_filled_title}</h5>
        <p className={contentClasses}>{data.jam_filled_content}</p>
      </Grid2>
    </Grid2>
  )
}

export default SingleDonut;