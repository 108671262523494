import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import { Dropdown } from "../../Common/Components/Dropdown";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";
import ItemBtn from "../../Common/Components/ItemBtn";
import withLocalContext from "../../store/withLocalContext";
import { sortDonuts } from "../../Common/Functions/sortDonuts";
import { filterOutMenuItems } from "../../Common/Functions/filterOutMenuItems";

const DonutPicks = ({ context: { state }, data, donutPicks }) => {

  const lap = useScreenWidth("isLaptop");
  const [selectedDonuts, setSelectedDonuts] = useState([]);
  const [sortedDonuts, setSortedDonuts] = useState();
  // const sortedDonuts = sortDonuts(data.donutPicks ? data.donutPicks : []);
  let subCategories = filterOutMenuItems([...state.menu[0].subCategories]
    .filter(item => !item.subCategory.includes("Coffee to Go") &&
      !item.subCategory.includes("Donut Boxes")));

  const {
    itemData: {
      name,
      variations,
    } = {
      name: "no name",
      variations: "no variations"
    },
  } = data;

  const sendDonutPicksValue = (value) => {

    let tempDonutsArr = [];
    Object.keys(sortedDonuts).map(el => {
      sortedDonuts[el].map(a => {
        tempDonutsArr.push(a);
        return a;
      })
      return el;
    })

    if (value.previous) {
      if (value.new.id === letStorePick.id) {
        tempDonutsArr.splice(tempDonutsArr.findIndex(item => item.id === value.previous.id), 1)
      } else if (value.previous.id === letStorePick.id) {
        tempDonutsArr.push(value.new);
      } else {
        tempDonutsArr.splice(tempDonutsArr.findIndex(item => item.id === value.previous.id), 1, value.new)
      }
      setSortedDonuts(sortDonuts([...tempDonutsArr]))
      setSelectedDonuts([...tempDonutsArr])
    }
  }

  useEffect(() => {
    setSortedDonuts(sortDonuts(data.donutPicks ? data.donutPicks : []));
    setSelectedDonuts(data.donutPicks);
  }, [data.donutPicks]);

  const letStorePick = { name: "Let the store pick", id: "let_the_store_pick" };

  return (
    <Grid2 container justifyContent={"center"} className="p-width-100">
      {sortedDonuts &&
        <Grid2 container className={`${lap ? "p-l-23 p-r-20" : ""}`}>
          <Grid2 xs={12} className={`popup-header`}>
            <h3 className={`${lap ? "font-size-29" : "font-size-20"} color-blue p-b-10`}>{name}</h3>
          </Grid2>
          {/* Classics */}
          <Grid2 xs={12} sx={{ paddingLeft: lap ? 0 : "25px", paddingRight: lap ? 0 : "12px" }}>
            {Array(donutPicks.classics).fill('a').map((a, i) => (
              <Dropdown
                key={i}
                options={[letStorePick, ...subCategories[1].items]}
                catId={"classics_" + String(i)}
                category={i === 0 ? String(subCategories[1].subCategory.replace("Donuts", "") + `s (${donutPicks.classics})`).replace(" ", "") : null}
                initialOpt={(sortedDonuts.classics.length > 0 &&
                  i < sortedDonuts.classics.length) ?
                  sortedDonuts.classics[i]
                  :
                  letStorePick
                }
                numbered={i + 1}
                sendDonutPicksValue={(val) => sendDonutPicksValue(i < sortedDonuts.classics.length ? { ...val, new: { ...val.new, toReplace: i } } : val)}
              />
            ))}
          </Grid2>
          {/* Premiums */}
          <Grid2 xs={12} className="m-t-25" sx={{ paddingLeft: lap ? 0 : "25px", paddingRight: lap ? 0 : "12px" }}>
            {Array(donutPicks.premiums).fill('a').map((a, i) => (
              <Dropdown
                key={i}
                options={[letStorePick, ...subCategories[2].items]}
                catId={"premiums_" + String(i)}
                category={i === 0 ? String(subCategories[2].subCategory.replace("Donuts", "") + `s (${donutPicks.premiums})`).replace(" ", "") : null}
                initialOpt={(sortedDonuts.premiums.length > 0 &&
                  i < sortedDonuts.premiums.length) ?
                  sortedDonuts.premiums[i]
                  :
                  letStorePick
                }
                numbered={donutPicks.classics + i + 1}
                sendDonutPicksValue={(val) => sendDonutPicksValue(i < sortedDonuts.premiums.length ? { ...val, new: { ...val.new, toReplace: i } } : val)}
              />
            ))}
          </Grid2>
          {/* Inspired */}
          <Grid2 xs={12} className="m-t-25" sx={{ paddingLeft: lap ? 0 : "25px", paddingRight: lap ? 0 : "12px" }}>
            {Array(donutPicks.inspireds).fill('a').map((a, i) => (
              <Dropdown
                key={i}
                catId={"inspireds_" + String(i)}
                options={[letStorePick, ...subCategories[0].items]}
                category={i === 0 ? String(subCategories[0].subCategory.replace("Donuts", "") + `s (${donutPicks.inspireds})`).replace(" ", "") : null}
                initialOpt={(sortedDonuts.inspireds.length > 0 &&
                  i < sortedDonuts.inspireds.length) ?
                  sortedDonuts.inspireds[i]
                  :
                  letStorePick
                }
                numbered={donutPicks.classics + donutPicks.premiums + i + 1}
                sendDonutPicksValue={(val) => sendDonutPicksValue(i < sortedDonuts.inspireds.length ? { ...val, new: { ...val.new, toReplace: i } } : val)}
              />
            ))}
          </Grid2>
          <Grid2 xs={12} className="popup-button-gradient">
            <ItemBtn
              item={{ ...data, donutPicks: selectedDonuts }}
              price={variations[0].itemVariationData.priceMoney.amount}
              modifyData={data.donutPicks ? true : false}
            />
          </Grid2>

        </Grid2>
      }
    </Grid2>
  )
}

export default withLocalContext(DonutPicks);
