import { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants/colors";
import Checkbox from "./Checkbox";
import { useScreenWidth } from "../Functions/useScreenWidth";
import CardiganDatePicker from "./CardiganDatePicker";
import CardiganSelect from "./CardiganSelect";

const StyledDiv = styled.div`
  max-width: calc(${props => props.maxwidth} - ${props => props.islap === "true" ? "25px" : "0"});
  >div>div>div {
    padding-top: 9px !important;
    padding-bottom: 8px !important;
    >input {
      color: ${COLORS.brown};
      font-family: Futura;
    }
  }
`;

export const Input = ({
  id,
  label,
  maxWidth,
  notRequired,
  type,
  onChange,
  value,
  options,
  sectionId,
  containerId,
  isDisabled,
  isControlled,
  hasInputError,
  tabIndex
}) => {

  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [textareaHeight, setTextareaHeight] = useState(0);
  const lap = useScreenWidth("isLaptop");

  const checkValue = (value) => {
    if (!notRequired) {
      if (type === "email") {
        if (!/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\b/.test(value)) {
          setHasError(true);
          hasInputError(true, id)
          setErrorMsg("Please format email correctly");
        } else {
          setHasError(false);
          hasInputError(false, id)
          setErrorMsg("");
        }
      } else {
        if (value === "") {
          setHasError(true);
          hasInputError(true, id)
          setErrorMsg("Please fill out field");
        } else {
          setHasError(false);
          hasInputError(false, id)
          setErrorMsg("");
        }
      }
    }
  }

  const convertTel = (value, key) => {
    if (!isNaN(Number(key.data))) {
      let tempVal = value.replace(/-/g, "");
      let newVal = "";
      if (key.inputType === "deleteContentBackward") {
        newVal = value;
      } else {
        for (let i = 0; i < tempVal.length; i++) {
          const el = tempVal[i];
          newVal = newVal + el;
          if ((i === 2) || (i === 5)) {
            newVal = newVal + "-";
          }
        }
      }
      if (tempVal.length <= 10) {
        onChange(newVal);
      }
    }
  }

  useEffect(() => {
    setHasError(false);
    hasInputError(false, id);
    setErrorMsg("");
    // eslint-disable-next-line
  }, [isDisabled])

  useEffect(() => {
    if (type === "textarea") {
      let container = document.getElementById(id);
      if (container) {
        setTextareaHeight(Math.round((container.offsetWidth * 0.2) - 32));
      }
    }
  }, [id, type])

  return (
    <StyledDiv
      maxwidth={maxWidth}
      islap={String(lap)}
      className={`p-relative d-flex min-width-200 p-width-100 ${lap ? "m-r-35" : ""} m-b-18 ${type === "checkbox" ? `flex-direction-row-reverse ${lap ? "align-end" : "align-center"}` : "flex-direction-column"}`}
      onClick={() => { type === "checkbox" && onChange(!value) }}
    >
      {label &&
        <label className={`color-brown ${lap ? "font-size-20" : "font-size-16"} ${type === "checkbox" ? "cursor-pointer" : "m-b-9"}`} htmlFor={id}>
          {label}
          {!notRequired && <span className="color-red">*</span>}
        </label>
      }
      {type === "dropdown" ?
        // below span is to protect if from styles that being applied to <CheckoutAddress/>
        <span>
          {/* <Dropdown
            tabIndex={tabIndex}
            options={options}
            catId={id}
            containerId={containerId}
            selectedValue={onChange}
            initialOpt={value}
            isControlled={isControlled}
            isDisabled={isDisabled}
            styles={{
              contMT: "m-t-0",
              disContHeight: lap ? "height-46" : "height-36",
              disPadL: "p-l-10",
              contWidth: "min-width-200",
              arrWidth: "width-14",
              pointer: isDisabled ? "pointer-none" : ""
            }}
          /> */}
          <CardiganSelect
            tabIndex={tabIndex}
            value={value}
            isControlled={isControlled}
            isDisabled={isDisabled}
            onChange={onChange}
            options={options}
          />
        </span>
        :
        type === "cal" ?
          <CardiganDatePicker
            tabIndex={tabIndex}
            containerId={containerId}
            onChange={(val) => onChange(val)}
            value={value}
            isDisabled={isDisabled}
          />
          :
          type === "checkbox" ?
            <div className={`${lap ? "m-b-13" : ""} ${isDisabled ? "pointer-none opacity-75" : ""}`}>
              <Checkbox
                tabIndex={tabIndex}
                isChecked={value}
                singleSelect={true}
              />
            </div>
            :
            type === "textarea" ?
              <textarea
                tabIndex={tabIndex}
                id={id}
                required={notRequired ? false : true}
                type={type ? type : "text"}
                value={value}
                onChange={(e) => {
                  type === "tel" ?
                    convertTel(e.target.value, e.nativeEvent)
                    :
                    onChange(e.target.value)
                }
                }
                onBlur={(e) => checkValue(e.target.value)}
                placeholder={label ? label.toLowerCase() + "..." : ""}
                className={`border-brown thick-input p-a-16 height-${textareaHeight} ${isDisabled ? "pointer-none opacity-75" : ""}`}
              />
              :
              <input
                tabIndex={tabIndex}
                id={id}
                required={notRequired ? false : true}
                type={type ? type : "text"}
                value={value}
                onChange={(e) => {
                  type === "tel" ?
                    convertTel(e.target.value, e.nativeEvent)
                    :
                    onChange(e.target.value)
                }
                }
                onBlur={(e) => checkValue(e.target.value)}
                placeholder={label ? label.toLowerCase() + "..." : ""}
                className={`border-brown ${lap ? "thick-input" : "thin-input"} ${isDisabled ? "pointer-none opacity-75" : ""}`}
              />
      }
      {hasError &&
        <span className="p-absolute -bottom-18 futura-cond font-size-14 color-red">{errorMsg}</span>
      }
    </StyledDiv>
  )
}