import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import { subscribeUser } from "../../services";
import Message from "../Popup/Message";
import { setPopupHTML } from "../store/actions";
import withLocalContext from "../store/withLocalContext";
import border from "../../assets/NewsLetter/newsletter_border.svg";
import mobileBorder from "../../assets/NewsLetter/newsletter_border_mobile.svg";
import logoPostmark from "../../assets/NewsLetter/logoPostmark.svg";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { Box } from "@mui/material";

const Newsletter = ({ context: { dispatch } }) => {
  const [email, setEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const lap = useScreenWidth("isLaptop");
  const medDesk = useScreenWidth("isMedDesktop");

  const verifyEmailandSubscribe = async () => {
    // testing email is formatted right
    // https://www.codexpedia.com/regex/regex-symbol-list-and-regex-examples/
    if (/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\b/.test(email)) {
      const subscribeRes = await subscribeUser({ email: email });
      if (subscribeRes.data.message.includes("You are subscribed")) {
        setIsDisabled(true);
      } else {
        setEmail("");
      }
      dispatch(setPopupHTML(<Message title={subscribeRes.data.message} />));
    } else {
      dispatch(setPopupHTML(<Message title={"Email address is not formatted correctly."} />));
    }
  }

  const borderObj = {
    borderImage: `url(${lap ? border : mobileBorder})`,
    borderImageSlice: "10",
    borderImageRepeat: "stretch",
    borderWidth: "10px",
    borderStyle: "solid",
  };

  const mobileObj = {
    width: "calc(100% + 30px)",
    marginX: "-15px",
  }


  return (
    <Grid2
      container
      alignItems={"center"}
      xs={12}
      className="background-color-tan p-relative"
      sx={lap ? borderObj : { ...borderObj, ...mobileObj }}
    >
      <img alt="logo and postmark" src={logoPostmark} className={`p-absolute -top-9 -right-7 ${lap ? "" : "max-height-62"}`} />
      <div className={`${lap ? "p-y-30 center" : "p-b-17 p-t-50 flex-direction-column d-flex align-start p-l-16"}`}>
        <div className={`color-brown text-align-left ${lap ? `${medDesk ? "min-width-455" : ""} m-l-52` : ""}`}>
          <h3 className={`${lap ? "font-size-30" : "font-size-16"} color-brown`}>Never miss your favorite donut</h3>
          <p className={`${lap ? "font-size-20 m-t-5" : "font-size-14 m-b-15"}`}>Receive donut alerts, discounts, and event news.</p>
        </div>
        <Box
          component={'div'}
          sx={{ flexDirection: { xs: 'column', lg: medDesk ? 'row' : 'column' }, alignItems: "start" }}
          className={`${lap ? "m-l-65" : ""} p-relative center`}
        >
          <input disabled={isDisabled} type="text" aria-label="Enter your email" placeholder="Email..." value={email} onChange={(e) => setEmail(e.target.value)} className={`${lap ? "thick-input width-300" : "thin-input width-148"} border-white p-l-25 p-r-25 m-r-21`} />
          <button
            disabled={isDisabled}
            aria-label="Sign-up for the newsletter"
            onClick={() => verifyEmailandSubscribe()}
            className={`red-button sentinel ${lap ? `max-width-150 font-size-24` : "max-width-100 font-size-16"} ${medDesk ? "" : "m-t-15"}`}
          >
            Subscribe
          </button>
        </Box>
      </div>
    </Grid2>
  )
}

export default withLocalContext(Newsletter);