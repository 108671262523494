import { COLORS } from "../../../../../../constants/colors";
import { contactConfirm } from "./contactConfirm";
import { paymentConfirm } from "./paymentConfirm";

export const confirmation = (confirmation) => {
  let html = "";
  html += `
  <div style="display: flex; text-align: left;">
    <div>
      ${contactConfirm(confirmation)}
      ${paymentConfirm(confirmation)}
      <div style="height: 1px; background-color: ${COLORS.grey}; opacity: 0.5; width: 100% ;border-radius: 50px;"></div>
    </div>
  </div>
`;
  return html;
}