import { toUSD } from "../../../../Functions/toUSD";

export const modiferList = (modifierList) => {
  let html = "";
  html = "";
  modifierList.map(modCat => {
    html += `<div style="text-align: left; margin-top: 10px;">`;
    modCat.modifiers.map(mod => Array.isArray(mod) ?
      mod.map(e => {
        return html += `<div style="height: 1px; background-color: #E8D7C0; margin: 6px 0 10px 0;"></div>
            <p style="font-size: 16px; color: #624C42; margin-left: 20px;">${e.name} ${e.price > 0 ? "(" + toUSD(e.price) + ")" : ""}</p>`
      })
      :
      html += `<div style="height: 1px; background-color: #E8D7C0; margin: 6px 0 10px 0;"></div>
          <p style="font-size: 16px; color: #624C42; margin-left: 20px;">${mod.name} ${mod.price > 0 ? "(" + toUSD(mod.price) + ")" : ""}</p>`
    )
    return html += `</div>`;
  })

  return html;
}