export const addLoggedIn = (payload) => ({
  type: "add_logged_in",
  payload,
});

export const addProducts = (payload) => ({
  type: "add_products",
  payload,
});

export const addTaxes = (payload) => ({
  type: "add_taxes",
  payload,
});

export const modifyCart = (payload) => ({
  type: "add_item_to_cart",
  payload,
});
export const createPayments = (payload) => ({
  type: "create_payments",
  payload,
});

export const loadCartFromStorage = (payload) => ({
  type: "load_cart_from_storage",
  payload,
});

export const setAddToLS = (payload) => ({
  type: "set_add_to_ls",
  payload,
});

export const isActiveCat = (payload) => ({
  type: "is_active_cat",
  payload,
});

export const activeSubCat = (payload) => ({
  type: "active_sub_cat",
  payload,
});

export const setOrderMessage = (payload) => ({
  type: "set_order_message",
  payload,
});
export const setOrderInstructions = (payload) => ({
  type: "set_order_instructions",
  payload,
});

export const isMenuLoading = (payload) => ({
  type: "is_menu_loading",
  payload,
});

export const hideFloatingCart = (payload) => ({
  type: "hide_floating_cart",
  payload,
});

export const setPopupHTML = (payload) => ({
  type: "set_popup_html",
  payload,
});

export const setOptions = (payload) => ({
  type: "set_options",
  payload,
});

export const setCartConfig = (payload) => ({
  type: "set_cart_config",
  payload,
});

export const setDeliveryMethods = (payload) => ({
  type: "set_delivery_methods",
  payload,
});

export const setMenuItem = (payload) => ({
  type: "set_menu_item",
  payload,
});

export const setOrderDiscount = (payload) => ({
  type: "set_order_discount",
  payload,
});

export const setOrderTotals = (payload) => ({
  type: "set_order_totals",
  payload,
});

export const setOrderTip = (payload) => ({
  type: "set_order_tip",
  payload,
});

export const setBanner = (payload) => ({
  type: "set_banner",
  payload,
});

export const setGiftCard = (payload) => ({
  type: "set_gift_card",
  payload,
});
export const setConfirmation = (payload) => ({
  type: "set_confirmation",
  payload,
});
export const setUnavailableItems = (payload) => ({
  type: "set_unavailable_items",
  payload,
});
export const setSiteData = (payload) => ({
  type: "set_site_data",
  payload,
});
export const setSiteAnnouncement = (payload) => ({
  type: "set_site_announcment",
  payload,
});
export const setHeaderTitle = (payload) => ({
  type: "set_header_title",
  payload,
});
export const setAvailableForMetroDelivery = (payload) => ({
  type: "set_available_for_metro_delivery",
  payload,
});
export const setAvailableForSameDay = (payload) => ({
  type: "set_available_for_same_day",
  payload,
});
export const setDeviceDetails = (payload) => ({
  type: "set_device_details",
  payload,
});