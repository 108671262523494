import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { loadCartFromStorage, setBanner, setPopupHTML } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";

const OutOfStock = ({ items, context: { state, dispatch } }) => {

  const removeItemsFromCart = () => {
    let tempCartArr = state.cart;
    items.map(item => {
      return tempCartArr = tempCartArr.filter(el => el.id !== item.id)
    })
    dispatch(loadCartFromStorage(tempCartArr));
    dispatch(setBanner("Cart modified"));
    dispatch(setPopupHTML(""));
  }

  return (
    <Grid2 container className="max-width-400" justifyContent={"center"}>
      <Grid2 xs={12}>
        <h3 className="m-b-15">Unavailable Items</h3>
        <p className="font-size-20 m-b-10">Some items are no longer available to purchase!</p>
        <p className="font-size-20 m-b-10">We aplogize for the inconvenience.</p>
        <div className="height-2 m-y-20 background-color-tan" />
        <p className="font-size-20">The following item{items.length > 1 ? "s are" : " is"} no longer in stock:</p>
      </Grid2>
      <Grid2 xs={12} container justifyContent={"center"}>
        <div className="width-fit m-t-18">
          {items.map((prod, i) => (
            <p key={prod.name + i} className="text-align-left font-size-20">{i + 1}. {prod.name}</p>
          ))}
        </div>
      </Grid2>
      <Grid2 xs={12} container justifyContent={"center"} className="m-t-30">
        <button
          className="red-button"
          onClick={() => removeItemsFromCart()}
        >
          Remove items
        </button>
      </Grid2>
    </Grid2>
  )
}

export default withLocalContext(OutOfStock);