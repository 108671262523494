import { Fragment, useEffect, useState } from "react";
import { pad2 } from "../Common/Functions/Pad2";
// import { setSelectedDonutPicks } from "../store/actions";
import withLocalContext from "../store/withLocalContext";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { sortDonuts } from "../Common/Functions/sortDonuts";

const DonutPicksLineItem = ({ donuts, donutPicks, name, context: { dispatch } }) => {
  const lap = useScreenWidth("isLaptop");

  const donutClasses = `color-brown ${lap ? "font-size-14" : "font-size-16"}`;

  const [classics, setClassics] = useState([]);
  const [premiums, setPremiums] = useState([]);
  const [inspired, setInspired] = useState([]);
  const maxDonuts = donutPicks.classics + donutPicks.premiums + donutPicks.inspireds;

  useEffect(() => {
    let sortedDonuts = sortDonuts(donuts);
    setClassics(sortedDonuts.classics);
    setPremiums(sortedDonuts.premiums);
    setInspired(sortedDonuts.inspireds);
  }, [donuts, name, dispatch])

  return (
    <div className="text-align-left m-t-10">
      {classics.length > 0 &&
        <Fragment>
          {lap &&
            <p className="futura-cond m-b-3">Classics</p>
          }
          {classics.map((classic, i) => (
            <Fragment key={i}>
              {!lap &&
                <div className="height-1 background-color-tan m-t-6 m-b-10" />
              }
              <p key={classic.number} className={donutClasses}><span className="color-gold m-r-5">{pad2(i + 1)}</span>{classic.itemData.name}</p>
            </Fragment>
          ))}
        </Fragment>
      }
      {premiums.length > 0 &&
        <Fragment>
          {lap &&
            <p className="futura-cond m-b-3">Premiums</p>
          }
          {premiums.map((premium, i) => (
            <Fragment key={i}>
              {!lap &&
                <div className="height-1 background-color-tan m-t-6 m-b-10" />
              }
              <p key={premium.number} className={donutClasses}><span className="color-gold m-r-5">{pad2(classics.length + i + 1)}</span>{premium.itemData.name}</p>
            </Fragment>
          ))}
        </Fragment>
      }
      {inspired.length > 0 &&
        <Fragment>
          {lap &&
            <p className="futura-cond m-b-3">Inspired</p>
          }
          {inspired.map((insp, i) => (
            <Fragment key={i}>
              {!lap &&
                <div className="height-1 background-color-tan m-t-6 m-b-10" />
              }
              <p key={insp.number} className={donutClasses}><span className="color-gold m-r-5">{pad2(classics.length + premiums.length + i + 1)}</span>{insp.itemData.name}</p>
            </Fragment>
          ))}
        </Fragment>
      }
      {donuts.length < maxDonuts &&
        <p className="m-t-5 color-red">Let the store pick the rest</p>
      }
    </div>
  )
}

export default withLocalContext(DonutPicksLineItem);