import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useEffect, useState } from 'react';
import { useScreenWidth } from '../../Functions/useScreenWidth';
import { FLAGSHIP_LOCATIONS, PARTNER_LOCATIONS, WEEKEND_POPUPS, BAKERY_CASES } from '../../../../constants/locations';
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin
} from "@vis.gl/react-google-maps";
import { COLORS } from '../../../../constants/colors';
import InfoWindow from './InfoWindow';

/**
 * Renders a map component displaying various locations.
 * @returns {JSX.Element} The LocationsMap component.
 * @tutorial https://visgl.github.io/react-map-gl/docs/get-started/get-started
 * @tutorial https://www.youtube.com/watch?v=PfZ4oLftItk&list=PL2rFahu9sLJ2QuJaKKYDaJp0YqjFCDCtN
 */
const LocationsMap = () => {

  const lap = useScreenWidth("isLaptop");
  const width = window.innerWidth;
  const height = width * .32;

  // this is added because the google libraries maps and places do not play together.
  // maps takes away places, but places is needed for the address component.
  useEffect(() => {
    const load = async () => {
      await window.google.maps.importLibrary("places");
    }
    load();
  }, [])

  const LOCATIONS = [
    ...FLAGSHIP_LOCATIONS.map(item => ({ ...item, isFlagship: true })),
    ...PARTNER_LOCATIONS,
    ...WEEKEND_POPUPS,
    ...BAKERY_CASES
  ];

  const styles = {
    height: (lap ? `${height}px` : "456px") + " !important",
    width: (lap ? "100%" : "calc(100% + 60px)") + " !important",
    marginX: "-30px"
  }
  const [open, setOpen] = useState(false);
  const [coords, setCoords] = useState();
  const initInfoObj = {
    title: "",
    address: "",
    link: ""
  }
  const [info, setInfo] = useState(initInfoObj);
  const defaultProps = {
    center: {
      lat: 44.97682182284217,
      lng: -93.27288168239693
    },
    zoom: 17,
    mapId: process.env.REACT_APP_GOOGLE_CUSTOM_MAP_ID,
  };

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      <Grid2
        id="cardiganMap"
        sx={styles}
      >
        <Map zoom={defaultProps.zoom} center={defaultProps.center} mapId={defaultProps.mapId} streetViewControl={false}>
          {LOCATIONS.map((location, index) => (
            <AdvancedMarker
              key={location.lngLat.lat}
              position={location.lngLat}
              onClick={() => {
                setOpen(true);
                setCoords(location.lngLat);
                setInfo({
                  title: location.title,
                  address: location.address,
                  link: location.addressLink
                });
              }}
            >
              {location.isFlagship ?
                <div className='diamond-30 background-color-red center'>
                  <span className='d-flex -rotate-45 color-white font-size-19'>{index + 1}</span>
                </div>
                :
                <Pin
                  borderColor={COLORS.blue}
                  background={COLORS.blue}
                  glyphColor={COLORS.yellow}
                />
              }
            </AdvancedMarker>
          ))}
          {open && (
            <InfoWindow
              coords={coords}
              info={info}
              setOpen={setOpen}
            />
          )}
        </Map>
      </Grid2>
    </APIProvider>
  );
}

export default LocationsMap;