export const variation = (variation) => {

  const html = `
  <div style="text-align: left; margin-top: 10px;">
  ${variation.itemVariationData.itemOptionValues ?
      `<div style="height: 1px; background-color: #E8D7C0; margin: 6px 0 10px 0;"></div>
      <p style="font-size: 16px; color: #624C42; margin-left: 20px;">${variation.itemVariationData.name}</p>
    `
      : ""
    }
  </div>`
  return html;
}