import React, { useEffect, useState } from "react";
import withLocalContext from "../../store/withLocalContext";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { setHeaderTitle, setOrderInstructions, setOrderMessage, setPopupHTML } from "../../store/actions";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import CartConfiguration from "../../Popup/CartConfiguration";
import { COLORS } from "../../../constants/colors";
import { element_sizes } from "../../../constants/elementSizes";
import OrderTotals from "../OrderTotals";
import { ROUTES } from "../../../constants/routes";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";
import card from "../../../assets/card.webp";
import { ORDER_FROM_LOCATIONS } from "../../../constants/locations";
import { usePageTracking } from "../../Tracking";

export const blueUpperClasses = (lap) => {
  return `futura-cond font-size-${lap ? 20 : 16}`;
};

export const Cart = ({ context: { state, dispatch } }) => {
  dayjs.extend(advancedFormat);

  const [configObj, setConfigObj] = useState([]);
  const lap = useScreenWidth("isLaptop");
  const maxLength = 200;
  const metroRegex = /metro/i;
  const deliveryDesc = "Our standard delivery window is between 7:30 and 10am.";

  useEffect(() => {
    let tempArr = [];
    let cartConfigOBj = Object.keys(state.cartConfig);
    if (cartConfigOBj.length > 0) {
      cartConfigOBj.map(e => {
        let splitTitle = e.split(/(?=[A-Z])/);
        splitTitle = splitTitle.reverse().reduce((total, item) => item + " " + total);
        if (e === "orderType") {
          tempArr.push({ title: "Your order type", desc: state.cartConfig.orderType.externalId })
        } else if (e === "deliveryType") {
          tempArr.push({ title: splitTitle, desc: state.cartConfig.deliveryType.name })
        } else if (e === "date") {
          tempArr.push({ title: splitTitle, desc: dayjs(state.cartConfig[e]).format("dddd, MMMM Do, YYYY") })
        } else {
          tempArr.push({ title: splitTitle, desc: state.cartConfig[e] })
        }
        return e;
      })
      let orderType = tempArr.splice(tempArr.findIndex(item => item.title.includes === "order"), 1);
      tempArr.unshift(orderType[0]);
    }

    setConfigObj(tempArr);
  }, [state.cartConfig])

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setHeaderTitle(""));
  }, [dispatch]);

  usePageTracking();

  return (
    <Grid2 id="cardigan_cart" container xs={12} sx={lap ? { border: `3px solid ${COLORS.tan} ` } : {}} className={`${lap ? "m-l-65 m-r-65 p-a-55" : "p-l-12 p-r-12"} height-fit p-sticky top-${element_sizes.headerHgt.large + 100} `}>
      <Grid2 container xs={lap ? 6 : 12} className="text-align-left">
        <Grid2 xs={12} className="m-b-25">
          <h3>Order details</h3>
        </Grid2>
        {configObj.map(item => (
          <Grid2 key={item.title} xs={12} className="m-b-25">
            <h4 className={`${blueUpperClasses(lap)} `}>{item.title}</h4>
            <p className="m-r-20">{item.desc}</p>
            {metroRegex.test(item.desc) &&
              <span className="d-flex max-width-200 color-brown m-t-3 italic">{deliveryDesc}</span>
            }
            {item.title === "location" &&
              <p className="max-width-200">{ORDER_FROM_LOCATIONS.filter(el => el.name === item.desc)[0].address}</p>
            }
          </Grid2>
        ))}
        <Grid2 xs={12}>
          <button
            className={`${lap ? "font-size-20" : "font-size-16"} blue-link p-a-0`}
            onClick={() => dispatch(setPopupHTML(<CartConfiguration orderType={state.cartConfig.orderType} pickup={false} />))}
          >
            Change my order details
          </button>
        </Grid2>
      </Grid2>
      <Grid2 container xs={lap ? 6 : 12}>
        <Grid2 xs={12}>
          <h3 className={`text-align-left ${lap ? "" : "m-t-55"} m-b-15`}>Any special instructions for this order?</h3>
          <Grid2 container xs={12} className="height-fit">
            <div className="center p-width-100">
              <textarea
                maxLength={maxLength}
                className={`height-56 p-a-5 p-width-100 border-brown color-brown`}
                placeholder="instructions..."
                value={state.orderInstructions}
                onChange={(e) => dispatch(setOrderInstructions(e.target.value))}
              />
            </div>
            {state && state.orderInstructions ?
              <p>{maxLength - state.orderInstructions.length} charactors remaining</p>
              :
              <p>{maxLength} charactors remaining</p>
            }
          </Grid2>
        </Grid2>
        <Grid2 xs={12} sx={{ marginTop: "25px" }}>
          <h3 className={`text-align-left ${lap ? "" : "m-t-55"} m-b-15`}>Include a personal greeting card and message:</h3>
          <Grid2 container xs={12} className="height-fit">
            <div className="center p-width-100">
              <img className="height-66 m-r-12" alt="card" src={card} />
              <textarea
                maxLength={maxLength}
                className={`height-56 p-a-5 p-width-100 border-brown color-brown`}
                placeholder="your message..."
                value={state.orderMessage}
                onChange={(e) => dispatch(setOrderMessage(e.target.value))}
              />
            </div>
            {state && state.orderMessage ?
              <p>{maxLength - state.orderMessage.length} charactors remaining</p>
              :
              <p>{maxLength} charactors remaining</p>
            }
          </Grid2>
        </Grid2>
        <Grid2 container xs={12}>
          <OrderTotals showBtn btnInfo={{ text: "Proceed to checkout", route: ROUTES.CHECKOUT }} lineInputs={["products", "delivery", "discount", "subtotal", "tax"]} />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default withLocalContext(Cart);