import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useState } from "react"
import styled from "styled-components"
import { COLORS } from "../../../../constants/colors"
import { useScreenWidth } from "../../../Common/Functions/useScreenWidth"
import withLocalContext from "../../../store/withLocalContext"
import Contact from "./Contact"
import Delivery from "./Delivery"
import Payment from "./Payment"
import { PersonInfoProvider } from "./Provider"

// common children classes for all contact info
export const inputWrappers = "d-flex flex-wrap";
// 

export const contClasses = (lap) => `${lap ? "p-l-55 p-r-55 p-t-45 p-b-45 m-t-35" : "p-l-30 p-r-30 p-t-25 p-b-25 m-t-30"}`;

const StyledPersonInfoCont = styled(Grid2)`
  h3 {
    font-size: 20px;
    ${props => props.lap === "true" ? `
      margin-bottom: 35px;
    `: `
      margin-bottom: 30px;
  `}
  }`;

export const StyledSectionCont = styled(Grid2)`
  border: 3px solid ${props => props.haserror === "true" ? COLORS.red : COLORS.tan};
 `;

const PersonalInfo = ({ total, context: { state, dispatch } }) => {

  const [sectionErrors, setSectionErrors] = useState("");
  const lap = useScreenWidth("isLaptop");

  const contactId = "contact";
  const billingId = "billing";
  const deliveryId = "delivery";

  return (
    <PersonInfoProvider>
      <StyledPersonInfoCont lap={String(lap)} container xs={12} className="text-align-left">
        <StyledSectionCont
          id={contactId}
          container
          xs={12}
          haserror={String(sectionErrors ? sectionErrors[contactId].length > 0 : false)}
          className={`${contClasses()}`}
        >
          <Contact
            sectionId={contactId}
            setSectionErrors={(val) => setSectionErrors(val)}
          />
        </StyledSectionCont>
        <StyledSectionCont
          id={billingId}
          container
          xs={12}
          haserror={String(sectionErrors ? sectionErrors[billingId].length > 0 : false)}
          className={`${contClasses()}`}
        >
          <Payment
            sectionId={billingId}
            setSectionErrors={(val) => setSectionErrors(val)}
            showSubmitBtn={state.cartConfig.deliveryType ? false : true}
            total={total}
          />
        </StyledSectionCont>
        {state.cartConfig.deliveryType ?
          <StyledSectionCont
            id={deliveryId}
            container
            xs={12}
            haserror={String(sectionErrors ? sectionErrors[deliveryId].length > 0 : false)}
            className={`${contClasses()}`}
          >
            <Delivery
              sectionId={deliveryId}
              setSectionErrors={(val) => setSectionErrors(val)}
              showSubmitBtn={state.cartConfig.deliveryType ? true : false}
              total={total}
            />
          </StyledSectionCont>
          :
          null
        }
      </StyledPersonInfoCont>
    </PersonInfoProvider >
  )
}

export default withLocalContext(PersonalInfo);