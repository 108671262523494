export const CustomSort = (arrToSort, sortKey) => {
  let arrToReturn = [];

  sortKey.map(key => {
    let indexOf = arrToSort.findIndex(e => e.name === key);

    if (indexOf >= 0) {
      let filteredItem = arrToSort.splice(indexOf, 1);
      if (filteredItem.length > 0) {
        arrToReturn.push(filteredItem[0]);
      }
    }
    return key;
  });
  arrToReturn = [...arrToReturn, ...arrToSort];
  return arrToReturn;
}