export let JOB_FORM_DATA = {
  successMsg: "Your request has been sent!",
  failedMsg: "Your request failed for some reason. Please refresh the page and try again.",
  submitBtnTxt: "Submit",
  formTitle: "",
  formId: ["candidate-job-confirmation", "internal-job-confirmation"],
  inputs: [
    {
      type: "text",
      title: "Full Name",
      required: true,
      col: 12,
      value: ""
    },
    {
      type: "email",
      title: "Email",
      required: true,
      col: 6,
      value: ""
    },
    {
      type: "tel",
      title: "Phone",
      required: true,
      col: 6,
      value: ""
    },
    {
      type: "dropdown",
      title: "Position",
      required: true,
      col: 6,
      value: { name: "select" },
      dropdown: {
        dropdownOpts: [
          {
            id: "barista",
            name: "Barista"
          },
          {
            id: "bakery",
            name: "Bakery"
          },
          {
            id: "delivery",
            name: "Delivery"
          }
        ]
      }
    },
    {
      type: "text",
      title: "Part-time or Full-time",
      required: true,
      col: 6,
      value: ""
    },
    {
      type: "textarea",
      title: "Tell us why you're interested in this job at Cardigan Donuts",
      required: true,
      col: 12,
      value: ""
    },
    {
      type: "textarea",
      title: "Tell us about any relevant job experience",
      required: true,
      col: 12,
      value: ""
    }
  ]
}