import { useEffect, useState } from "react";
import { setBanner } from "../store/actions";
import withLocalContext from "../store/withLocalContext";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";

const Banner = ({ context: { dispatch, state } }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState("");
  const lap = useScreenWidth("isLaptop");

  useEffect(() => {
    if (state.banner) {
      setIsVisible(true);
      setMessage(state.banner);
      setTimeout(() => {
        setIsVisible(false);
        dispatch(setBanner(""))
      }, 2000);
    }
  }, [state.banner, dispatch])

  return (
    <Grid2
      sx={{ transition: "all ease-in .3s" }}
      className={`${isVisible ? "opacity-1 z-index-10" : "opacity-0 -z-index-1"} p-fixed center dropdown-menu-box-shadow background-color-blue color-white v-width-100 ${lap ? "height-60" : "height-42"}`}>
      <span className={`d-flex ${lap ? "font-size-20" : "font-size-16"}`}>
        {message}
      </span>
    </Grid2>
  )
}

export default withLocalContext(Banner);