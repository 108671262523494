import { JOB_FORM_DATA } from "../../JobOpenings/jobFormData";
import Form from "../../Common/Components/Form";
import { sendEmail } from "../../../services";
import withLocalContext from "../../store/withLocalContext";
const JobForm = ({ context: { state } }) => {

  const submitJobForm = async (data) => {

    const position = data.template_content.filter(item => item.name === "Position")[0].content;
    let email = "";

    if (state.siteData.contact_info) {
      state.siteData.contact_info[0].map(item => {
        item.map(el => {
          if (el.content === position) {
            email = item.filter(a => a.header === "Email")[0].content;
          }
          return el;
        })
        return item;
      })
    }

    let fullName = data.template_content.filter(item => item.name === "Full_Name")[0];
    fullName = fullName.content.split(" ");
    fullName = fullName[0];
    data.template_content = [...data.template_content, { name: "First_Name", content: fullName }]
    let emailRes = "";
    const tempNameArr = data.template_name;

    for (let i = 0; i < tempNameArr.length; i++) {
      const element = tempNameArr[i];
      data.template_name = element;
      if (i === 1) {
        data.message.to[0].email = email;
        delete data.message.subject;
      } else {

        data.message.subject = `Your ${data.template_content.filter(item => item.name === "Position")[0].content} application has been submitted`
      }
      emailRes = await sendEmail({ data: data });
    }

    return emailRes;
  }
  return (
    <Form data={JOB_FORM_DATA} submitData={(val) => submitJobForm(val)} />
  )
}
export default withLocalContext(JobForm);