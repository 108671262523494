import { useState } from "react";
import withLocalContext from "../store/withLocalContext";
import SingleCard from "./SingleCard";

const GiftCard = ({ context: { state } }) => {

  const [cardObjs, setCardObjs] = useState([]);
  const paymentMethods = ["zero-gift-card"];

  return (
    <div className="m-t-8">
      <form id="payment-form">
        {paymentMethods.map((paymentMethod, i) => (
          <SingleCard
            key={i}
            paymentMethod={paymentMethod}
            cardObj={cardObjs}
            updateCardObj={
              (val) => {
                let newCardObjArr = cardObjs;
                let newCardObj = newCardObjArr.filter(item => item.type === val.type);
                newCardObj[0].amount = val.amount;
                newCardObjArr.splice(newCardObjArr.indexOf(item => item.type === val.type), 1, newCardObj[0]);
                setCardObjs([...newCardObjArr])
              }}
          />
        ))}
      </form>
    </div>
  )
}

export default withLocalContext(GiftCard);