import { useState } from "react";
import styled from "styled-components"
import { COLORS } from "../../../constants/colors";

const markerWidth = 22;
const marksWidth = 13;

const StyledBaseLine = styled.div`
  width: calc(100% - ${markerWidth / 2}px);
  background-image: linear-gradient(90deg,${COLORS.red} ${props => props.markerpos}px, ${COLORS.brown} ${props => props.markerpos}px);
  left: 50%;
  transform: translate(-50%);
`;

const StyledMarker = styled.div`
  box-shadow: 0px 0px 4px 1px ${COLORS.brown};
  left : ${props => props.markerpos}px;
`;

export default function Slider({ quantity, max, markIncrements, containerId, selectedQuantity }) {

  const [markerPos, setMarkerPos] = useState(-3);
  const markerLineId = containerId + "-markerLine";
  const marker = "marker";


  // To get marker position
  // get input.pagex, then add the negative of container.offsetLeft
  // then take width of marker and subtract half of marker width
  // (to be most exact, marker width should be an even number)
  // this will get the marker exactly in the middle of where the pointer was clicked
  const setMarkerPosition = (input) => {
    let container = document.getElementById(containerId);

    let pointerStartLeft = input.pageX + (container.offsetLeft * -1);
    let pointerPos = pointerStartLeft - (markerWidth / 2);
    setMarkerPos(pointerPos);
    findNumOfDonuts(pointerStartLeft);
    searchForSpecificMarker(input.pageX, container);
  }

  const findNumOfDonuts = (pointerPos) => {
    let markerLine = document.getElementById(markerLineId);
    let markerPct = pointerPos / markerLine.offsetWidth;
    let numOfDonuts = Math.round(max * markerPct);

    selectedQuantity(numOfDonuts);
  }

  const searchForSpecificMarker = (pageX, container) => {

    let markers = document.getElementsByClassName(marker);
    [...markers].map(item => {
      let clientRects = item.getClientRects();
      if (pageX >= clientRects[0].left && pageX <= clientRects[0].right) {
        let pointerStartLeft = clientRects[0].left + (container.offsetLeft * -1);
        let pointerPos = pointerStartLeft - (markerWidth / 2);
        let markerNum = Number(item.id.substring(item.id.length - 1, item.id.length));
        setMarkerPos(pointerPos + 7);
        selectedQuantity(markerNum * markIncrements);
      }
      return item;
    })

  }
  //13 * √2
  return (
    <div
      id={containerId}
      className={`d-flex p-relative justify-between m-x-3 m-t-20 cursor-pointer highlight-none`}
      onClick={(e) => setMarkerPosition(e)}
    >
      <StyledBaseLine
        id={markerLineId}
        markerpos={markerPos}
        className="p-absolute top-6 height-3"
      />
      {/* <StyledMarker markerpos={markerPos} className={`p-absolute -top-4 z-index-1 diamond-${markerWidth} background-color-red`} /> */}
      <StyledMarker markerpos={markerPos} className={`p-absolute -top-4 z-index-1 diamond-${markerWidth} background-color-red`} />
      {Array(max / markIncrements + 1).fill(marker).map((e, i) => (
        <div
          id={e + "-" + i}
          key={i}
          className={`${marker} center flex-direction-column`}
        >
          <span
            className={`diamond-${marksWidth} d-flex ${quantity >= (i * markIncrements) ? "background-color-red border-red" : "background-color-white border-brown"}`}
          />
          <p className="p-absolute top-25 text-align-center">{i * markIncrements}</p>
        </div>
      ))}
    </div>
  )
}