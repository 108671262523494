export const setCarouselBtns = (querySelector, arrows) => {
  let carouselBtns = document.querySelectorAll(querySelector);

  if (carouselBtns.length > 0) {
    [...carouselBtns].map(btn => {
      let child = btn.children[0];
      let img = document.createElement("img");

      if ([...btn.classList].includes("next")) {
        img.src = arrows.right;
      } else {
        img.src = arrows.left;
      }
      return btn.replaceChild(img, child);
    })
  }
}