import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import LocationsMap from "../Common/Components/LocationsMap";
import LocationList from "../Common/Components/LocationList";
import { FLAGSHIP_LOCATIONS, PARTNER_LOCATIONS, WEEKEND_POPUPS, BAKERY_CASES } from "../../constants/locations";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { COLORS } from "../../constants/colors";
import Pin from "../../assets/pin.svg"
import { ROUTES } from "../../constants/routes";

const Map = () => {
  const lap = useScreenWidth("isTablet");
  const navigate = useNavigate();
  const OTHER_LOCATIONS = [...PARTNER_LOCATIONS, ...WEEKEND_POPUPS, ...BAKERY_CASES];
  return (
    <Grid2 container xs={12} className="m-b-50">
      <Grid2 container xs={12} justifyContent="center" className="m-t-70 m-b-30">
        <LocationsMap />
      </Grid2>
      <LocationList
        locations={FLAGSHIP_LOCATIONS}
        isNumbered
        imageDisplay={lap ? "right" : "bottom"}
      />
      <Grid2 xs={12} container justifyContent={"center"} alignContent={"center"}>
        <img alt="" src={Pin} />
        <Box
          component="h3"
          className="futura"
          sx={{
            fontSize: { xs: "20px", sm: "24px" },
            lineHeight: { xs: "25px", sm: "35px" },
            color: COLORS.blue,
            textAlign: "center",
            marginLeft: { xs: 0, md: "20px" }
          }
          }
        >
          We partner with {OTHER_LOCATIONS.length} small businesses to share our donuts
        </Box>
        <Grid2 container xs={12} justifyContent={"center"} alignContent={"center"} sx={{ marginTop: "20px", marginBottom: "80px" }}>
          <button
            className={`red-button sentinel max-width-fit ${lap ? "font-size-24" : "font-size-16"}`}
            onClick={() => navigate(ROUTES.GET_IN_TOUCH)}
            aria-label="Visit a partnering location"
          >
            Visit a partnering location
          </button>
        </Grid2>
      </Grid2>
    </Grid2 >
  )
}

export default Map;