import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { ROUTES } from "../../constants/routes";
import wordmark from "../../assets/cardiganWordmark.svg";
import wordmark_red from "../../assets/cardiganWordmark_red.svg";
import cart from "../../assets/cart.webp";
import NavMenu from "./NavMenu";
import withLocalContext from "../store/withLocalContext";
import { BottomLines } from "./BottomLines";
import { element_sizes } from "../../constants/elementSizes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { calcNumLineItems } from "../Common/Functions/CalculateTotals";
import MobileMenu from "./MobileMenu";
import { setPopupHTML } from "../store/actions";
import { ABOUT_US, CATERING, menu, mobileMenu, ORDER } from "../../constants/navMenus";

const StyledCartDiv = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 3px 0px 4px rgba(0, 0, 0, 0.25);
`;

const Header = ({ context: { state, dispatch } }) => {

  const [orderMenu, setOrderMenu] = useState(ORDER);

  const lap = useScreenWidth("isLaptop");
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  let tabIdx = 1;

  useEffect(() => {
    setOrderMenu(state.availableForMetroDelivery ? ORDER : ORDER.filter(item => !item.title.includes("delivery")));
  }, [state.availableForMetroDelivery]);

  return (
    <Grid2
      id="header"
      container
      sx={{ zIndex: 2000 }}
      className={`v-width-100 p-fixed top-0 ${lap ? `background-color-red height-${element_sizes.headerHgt.large}` : `${location.pathname === ROUTES.MENU_CATEGORIES ? "" : location.pathname === ROUTES.MENU ? "background-color-transparent" : "background-color-white"} height-${element_sizes.headerHgt.small}`}`}
      onClick={() => {
        if (state.popupHTML) {
          dispatch(setPopupHTML(""))
        }
      }}
    >
      {lap ?
        <Fragment>
          <Grid2 container xs={12} className="m-b-10">
            <Grid2 container justifyContent={"space-evenly"} alignContent="center" xs={4}>
              <NavMenu title={{ text: "home", link: ROUTES.LANDING_PAGE }} tabIndex={tabIdx} />
              <NavMenu title="menu" items={menu(state.menu)} tabIndex={tabIdx += 1} />
              <NavMenu title="order" items={orderMenu} tabIndex={tabIdx += (menu(state.menu).length + 1)} />
            </Grid2>
            <Grid2 container justifyContent={"center"} alignContent="center" xs={4}>
              <Link tabIndex={tabIdx += (orderMenu.length + 1)} state={{ fromNav: true }} to={ROUTES.LANDING_PAGE}><img src={wordmark} alt="Cardigan Donuts Wordmark" className="height-73" /></Link>
            </Grid2>
            <Grid2 container justifyContent={"space-evenly"} alignContent="center" xs={4}>
              <NavMenu title="catering" items={CATERING} tabIndex={tabIdx += + 1} />
              <NavMenu title="about us" items={ABOUT_US} tabIndex={tabIdx += (CATERING.length + 1)} />
              <div className="d-flex">
                <NavMenu title={{ text: "cart", link: ROUTES.CART }} tabIndex={tabIdx += (ABOUT_US.length + 1)} />
              </div>
            </Grid2>
          </Grid2>
          <BottomLines yAxis={"bottom-0"} />
        </Fragment>
        :
        <Grid2 container xs={12} alignContent="center" className="background-color-white">
          <Grid2 container xs={3} className="p-relative center">
            {![ROUTES.CART, ROUTES.CHECKOUT, ROUTES.CONFIRMATION].includes(location.pathname) &&
              <StyledCartDiv
                className="diamond-33 center"
                onClick={() => navigate(ROUTES.CART)}
              >
                <img alt="cart" src={cart} className="-rotate-45 max-width-23" />
                <div className="p-absolute center width-30 -left-11 -top-10 -rotate-45">
                  <p className="color-red sentinel font-size-20">
                    {state.cart.length > 0 ? `${calcNumLineItems(state.cart)}` : ""}
                  </p>
                </div>
              </StyledCartDiv>
            }
          </Grid2>
          <Grid2 container xs={6} justifyContent="center" alignContent="center">
            {
              location.pathname === "/" ?
                <Link to={ROUTES.LANDING_PAGE}><img src={wordmark_red} alt="Cardigan Donuts Wordmark" className="height-41" /></Link>
                :
                <h1 className="font-size-24 height-fit max-width-185">{state.headerTitle}</h1>
            }
          </Grid2>
          <Grid2 container xs={3} className="center" height={"100%"}>
            <MobileMenu
              menu={mobileMenu(state.cart)}
              isOpen={isOpen}
              setIsOpen={(val) => setIsOpen(val)}
            />
          </Grid2>
        </Grid2>
      }
    </Grid2>
  );
}

export default withLocalContext(Header);