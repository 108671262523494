export const COLORS = {
  white: "#ffffff",
  grey: "#808080",
  cream: "#f9f5ef",
  hoverWhite: "#dcd7d7",
  black: "#000000",
  yellow: "#DABE5D",
  blue: "#40657D",
  hoverBlue: "#003d64",
  cyan: "#495859",
  mint: "#A9FFCB",
  red: "#D85028",
  hoverRed: "#b0511b",
  gold: "#DCB964",
  brown: "#624C42",
  hoverBrown: "#38302c",
  tan: "#E8D7C0",
};