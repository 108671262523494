import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import BackgroundDiamond from "../Common/Components/BackgroundDiamond";

import pic1 from "../../assets/barista.webp";
import pic3 from "../../assets/delivery.webp";
import JobType from "./JobType";
import { useScreenWidth } from "../Common/Functions/useScreenWidth";
import { useEffect } from "react";
import { setHeaderTitle } from "../store/actions";
import withLocalContext from "../store/withLocalContext";
import { usePageTracking } from "../Tracking";

const JOB_ARRAY = [
  {
    image: pic1,
    title: "Barista/Server",
    text: `We treasure our baristas and servers because they are the ones we entrust to serve our guests, 
    handle our products with care, and keep our store clean and orderly.`,
    description: <div className="text-align-left p-x-15 m-b-10">
      <p>Cardigan Donuts is a leading Twin Cities craft donut experience and coffee shop located in the City Center skyway and serving the downtown community, Monday - Saturday. At Cardigan Donuts, you will be part of a fast paced and innovative small business, working directly with local owner-entrepreneurs and skilled artisans who are passionate about taking quick service, hospitality, craft donuts and beverages to the next level.</p>

      <p className="m-t-10">Server / Baristas are responsible for serving our signature line of classic and artisan donuts, delivering great guest service, brewing coffee, and making traditional espresso drinks and tap drinks. Beyond these duties, we look to our Barista / Servers for new ideas for crafting drinks and contributing new marketing ideas to drive sales.</p>

      <p className="m-t-10">In this role you can expect to work as a server for 2-3 months before being eligible to transition to the barista role.  We train and certify new baristas 3 times a year, and fill open Barista positions from within the server team.</p>

      <p className="m-t-10">We'd love to hear from people who:</p>
      <ul>
        <li>Have a passion for coffee and donuts</li>
        <li>Are quick learners and enjoy teamwork</li>
        <li>Have 1-2 years of coffee shop or food service experience</li>
        <li>Can work morning shift (6:00AM to 12:00 PM) or afternoon shifts (12:00PM to 6:30PM) or on Saturdays between 8:00 AM and 5:30 PM</li>
        <li>Can begin immediately and have availability for 3-4 shifts per week.</li>
      </ul>
    </div>
  },
  {
    image: "",
    title: "Bakery Team",
    text: `Our incredible bakery team handcrafts our donut lineup each morning with fresh, house-made ingredients.`,
    description: <div className="text-align-left p-x-15 m-b-10">
      <p>Cardigan Donuts is a leading Twin Cities craft donut experience and coffee shop located in the City Center skyway and serving the downtown community, Monday - Saturday. At Cardigan Donuts, you will be part of a fast paced and innovative small business, working directly with local owner-entrepreneurs and skilled artisans who are passionate about taking quick service, hospitality, craft donuts, and beverages to the next level.</p>
      <p className="m-t-10">We currently have 1 Overnight Baker position available for shifts beginning at 1:00 am. We take our overnight bakery and prep team very seriously. Compensation starts at $15 /hour, or better based on experience. Downtown parking can be provided if needed.</p>
      <p className="m-t-10">We are looking for the following qualifications:</p>
      <ul>
        <li>Can begin immediately</li>
        <li>Have experience working overnight shifts</li>
        <li>Have 1-2 years of Prep-Cook or Bakery experience</li>
        <li>Have a passion for baking and culinary arts</li>
        <li>Are quick learners and enjoy teamwork</li>
        <li>Can work early morning shifts beginning at  1:00AM</li>
      </ul>
    </div>
  },
  {
    image: pic3,
    title: "Delivery Driver",
    text: `Each morning, our delivery drivers set out to bring orders to customers in the metro area, delivering donuts to directly to their door.`,
    description: <div className="text-align-left p-x-15 m-b-10">
      <p>Cardigan Donuts is a leading Twin Cities craft donut experience and coffee shop located in the City Center skyway and serving the downtown community, Monday - Saturday. At Cardigan Donuts, you will be part of a fast paced and innovative small business, working directly with local owner-entrepreneurs and skilled artisans who are passionate about taking quick service, hospitality, craft donuts and beverages to the next level.</p>
      <p className="m-t-10">Our AM Delivery Drivers are responsible for making 5-10 residential deliveries between 7AM and 10AM. Occasionally, our drivers will run special errands for the store as well.  Compensation includes $17 / hour, $0.45 / mile, and the ability to earn tips, donuts, and coffee!! On every residential delivery, we attach the driver’s Venmo QR code so that our customers can leave a tip if they desire.</p>
      <p className="m-t-10">In this role you can expect to work 2-3  shifts per week, with the opportunity to work more if desired. We'd love to hear from people who:</p>
      <ul>
        <li>Have availability on Saturday mornings and 2 other weekdays</li>
        <li>Have a reliable and presentable vehicle</li>
        <li>Can demonstrate proof of insurance and a valid driver’s license</li>
        <li>Can begin immediately</li>
        <li>Doordash / UberEats or similar experience is a plus, but not required</li>
      </ul>
    </div>
  }
]

const JobOpenings = ({ context: { dispatch } }) => {
  const lap = useScreenWidth("isLaptop");
  const title = "Job Openings";

  useEffect(() => {
    dispatch(setHeaderTitle(title))
  }, [dispatch])

  usePageTracking();

  return (
    <BackgroundDiamond>
      <Grid2 container xs={12} className={`${lap ? "p-l-150 p-r-150 m-t-85" : "p-l-30 p-r-30"} m-b-85 text-align-left`}>
        <Grid2 xs={12} className={`p-b-60`}>
          {lap &&
            <h1>{title}</h1>
          }
          <p className={`${lap ? "font-size-24 line-height-31 p-r-100" : ""} m-t-28`}>
            At Cardigan Donuts, we’re excited about the morning. We set out each day to create a warm, welcoming environment for each visitor. Are you or someone you know looking for part-time or full-time work? We’d love to hear from you.
          </p>
        </Grid2>
        <Grid2 container xs={12}>
          {JOB_ARRAY.map(item => (
            <JobType key={item.title} data={item} />
          ))}
        </Grid2>
      </Grid2>
    </BackgroundDiamond>
  )
}
export default withLocalContext(JobOpenings);