import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { DownArrow } from "../../../assets/DownArrow";
import { COLORS } from "../../../constants/colors";
import dayjs from "dayjs";
import { useScreenWidth } from "../Functions/useScreenWidth";
import { adjustMenu } from "../Functions/adjustMenu";
import withLocalContext from "../../store/withLocalContext";
import { ORDER_FROM_LOCATIONS } from "../../../constants/locations";

const StyledSpan = styled.span`
  transition: rotate .2s;
`;

export const TimeDropdown = ({ orderType, location, deliveryType = "", selectedValue, initialOpt = "", calendarDate = "", context: { state } }) => {

  const lap = useScreenWidth("isLaptop");
  const [value, setValue] = useState(initialOpt);
  const [isVisible, setIsVisible] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const catId = "timeSlotDropdown";
  const ulId = "ul_" + catId;

  // eslint-disable-next-line
  const findNearest15Minute = () => {
    let isNearest15 = false;
    let nearest15Minutes = dayjs();
    let startMinute = dayjs().minute();
    let minute = startMinute;

    while (!isNearest15) {
      if (minute % 15 === 0) {
        if (minute - startMinute < 15) {
          nearest15Minutes = dayjs().minute(minute).add(15, 'm');
        }
        isNearest15 = true;
      } else {
        if (minute >= 60) {
          minute = 0;
        } else {
          minute++;
        }
      }
    }
    return nearest15Minutes;
  }

  const setListofAvailbleTimes = (startTime) => {
    let startTimeSlot = '';
    let endTimeSlot = '';
    const locationMatch = ORDER_FROM_LOCATIONS.find(loc => loc.name === location);
    if (locationMatch) {
      const calDate = dayjs(calendarDate).isValid ? dayjs(calendarDate) : dayjs();
      const orderMatch = locationMatch.orderAvailibility.find(order => order.day === dayjs(calDate).format("dddd"));
      if (orderMatch) {
        const typeMatch = orderMatch.orderType.find(type => type.id === orderType.id);
        if (typeMatch) {
          const splitStartTime = typeMatch.startTime.split(":");
          const splitEndTime = typeMatch.endTime.split(":");
          startTimeSlot = dayjs().hour(splitStartTime[0]).minute(splitStartTime[1]).startOf('m');
          endTimeSlot = dayjs().hour(splitEndTime[0]).minute(splitEndTime[1]).startOf('m').format("hh:mm A");
        }
      }
    }

    if (orderType.id === "same") {
      if (dayjs(startTime).isAfter(startTimeSlot)) {
        startTimeSlot = startTime;
      }
    } else if (deliveryType.name?.includes("Skyway")) {
      startTimeSlot = dayjs().hour(7).minute(30).startOf('m');
      endTimeSlot = "10:30 AM";
    }

    if (startTimeSlot && endTimeSlot) {

      let tempTimeSlots = [];
      let tempTimeSlot = startTimeSlot;
      while (tempTimeSlots[tempTimeSlots.length - 1] !== endTimeSlot) {
        tempTimeSlots.push(dayjs(tempTimeSlot).format("hh:mm A"));
        if (deliveryType.name?.includes("Skyway")) {
          tempTimeSlot = dayjs(tempTimeSlot).add(60, "m");
        } else {
          tempTimeSlot = dayjs(tempTimeSlot).add(15, "m");
        }
      }

      setTimeSlots(tempTimeSlots);

      let timeToSet = tempTimeSlots[0];
      if (state.cartConfig.time) {
        timeToSet = dayjs(state.cartConfig.time, 'h:mm A').format('h:mm A');
        if (dayjs(state.cartConfig.time, "h:mm A").isBefore(dayjs(startTimeSlot, "h:mm A"))) {
          timeToSet = dayjs.isDayjs(startTimeSlot) ? dayjs(startTimeSlot).format('h:mm A') : startTimeSlot;
        }
      }

      setValue(timeToSet);
      selectedValue(timeToSet);
    }
  };

  useEffect(() => {
    setListofAvailbleTimes(findNearest15Minute());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType, deliveryType, location])

  if (orderType.id === "same") {
    if (!state.availableForSameDay) {
      return null;
    }
  }

  return (
    <Grid2 container flexDirection={"column"} className={`m-t-0 text-align-left p-relative`}>
      <ClickAwayListener onClickAway={() => setIsVisible(false)}>
        <div
          className="p-relative color-brown width-fit"
          onClick={() => {
            setIsVisible(!isVisible)
            adjustMenu("", catId, ulId, !isVisible);
          }}>
          <div
            id={catId}
            className={`${isVisible ? "border-red" : "border-brown"} ${lap ? "height-45 p-r-35" : "height-34 p-r-22"} p-l-10 d-flex align-center justify-between cursor-pointer`}
          >
            <span className="m-r-20">
              {value}
            </span>
            <span className={`${isVisible ? "border-l-red" : "border-l-brown"} p-height-100`}>
              <StyledSpan
                className={`width-15 d-flex p-absolute ${lap ? "top-15 right-9" : "top-12 right-5"} ${isVisible ? "rotate-180" : "rotate-0"}`}
              >
                <DownArrow fillColor={COLORS.red} />
              </StyledSpan>
            </span>
          </div>
          <ul
            id={ulId}
            className={`p-absolute m-t-5 background-color-white p-l-0 p-width-100 max-width-493 max-height-300 transition ${isVisible ? `height-${timeSlots.length * 45} border-brown dropdown-menu-box-shadow z-index-300 overflow-scroll` : "height-0 -z-index-1 border-white overflow-hidden"}`}
          >
            {timeSlots.map((slot, i) => (
              <li
                key={slot}
                className={`${i !== timeSlots.length - 1 && "border-b-brown"} height-45 p-l-15 d-flex align-center color-brown cursor-pointer dropdown-item list-style-none`}
                onClick={() => {
                  setValue(slot);
                  selectedValue(slot);
                }}
              >
                {slot}
              </li>
            ))}
          </ul>
        </div>
      </ClickAwayListener>
    </Grid2>
  )
}

export default withLocalContext(TimeDropdown);