import { donutDropdownItems, donutPickItems } from "../../constants/donutPicks";
import { MENU_ARRAY } from "../../constants/menuArray";
import { isAvailableForSameDay } from "../Common/Functions/timeChecks";


export const initialState = {
  menu: MENU_ARRAY,
  unavailableItems: [],
  taxes: "",
  cart: [],
  donutPickItems: donutPickItems,
  donutDropdownItems: donutDropdownItems,
  squarePayments: "",
  canAddtoLS: false,
  orderTotals: {},
  orderMessage: "",
  orderInstructions: "",
  orderDiscount: "",
  orderTip: "",
  giftCards: [],
  confirmation: "",
  hideFloatingCart: false,
  activeCat: "donuts",
  activeSubCat: "Donut Boxes",
  isMenuLoading: true,
  popupHTML: "",
  options: [],
  cartConfig: "",
  deliveryMethods: "",
  availableForSameDay: isAvailableForSameDay(),
  availableForMetroDelivery: true,
  menuItem: { name: "", id: "", modifierList: [], variation: {}, variations: [], options: [] },
  banner: "",
  // siteAnnouncement: "The store is closed",
  siteAnnouncement: "",
  siteData: "",
  headerTitle: "",
  deviceDetails: "",
};
