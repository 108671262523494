/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useParams } from "react-router-dom";
import withLocalContext from "../store/withLocalContext";
import styled from "styled-components";

const StyledImg = styled.img`
  width: 200px;
`;

export const MenuItem = ({ context: { state } }) => {
  const { slug } = useParams();
  const product = state.menu.find((p) => p.slug === slug)

  return (
    <div>
      <h2>{product.itemData.name}</h2>
      <p>{product.itemData.description}</p>
      <StyledImg alt={product.itemData.name} src={product.imageUrl} />
    </div>
  );
};

export default withLocalContext(MenuItem);
