import { useEffect, useState } from "react";
import { Dropdown } from "../../Common/Components/Dropdown";
import { setMenuItem } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";

const Options = ({ context: { state, dispatch }, optionsToRender, variations, selectedOptions }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptionsArr();
    // eslint-disable-next-line
  }, [])

  const setOptionsArr = () => {
    const optionArr = [];
    if (optionsToRender) {
      optionsToRender.map(optToRender => {
        const filteredObj = state.options.filter(item => item.id === optToRender.itemOptionId);
        filteredObj[0].itemOptionData.values.map(val => {
          val.category = filteredObj[0].itemOptionData.displayName;
          return val.name = val.itemOptionValueData.name;
        })
        let initialOpt = filteredObj[0].itemOptionData.values[0];
        if (selectedOptions) {
          selectedOptions.map(selectedOpt => {
            if (filteredObj[0].itemOptionData.values.some(item => item.id === selectedOpt.id)) {
              initialOpt = selectedOptions[0];
            }
            return selectedOpt;
          })
        }
        const objToReturn = {
          obj: { ...filteredObj[0] },
          initialOpt: initialOpt,
          catId: filteredObj[0].id,
          category: filteredObj[0].itemOptionData.displayName
        }
        dispatch(setMenuItem([{ ...objToReturn.initialOpt, variations: variations }]))
        return optionArr.push(objToReturn);
      })
      setOptions(optionArr);
    }
  };

  return (
    <div>
      {options.map(opt => (
        <Dropdown
          key={opt.catId}
          initialOpt={{ ...opt.initialOpt }}
          catId={opt.catId}
          category={opt.category}
          options={opt.obj.itemOptionData.values}
          selectedValue={(value) => dispatch(setMenuItem(value))}
        />
      ))}
    </div>
  )
}
export default withLocalContext(Options);