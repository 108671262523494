import { usePageTracking } from "../Tracking";

const MailMeDonuts = () => {
  usePageTracking();
  return (
    <div>
      MailMeDonuts
    </div>
  )
}

export default MailMeDonuts;