import dayjs from "dayjs"

export const isAvailableForSameDay = () => {
  let isAvailable = true;

  if (dayjs().day() === 0) {
    isAvailable = false;
  } else {
    isAvailable = dayjs().isBefore(dayjs().hour(15).startOf('hour'));
  }
  return isAvailable;
}

export const isAfter12PM = () => {
  return dayjs().isAfter(dayjs().hour(12).startOf('hour'));
}